/* eslint-disable @typescript-eslint/no-unused-vars */
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';

import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Iconify from 'src/components/iconify';
import { SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },

  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...bgGradient({
        startColor: theme.palette.mode === 'light' ? '#000' : '#fff',
        endColor: theme.palette.primary.light,
      }),
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...bgGradient({
        startColor: theme.palette.primary.light,
        endColor: theme.palette.primary.light,
      }),
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: `1px dashed ${theme.palette.mode === 'light' ? '#000' : '#fff'}`,
    borderRadius: 1,
    backgroundColor: 'transparent',
    borderStyle: 'dashed',
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  border: '1px solid #000',

  ...(ownerState.active && {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: '#fff',
    ...bgGradient({
      startColor: '#000',
      endColor: '#000',
    }),
  }),
  ...(ownerState.completed && {
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: theme.palette.mode === 'light' ? '#000' : '#fff',
      endColor: theme.palette.mode === 'light' ? '#000' : '#fff',
    }),
  }),
}));

function ColorlibStepIcon(props: StepIconProps, _icon: string) {
  const { active, completed, className, icon } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Iconify icon="mdi:company" width={24} />,
    2: <Iconify icon="mdi:locations" width={24} />,
    3: <Iconify icon="bi:diagram-2" width={24} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {!completed ? icons[String(icon)] : <Iconify icon="material-symbols:done" width={24} />}
    </ColorlibStepIconRoot>
  );
}

type StepperProps = {
  activeStep: number;
  STEPS: string[];
  sx?: SxProps<Theme>;
  isStepSkipped: (step: number) => boolean;
};

export default function CustomizedSteppers({ activeStep, STEPS, sx, isStepSkipped }: StepperProps) {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
      sx={{
        ...sx,
      }}
    >
      {STEPS.map((label, index) => {
        const stepProps: { completed?: boolean; active?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        stepProps.completed = index < activeStep || isStepSkipped(index) === true;

        return (
          <Step key={label} {...stepProps}>
            <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
