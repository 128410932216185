/* eslint-disable @typescript-eslint/no-unused-vars */
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// slices
import { combineReducers } from '@reduxjs/toolkit';
import RegisterReducer from './slices/register';
import UserReducer from './slices/user.slice';
import UsageReducer from './slices/usage';
import NotificationReducer from './slices/notification';
import BillingReducer from './slices/billing';

// ----------------------------------------------------------------------

export const createNoopStorage = () => ({
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

export const storage =
  typeof window !== 'undefined' ? createWebStorage('session') : createNoopStorage();

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['register'],
};

const rootReducer = combineReducers({
  register: RegisterReducer,
  user: UserReducer,
  usage: UsageReducer,
  billing: BillingReducer,
  notification: NotificationReducer,
});

export default rootReducer;
