import axios from 'axios';
// config
import { BE_URL } from 'src/config-global';
import { supabaseClient } from './supabaseClient';

// ----------------------------------------------------------------------

const axiosBackendWithoutVirsionInstance = axios.create({ baseURL: BE_URL });

axiosBackendWithoutVirsionInstance.interceptors.request.use(
  async (config) => {
    const { data, error } = await supabaseClient.auth.getSession();

    const { session } = data;
    if (error) throw new Error(error.message);
    if (session?.access_token) {
      // Create a new object by copying config and add the Authorization header to it.
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${session?.access_token}`;
      return newConfig;
    }
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosBackendWithoutVirsionInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosBackendWithoutVirsionInstance;
