import { CreateLogsForm } from 'src/types/logs.type';
import axiosBackendInstance from 'src/utils/axios-backend-instance';
import { LogsActions } from 'src/enumerate/logsActions';
import { createNewLog } from './logs';

export async function uploadImage(image: File, path: string, fileName: string) {
  try {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('path', path);
    formData.append('fileName', fileName);
    console.log('🚀 ~ uploadImage ~ formData:', formData);

    const { data } = await axiosBackendInstance.post('uploader/upload', formData);
    return data;
  } catch (err) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPLOAD_IMAGE,
      message: err.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(err.message);
  }
}
export async function uploadTestimonialImage(image: File, path: string, fileName: string) {
  try {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('path', path);
    formData.append('fileName', fileName);
    console.log('🚀 ~ uploadImage ~ formData:', formData);

    const { data } = await axiosBackendInstance.post('uploader/anonymous-upload', formData);
    return data;
  } catch (err) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPLOAD_IMAGE,
      message: err.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(err.message);
  }
}

export async function uploadVideo(video: File, id: string) {
  try {
    const formData = new FormData();
    formData.append('file', video); // Append the file

    const { data } = await axiosBackendInstance.post(`aws/upload/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('🚀 ~ uploadVideo ~ data:', data);

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function deleteImage(path: string) {
  try {
    const { data } = await axiosBackendInstance.delete('uploader/delete', { data: { path } });
    return data;
  } catch (err) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DELETE_IMAGE,
      message: err.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(err.message);
  }
}
