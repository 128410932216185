import { Card, Chip, Container, Stack, Typography } from '@mui/material';
import DomainIllustration from 'src/assets/illustrations/domain-illustration';
import { MotionContainer } from '../animate';

type Props = {
  title?: string;
  text_content?: string;
  domain?: string;
};

export default function DomainVerify({ title, text_content, domain }: Props) {
  return (
    <Container
      maxWidth="md"
      component={MotionContainer}
      sx={{
        textAlign: 'center',
        position: 'relative',
        minHeight: '75vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          filter: 'drop-shadow(0px 30px 120px rgba(24, 119, 242, 0.62))',
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',

          maxWidth: 600,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DomainIllustration
          sx={{
            height: {
              sm: 300,
              md: 400,
            },
            margin: 'auto',
          }}
        />

        <Typography gutterBottom variant="h3">
          {title}
        </Typography>
        <Typography variant="subtitle2" sx={{ pb: 1 }}>
          {text_content}
        </Typography>

        <Chip
          label={
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {domain}
              </Typography>
            </Stack>
          }
          color="primary"
          sx={{
            mt: 2,
            backgroundColor: 'rgba(24, 119, 242, 0.1)',
            color: '#1877f2',
          }}
        />
      </Card>
    </Container>
  );
}
