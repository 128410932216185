import { useCallback, useEffect, useState } from 'react';
import { LogsActions } from 'src/enumerate/logsActions';
import { supabaseClient } from 'src/utils/supabaseClient';
import { useDispatch, useSelector } from 'react-redux';
import { userInfoUpdated } from 'src/redux/slices/user.slice';
import { CreateLogsForm } from 'src/types/logs.type';
import { store } from 'src/redux/store';
import { createNewLog } from './logs';
import { uploadImage } from './supabaseUpload';

export async function getProfile() {
  return new Promise((resolve, reject) => {
    const data = useProfile().userProfile;
    if (data) {
      resolve(data);
    } else {
      reject(useProfile().error);
    }
  });
}

/**
 * Update WalkTour
 */
export async function updateWalkTour(walktour: any, user_id: string) {
  const { data, error } = await supabaseClient
    .from('profiles')
    .update({ walktour })
    .eq('user_id', user_id);
  if (error) throw new Error(error.message);

  return data;
}

const updateProfile = async (data: any) => {
  const { user } = store.getState();

  if (data?.avatar_url instanceof File) {
    const url = await uploadImage(data?.avatar_url, 'user_profile', user?.id);
    data.avatar_url = url;
  }

  if (data?.name) {
    const { error: userAuthUpdatedError } = await supabaseClient.auth.updateUser({
      data: {
        name: data?.name,
      },
    });
    if (userAuthUpdatedError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_PROFILE,
        message: userAuthUpdatedError.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify(data)),
      };
      createNewLog(newLogsForm);
      throw new Error(userAuthUpdatedError.message);
    }
  }

  const { data: profileUpdatedData, error } = await supabaseClient
    .from('profiles')
    .update(data)
    .match({ user_id: user?.id })
    .select('*')
    .single();

  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_PROFILE,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(data)),
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.UPDATE_PROFILE,
    message: 'Profile updated successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);
  return profileUpdatedData;
};
export function useProfile() {
  const user = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProfile = useCallback(async () => {
    if (!user.id || userProfile !== null || loading) return;
    setLoading(true);
    setError(null);
    try {
      const { data: profile, error: userProfileError } = await supabaseClient
        .from('profiles')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (userProfileError) {
        const newLogsForm = {
          action: LogsActions.GET_PROFILE,
          message: userProfileError.message,
          is_error: true,
          user_email: user.email,
          user_id: user.id,
        };
        createNewLog(newLogsForm);

        throw new Error(userProfileError.message);
      }

      setUserProfile(profile);
      if (profile) dispatch(userInfoUpdated({ profile }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [user, dispatch, userProfile, loading]);

  useEffect(() => {
    if (userProfile === null) {
      fetchProfile();
    }
  }, [userProfile, fetchProfile]);

  const resetData = useCallback(() => {
    setUserProfile(null);
  }, []);

  return { userProfile, updateProfile, loading, error, resetData };
}

export const getUserProfile = async (user_id: string) => {
  const { data: profile, error: userProfileError } = await supabaseClient
    .from('profiles')
    .select('*')
    .eq('user_id', user_id)
    .single();
  if (userProfileError) {
    throw new Error(userProfileError.message);
  }
  return profile;
};
