import { Stack } from '@mui/material';

type Props = {
  color?: string;
  transitioning: boolean;
};
export default function AuthSectionView({ color, transitioning }: Props) {
  return (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        color,
        position: 'fixed',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Stack
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          '@media (max-width: 960px)': {
            display: 'none',
          },
        }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1920 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-453.487 1487.73C-553.255 1208.62 -529.432 884.041 -374.454 631.351C-219.477 378.662 68.0992 207.936 364.517 210.165C518.821 211.333 670.035 256.372 824.249 261.86C978.463 267.349 1150.13 221.248 1230.64 89.5263C1330.95 -74.4319 1265.06 -325.715 1416.84 -443.548C1493.14 -502.809 1602.68 -502.954 1692.99 -468.564C1783.31 -434.174 1858.81 -370.462 1932.42 -307.818C2041.76 -214.766 2151.14 -121.766 2260.53 -28.6742C2461.28 142.142 2664.55 315.71 2813.14 533.394C2961.69 751.132 3051.38 1022.19 2998.31 1280.34C2945.25 1538.5 2723.42 1769.42 2459.96 1777.94C2294.2 1783.29 2138.78 1706.08 1981.56 1653.29C1824.34 1600.51 1637.88 1575.46 1503.35 1672.58C1402.87 1745.18 1355.49 1868.85 1313.36 1985.4C1251.35 2157.04 1189.25 2328.69 1127.24 2500.34C1084.29 2619.21 1039.31 2741.39 953.892 2834.49C868.473 2927.58 731.279 2985.14 612.967 2940.65C525.452 2907.75 464.4 2826.15 428.646 2739.75C392.892 2653.35 377.756 2560.01 352.01 2470.16C323.244 2369.73 281.437 2230.78 200.971 2158.79C139.598 2103.93 47.4305 2089.62 -21.2214 2042.89C-216.781 1909.77 -373.686 1710.58 -453.343 1487.76L-453.487 1487.73Z"
            fill={color}
            style={{
              fill: color,
              animation: `${color} 1s ease-in-out alternate`,
              transition: transitioning ? 'fill 1s ease-in-out' : 'none',
            }}
          />
          <path
            d="M645.028 1507.25C587.018 1576.51 497.999 1620.29 407.66 1618.5C317.321 1616.72 228.089 1567.42 182.972 1489.14C159.488 1448.38 148.016 1401.68 125.681 1360.28C103.347 1318.88 64.7564 1280.85 17.7077 1279.99C-40.8703 1278.9 -96.786 1334.96 -151.172 1313.22C-178.519 1302.3 -195.445 1273.52 -200.328 1244.47C-205.211 1215.42 -200.1 1185.74 -194.977 1156.73C-187.368 1113.63 -179.778 1070.53 -172.166 1027.41C-158.203 948.288 -143.906 868.075 -109.576 795.442C-75.2269 722.81 -17.7818 657.434 58.2807 631.583C134.343 605.732 229.265 628.458 272.126 696.42C299.092 739.18 302.753 791.954 313.114 841.432C323.475 890.911 345.638 943.803 391.919 964.2C426.501 979.43 466.323 972.818 503.466 965.928C558.16 955.768 612.871 945.63 667.564 935.47C705.444 928.436 744.505 921.424 782.155 929.531C819.805 937.638 856.092 964.839 862.635 1002.81C867.478 1030.89 855.435 1059.53 838.229 1082.25C821.023 1104.97 798.814 1123.34 779.158 1143.97C757.185 1167.02 727.096 1199.43 720.573 1231.69C715.609 1256.29 726.059 1282.73 724.355 1307.99C719.503 1379.93 691.32 1451.9 645.012 1507.2L645.028 1507.25Z"
            fill="white"
            fillOpacity="0.2"
          />
          <g clipPath="url(#clip0_1000_5084)">
            <rect
              x="763.562"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 763.562 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="792.4"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 792.4 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="821.24"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 821.24 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="850.08"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 850.08 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="878.92"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 878.92 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="907.76"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 907.76 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="936.6"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 936.6 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="965.439"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 965.439 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="994.277"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 994.277 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1023.12"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1023.12 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1051.96"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1051.96 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1080.8"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1080.8 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1109.64"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1109.64 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1138.48"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1138.48 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1167.31"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1167.31 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1196.15"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1196.15 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1224.99"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1224.99 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1253.83"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1253.83 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1282.67"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1282.67 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1311.51"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1311.51 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1340.35"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1340.35 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
            <rect
              x="1369.19"
              y="108"
              width="5.22888"
              height="841.124"
              transform="rotate(30 1369.19 108)"
              fill="#71A1FF"
              fillOpacity="0.4"
            />
          </g>
          <defs>
            <clipPath id="clip0_1000_5084">
              <rect x="673" y="195" width="369" height="516" rx="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Stack>
    </Stack>
  );
}
