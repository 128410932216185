/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'; // Added useState import
import { useSelector } from 'react-redux';
import { useCompany } from 'src/Services/company';
import DomainVerify from 'src/components/domain/domain-verify';
import { LoadingScreen } from 'src/components/loading-screen';
import { usePayment } from 'src/hooks/use-payment';

type DomainGuardProp = {
  children: React.ReactNode;
};

export default function DomainGuard({ children }: DomainGuardProp) {
  const { validateDomain } = useCompany();
  const { isTestUser } = usePayment();
  const {
    user: {
      company: { is_domain_verified, name },
    },
  } = useSelector((state: any) => state);
  /* delete space from name */
  const name_without_space = name?.toLowerCase()?.replace(/\s/g, '');
  const [isLoading, setIsLoading] = useState(true);

  const verifDomain = useCallback(async () => {
    await validateDomain();
    setIsLoading(false);
  }, [validateDomain]);

  useEffect(() => {
    if (!is_domain_verified && !isTestUser) {
      verifDomain();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <LoadingScreen />; // Show loading message while verifying domain
  }
  if (!is_domain_verified) {
    return (
      <DomainVerify
        title="Domain Verification"
        text_content="Please wait while we verify your sender domain"
        domain={`${name_without_space}.revcallmail.com`}
      />
    );
  }

  return <>{children}</>;
}
