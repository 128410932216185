// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

const currentHost = window.location.host;
export const IsDev = currentHost.includes('dev.revcall.ai') || currentHost.includes('localhost');

export const SUPABASE_API = {
  url: IsDev
    ? (process.env.REACT_APP_PUBLIC_SUPABASE_URL as string)
    : (process.env.REACT_APP_PUBLIC_SUPABASE_URL_PROD as string),
  anonKey: IsDev
    ? (process.env.REACT_APP_SUPABASE_ANON_KEY as string)
    : (process.env.REACT_APP_SUPABASE_ANON_KEY_PROD as string),
};

export const { REACT_APP_GOOGLE_API_KEY, REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY } = process.env;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

export const REST_API_URL = IsDev
  ? (process.env.REACT_APP_REST_API_URL as string)
  : (process.env.REACT_APP_REST_API_URL_PROD as string);
export const EMBED_REVIEW_BASE_URL = IsDev
  ? (process.env.REACT_APP_EMBED_REVIEW_BASE_URL as string)
  : (process.env.REACT_APP_EMBED_REVIEW_BASE_URL_PROD as string);

export const BE_URL = IsDev
  ? (process.env.REACT_APP_BACKEND_URL as string)
  : (process.env.REACT_APP_BACKEND_URL_PROD as string);
export const facebook = {
  appId: IsDev
    ? (process.env.REACT_APP_FACEBOOK_APP_ID as string)
    : (process.env.REACT_APP_FACEBOOK_APP_ID_PROD as string),
  facebookScope: IsDev
    ? (process.env.REACT_APP_FACEBOOK_SCOPE as string)
    : (process.env.REACT_APP_FACEBOOK_SCOPE_PROD as string),
};

export const FEEDBACK_URL = IsDev
  ? (process.env.REACT_APP_FEEDBACK_REQUEST_BASE_URL as string)
  : (process.env.REACT_APP_FEEDBACK_REQUEST_BASE_URL_PROD as string);

export const DEV_URL = IsDev
  ? (process.env.REACT_APP_DEV_URL as string)
  : (process.env.REACT_APP_DEV_URL_PROD as string);

export const STRIPE_PUBLIC_KEY = IsDev
  ? (process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)
  : (process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD as string);

export const CALL_BACK_URL = IsDev
  ? (process.env.REACT_APP_RECOVER_PASSWORD_CALLBACK_URL as string)
  : (process.env.REACT_APP_RECOVER_PASSWORD_CALLBACK_URL_PROD as string);
