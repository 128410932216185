import { supabaseClient } from 'src/utils/supabaseClient';

export function areKeysEmpty(obj: { [key: string]: any }) {
  return Object.values(obj)
    .map((value) => value === null || value === undefined || value === '')
    .some(Boolean);
}

export function useOnboardingService() {
  const getFirstLocationForOnboarding = async () => {
    const { data: userconnected, error: errUserConnect } = await supabaseClient.auth.getUser();
    if (errUserConnect) {
      throw errUserConnect;
    }
    const { data: userCompany, error: userCompanyError } = await supabaseClient
      .rpc('get_location_members_by_user', {
        p_user_id: userconnected?.user.id,
        search_text: '',
      })
      .select('*')
      .limit(1)
      .single();
    if (userCompanyError) {
      throw new Error(userCompanyError.message);
    }
    return userCompany?.locations;
  };
  return {
    getFirstLocationForOnboarding,
  };
}
