export enum WalktourPageEnum {
  DASHBOARD = 'dashboard',
  CONTACTS = 'contacts',
  INBOX = 'inbox',
  REVIEWS = 'reviews',
  CAMPAIGNS = 'campaigns',
  AUTOMATION = 'automation',
  EMBED = 'embed',
  EMBED_SELECT = 'embed_select',
  EMBED_CONFIGURATION = 'embed_configuration',
  FIRST_EMBED = 'first_embed',
  FIRST_CONTACT = 'first_contact',
  FIRST_AUTOMATION = 'first_automation',
  FIRST_CAMPAIGN = 'first_campaign',
}
