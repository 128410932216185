import { Roles, account_type } from 'src/enumerate/roles';
import { supabaseClient } from 'src/utils/supabaseClient';
import { CreateLogsForm } from 'src/types/logs.type';
import { LogsActions } from 'src/enumerate/logsActions';
import { CALL_BACK_URL } from 'src/config-global';
import { createNewLog } from './logs';

export type Provider = 'twitter' | 'facebook' | 'google';
/**
  Logs in an existing user with Supabase authentication.
  @param {Object} params - The login parameters.
  @param {string} params.email - The user's email address.
  @param {string} params.password - The user's password.
  @returns {Promise<Object>} - The authentication data of the logged-in user.
  @throws {Error} - If an error occurs during the login process.
  */
export async function login({ email, password }: { email: string; password: string }) {
  const { data: user, error } = await supabaseClient.auth.signInWithPassword({
    email,
    password,
  });
  if (error) {
    throw error;
  }
  const { data, error: profilesError } = await supabaseClient
    .from('profiles')
    .select('*')
    .eq('email', email);

  if (profilesError) {
    throw new Error(profilesError.message);
  }
  if (!data || data.length === 0) {
    throw new Error(
      'There is no user with this email, probably you need to register first or you are using the wrong email'
    );
  }
  if (data[0]?.is_activated === false && data[0]?.role !== Roles.ADMIN) {
    throw new Error('Your account is deactivated, please contact the administrator');
  }

  return user;
}

/**
Registers a new user with Supabase authentication.
@param {Object} params - The registration parameters.
@param {string} params.email - The user's email address.
@param {string} params.password - The user's password.
@param {Object} [params.data] - Additional data to be associated with the user.
@returns {Promise<Object>} - The authentication data of the registered user.
@throws {Error} - If an error occurs during the registration process.
*/
export async function register({
  email,
  password,
  data,
}: {
  email: string;
  password: string;
  data?: any;
}) {
  const { data: emailExist, error } = await supabaseClient.rpc('check_user_existence', {
    email_param: email,
  });
  if (error) {
    throw new Error(error.message);
  }
  if (emailExist) {
    throw new Error('Email already exist');
  }

  const { data: authData, error: supaBaseError } = await supabaseClient.auth.signUp({
    email,
    password,

    options: {
      data: {
        ...data,
        is_password_enter: false,
        is_invited: false,
        first_sign_in: true,
        location_role: account_type.OWNER,
        company_onboarding: true,
        locations_onboarding: true,
        integrations_facebook_onboarding: true,
        integrations_google_onboarding: true,
      },
      emailRedirectTo: `${CALL_BACK_URL}auth/email-verified`,
    },
  });
  if (supaBaseError) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.REGISTER,
      message: supaBaseError.message,
      is_error: true,
      user_email: email,
      entry_data: { ...data, email },
    };

    createNewLog(newLogsForm);

    throw supaBaseError;
  }

  const newLogsForm: CreateLogsForm = {
    action: LogsActions.REGISTER,
    message: 'User registered successfully',
    is_error: false,
    user_email: email,
    user_id: authData?.user?.id,
  };
  createNewLog(newLogsForm);
  return authData;
}

/**

  Signs out the currently authenticated user.
  @returns {Promise<void>} - Resolves when the sign out process is complete.
  */
export async function signOut() {
  await supabaseClient.auth.signOut();
}

export async function getSession() {
  const { data, error } = await supabaseClient.auth.getSession();

  if (error) {
    throw error;
  }
  if (!data?.session) {
    throw new Error('No session');
  }
  return data;
}

/**
 * Reset password
 * @param {strign} params - The email where the recover password link code will be sent.
 * @returns
 */
export async function recoverPassword(email: string) {
  const { data, error } = await supabaseClient.auth.resetPasswordForEmail(email, {
    redirectTo: `${CALL_BACK_URL}auth/reset-password/`,
  });
  if (error) {
    throw error;
  }

  return data;
}

export async function updatePassword(password: string) {
  const { data, error } = await supabaseClient.auth.updateUser({
    password,
  });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_PASSWORD,
      message: error.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }

  const newLogsForm: CreateLogsForm = {
    action: LogsActions.UPDATE_PASSWORD,
    message: 'Password updated successfully',
    is_error: false,
    user_email: data?.user?.email,
    user_id: data?.user?.id,
  };
  createNewLog(newLogsForm);
  return data;
}

export async function verifySession() {
  const { data, error } = await supabaseClient.auth.getUser();
  const { data: profile, error: profilesError } = await supabaseClient
    .from('profiles')
    .select('*')
    .eq('email', data?.user?.email);

  if (profilesError) {
    throw new Error(profilesError.message);
  }
  if (!profile || profile.length === 0) {
    throw new Error(
      'There is no profile with this email, probably you need to register first or contact the administrator'
    );
  }

  if (error) {
    throw error;
  }
  return data;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function signWithProvider(provider: Provider, redirectTo?: string) {
  const { data, error } = await supabaseClient.auth.signInWithOAuth({
    provider,
    options: {
      queryParams: {
        account_type: 'offline',
        prompt: 'consent',
      },
    },
  });

  if (error) {
    if (error.message.toLowerCase().includes('user not found')) {
      // Handle user not found error
      throw new Error('User not found');
      // You can redirect to a signup page or show a specific message to the user
    } else {
      throw new Error(error.message);
    }
  }
  return data;
}

/**
 * GET AUTHENTICATED USER
 * @returns
 * @throws {Error} - If an error occurs during the login process.
 */
export async function getAuthenticatedUser() {
  const { data, error } = await supabaseClient.auth.getUser();

  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.GET_USER,
      message: error.message,
      is_error: true,
    };
    createNewLog(newLogsForm);

    throw error;
  }
  return data?.user;
}
