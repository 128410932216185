import { createSlice } from '@reduxjs/toolkit';

interface IUser {
  id: string | null;
  email: string | null;
  aud: string | null;
  role: string | null;
  email_confirmed_at: string | null;
  phone: string | null;
  confirmation_sent_at: string | null;
  confirmed_at: string | null;
  last_sign_in_at: string | null;
  app_metadata: any;
  user_metadata: any;
  identities: any;
  created_at: string | null;
  updated_at: string | null;
  locations: any;
  profile: any;
  company: any;
}

const initialState: IUser = {
  id: null,
  email: null,
  aud: null,
  role: null,
  email_confirmed_at: null,
  phone: null,
  confirmation_sent_at: null,
  confirmed_at: null,
  last_sign_in_at: null,
  app_metadata: null,
  user_metadata: null,
  identities: null,
  created_at: null,
  updated_at: null,
  locations: null,
  profile: null,
  company: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userInfoUpdated(state, action) {
      const userInfo = action.payload;
      return {
        ...state,
        ...userInfo,
      };
    },
    userReset() {
      return initialState;
    },
  },
});

export const { userInfoUpdated, userReset } = userSlice.actions;
export default userSlice.reducer;
