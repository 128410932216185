/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, Divider, SxProps, Typography, Theme, useTheme, Palette } from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import Iconify from 'src/components/iconify';

type Props = {
  icon: string;
  title: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  startAnimation?: boolean;
  handleChangeAnimation?: () => void;
  setStartAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps<Theme>;
};

export default function OnboardingMenu({
  title,
  icon,
  sx,
  color,
  startAnimation,
  handleChangeAnimation,
  setStartAnimation,
}: Props) {
  const theme = useTheme();
  const getColorCodeFromPalette = theme.palette[color! as keyof Palette] as any;
  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        width: '53vh',
        boxShadow: '-10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
        height: '10vh',
        padding: '2vh',
        transition: 'transform 0.7s ease-in-out',
        transform: startAnimation ? 'translateX(70px)' : 'translateX(0px)',
        ...sx,
      }}
      onClick={() => {
        if (handleChangeAnimation) {
          handleChangeAnimation();
        }
      }}
    >
      <CustomAvatar sx={{ width: '50px', height: '50px' }} color={color}>
        <Iconify icon={icon} color="#fff" width={30} height={30} />
      </CustomAvatar>
      <Typography variant="h6" sx={{ marginLeft: '10px' }}>
        {' '}
        {title}{' '}
      </Typography>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          marginLeft: 'auto',
          backgroundColor: getColorCodeFromPalette?.main,
          height: '100%',
          width: '0.4vh',
          borderRadius: '5vh',
          zIndex: 1,
        }}
      />
    </Card>
  );
}
