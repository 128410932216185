import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const RatingPage = lazy(() => import('src/pages/feedbackRequest/ratingPage'));
const CampaignReviewsPage = lazy(() => import('src/pages/feedbackRequest/campaign-reviews-page'));

// ----------------------------------------------------------------------

export const feedbackRequestRoutes = [
  {
    path: 'feedback-request/:id',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ element: <RatingPage />, index: true }],
  },
  {
    path: 'reviews/:id',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ element: <CampaignReviewsPage />, index: true }],
  },
];
