import { Template } from 'src/enumerate/template';
import { supabaseClient } from 'src/utils/supabaseClient';
import axiosBackendInstance from 'src/utils/axios-backend-instance';
import { uploadImage } from './supabaseUpload';
import { useCompany } from './company';

export function useTemplate() {
  const { company, userConnect } = useCompany();
  const getTemplate = async ({ rowPerPage = 4, currentPage = 0, searchText = '' }) => {
    const { data, error } = await supabaseClient
      .from('template')
      .select('*')
      .eq('company_id', company?.id)
      .eq('type', Template.EMAIL)
      .ilike('name', `%${searchText}%`)
      .range(currentPage * rowPerPage, currentPage * rowPerPage + rowPerPage - 1);
    if (error) {
      throw new Error(error.message);
    }
    const { count } = await supabaseClient
      .from('template')
      .select('id', { count: 'exact' })
      .eq('company_id', company?.id)
      .ilike('name', `%${searchText}%`)
      .eq('type', Template.EMAIL);
    return { data, count };
  };
  const getTemplateWithoutPagination = async () => {

    const { data, error } = await supabaseClient
      .from('template')
      .select('*')
      .eq('company_id', company?.id);
      
    if (error) {
      throw new Error(error.message);
    }

    return data;
  };
  const getTemplateSms = async () => {

    const { data, error } = await supabaseClient
      .from('template_sms')
      .select('*')
      .eq('company_id', company?.id);
      
    if (error) {
      throw new Error(error.message);
    }

    return data;
  };
  const getTextTemplate = async ({ rowPerPage = 4, currentPage = 0, searchText = '' }) => {
    const { data, error } = await supabaseClient
      .from('template')
      .select('*')
      .eq('company_id', company?.id)
      .eq('type', Template.TEXT)
      .ilike('name', `%${searchText}%`)
      .range(currentPage * rowPerPage, currentPage * rowPerPage + rowPerPage - 1);
    if (error) {
      throw new Error(error.message);
    }
    const { count } = await supabaseClient
      .from('template')
      .select('id', { count: 'exact' })
      .eq('company_id', company?.id)
      .eq('type', Template.TEXT)
      .ilike('name', `%${searchText}%`);
    return { data, count };
  };
  const getTextTemplateWithoutPagination = async () => {
    const { data, error } = await supabaseClient
      .from('template')
      .select('*')
      .eq('company_id', company?.id)
      .eq('type', Template.TEXT);
    if (error) {
      throw new Error(error.message);
    }

    return data;
  };
  const addTemplate = async (inputData: any) => {
    try{
      await axiosBackendInstance.post(`/template/email`, inputData);
    }catch(error){
      throw new Error(error.message);
    }
    
  };

  const addSmsTemplate = async (inputData: any) => {
    try{
      await axiosBackendInstance.post(`/template/sms`, inputData);
    }catch(error){
      throw new Error(error.message);
    }
  };

  const getTemplateById = async (id: string, type?: Template) => {
    const { data, error } = await supabaseClient
      .from('template')
      .select('*')
      .eq('id', id)
      .eq('type', type || Template.EMAIL)
      .single();
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const updateTemplateById = async (id: string, inputData: any) => {
    const { data, error } = await supabaseClient
      .from('template')
      .update(inputData)
      .eq('id', id)
      .eq('type', inputData.type || Template.EMAIL);

    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const deleteTemplateById = async (id: string, type?: Template) => {
    const { data, error } = await supabaseClient
      .from('template')
      .delete()
      .eq('id', id)
      .eq('type', type || Template.EMAIL);

    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const bulkDeleteTemplateById = async (id: string[], type?: Template) => {
    const { data, error } = await supabaseClient
      .from('template')
      .delete()
      .in('id', id)
      .eq('type', type || Template.EMAIL);

    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const SettingsTemplate = async (data: any) => {
    if (data?.email_logo_url && typeof data?.email_logo_url === 'object') {
      const url = await uploadImage(
        data?.email_logo_url,
        `template`,
        `${userConnect.id}_email_logo_url`
      );
      data.email_logo_url = url;
    } else {
      delete data.email_logo_url;
    }

    if (data?.sms_logo_url && typeof data?.sms_logo_url === 'object') {
      const url = await uploadImage(
        data?.sms_logo_url,
        `template`,
        `${userConnect.id}_sms_logo_url`
      );
      data.sms_logo_url = url;
    } else {
      delete data.sms_logo_url;
    }

    const finalData = {
      ...data,
      user_id: userConnect.id,
    };
    const { data: tmpSelectData, error: templateSelectError } = await supabaseClient
      .from('template_user_settings')
      .select('*')
      .eq('user_id', userConnect.id);
    if (templateSelectError) {
      throw new Error(templateSelectError.message);
    }
    if (tmpSelectData.length > 0) {
      const { error: tmpError } = await supabaseClient
        .from('template_user_settings')
        .update(finalData)
        .eq('user_id', userConnect.id);
      if (tmpError) {
        throw new Error(tmpError.message);
      }
    } else {
      const { error: templateError } = await supabaseClient
        .from('template_user_settings')
        .insert(finalData);
      if (templateError) {
        throw new Error(templateError.message);
      }
    }
  };

  const getSettingsTemplate = async (id?:string) => {
    const { data, error } = await supabaseClient
      .from('template_user_settings')
      .select('*')
      .eq('user_id', id || userConnect.id)
      .maybeSingle();
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  const getSettingsTemplateByLocationId = async (id: string) => {
    const { data, error } = await supabaseClient
      .from('email_location_template')
      .select('*')
      .eq('location_id', id)
      .maybeSingle();
    if (error) {
      throw new Error(error.message);
    }
    return data;
  };
  return {
    getTemplate,
    getTemplateSms,
    getTemplateWithoutPagination,
    getTextTemplate,
    getTextTemplateWithoutPagination,
    addTemplate,
    getTemplateById,
    updateTemplateById,
    deleteTemplateById,
    addSmsTemplate,
    bulkDeleteTemplateById,
    SettingsTemplate,
    getSettingsTemplate,
    getSettingsTemplateByLocationId,
  };
}
