import { supabaseClient } from 'src/utils/supabaseClient';
import { LogsActions } from 'src/enumerate/logsActions';
import { CreateLogsForm } from 'src/types/logs.type';
import { CompanyLocation } from 'src/types/CompanyLocation';
import { store, useSelector } from 'src/redux/store';
import { userInfoUpdated } from 'src/redux/slices/user.slice';
import { useDispatch } from 'react-redux';
import { account_type } from 'src/enumerate/roles';
import axiosBackendWithoutVirsionInstance from 'src/utils/axios-backend-without-virsion';
import { usePayment } from 'src/hooks/use-payment';
import { createNewLog } from './logs';
import { uploadImage } from './supabaseUpload';

export function useLocation() {
  const {
    user: userConnect,
    user: { company },
  } = useSelector((state: any) => state);
  const { isTestUser } = usePayment();
  const reduxDispatch = useDispatch();
  const getUnlinkedLocations = async () => {
    const {
      data: { user },
    } = await supabaseClient.auth.getUser();
    if (!user) {
      throw new Error('Not authenticated');
    }
    const userAssignedLocation = (await getUserAssignedLocation(user?.id, null)) as any[];
    const mappedId = userAssignedLocation?.map((location) => location?.id);
    const query = supabaseClient
      .from('google_business_locations')
      .select('...location_id(name,id)')
      .not('location_id.id', 'in', `(${mappedId.join(',')})`);
    const { data, error } = await query;

    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATIONS,
        message: error.message,
        is_error: true,
      };
      createNewLog(newLogsForm);

      return [];
    }
    if (!data || data?.length === 0) return userAssignedLocation;

    const difference1 = userAssignedLocation.filter((value) =>
      data.map((elem: any) => elem?.id).includes(value.id)
    );
    const difference2 = data.filter((value: any) =>
      userAssignedLocation.map((elem: any) => elem?.id).includes(value.id)
    );

    return [...difference1, ...difference2] || [];
  };

  const add_location_template = async (location_id: string, templateData: any) => {
    const { error, data } = await supabaseClient
      .from('email_location_template')
      .select('*')
      .eq('location_id', location_id);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATIONS,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify(location_id, templateData)),
      };
      createNewLog(newLogsForm);

      throw new Error('Error fetching location template');
    }
    if (data && data.length > 0) {
      if (templateData?.logo_url instanceof File) {
        const url = await uploadImage(templateData?.logo_url, 'template', location_id);
        templateData.logo_url = url;
      } else {
        delete templateData.logo_url;
      }
      if (templateData?.email_logo_url instanceof File) {
        const url = await uploadImage(templateData?.email_logo_url, 'template', location_id);
        templateData.email_logo_url = url;
      } else {
        delete templateData.email_logo_url;
      }
      if (templateData?.sms_logo_url instanceof File) {
        const url = await uploadImage(
          templateData?.sms_logo_url,
          'template',
          `${location_id}_text`
        );
        templateData.sms_logo_url = url;
      } else {
        delete templateData.sms_logo_url;
      }
      const { error: errorUpdate } = await supabaseClient
        .from('email_location_template')
        .update(templateData)
        .eq('location_id', location_id);
      if (errorUpdate) {
        const newLogsForm: CreateLogsForm = {
          action: LogsActions.UPDATE_LOCATION,
          message: `${errorUpdate.message} | Error updating location template`,
          is_error: true,
          entry_data: JSON.parse(JSON.stringify(location_id, templateData)),
        };
        createNewLog(newLogsForm);
        throw new Error('Error updating location template');
      }
      reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));
    } else {
      if (templateData?.email_logo_url instanceof File) {
        const url = await uploadImage(templateData?.email_logo_url, 'template', location_id);
        templateData.email_logo_url = url;
      } else {
        delete templateData.logo_url;
      }
      if (templateData?.sms_logo_url instanceof File) {
        const url = await uploadImage(
          templateData?.sms_logo_url,
          'template',
          `${location_id}_text`
        );
        templateData.sms_logo_url = url;
      } else {
        delete templateData.sms_logo_url;
      }
      const finalTemplateData = { ...templateData, location_id };
      const { error: errorInsert } = await supabaseClient
        .from('email_location_template')
        .insert(finalTemplateData);
      if (errorInsert) {
        const newLogsForm: CreateLogsForm = {
          action: LogsActions.ADD_LOCATION_TEMPLATE,
          message: `${errorInsert.message} | Error inserting location template`,
          is_error: true,
          entry_data: JSON.parse(JSON.stringify(location_id, templateData)),
        };
        createNewLog(newLogsForm);
        throw new Error('Error inserting location template');
      }
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.ADD_LOCATION_TEMPLATE,
      message: `Location template updated successfully`,
      is_error: false,
    };
    createNewLog(newLogsForm);
    reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));
  };
  const sms_location_template = async (location_id: string, templateData: any) => {
    const { error, data } = await supabaseClient
      .from('sms_location_template')
      .select('*')
      .eq('location_id', location_id);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATIONS,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify(location_id, templateData)),
      };
      createNewLog(newLogsForm);

      throw new Error('Error fetching location template');
    }
    if (data && data.length > 0) {
      if (templateData?.sms_logo_url instanceof File) {
        const url = await uploadImage(templateData?.sms_logo_url, 'template', location_id);
        templateData.sms_logo_url = url;
      } else {
        delete templateData.sms_logo_url;
      }

      const { error: errorUpdate } = await supabaseClient
        .from('sms_location_template')
        .update(templateData)
        .eq('location_id', location_id);
      if (errorUpdate) {
        const newLogsForm: CreateLogsForm = {
          action: LogsActions.UPDATE_LOCATION,
          message: `${errorUpdate.message} | Error updating location template`,
          is_error: true,
          entry_data: JSON.parse(JSON.stringify(location_id, templateData)),
        };
        createNewLog(newLogsForm);
        throw new Error('Error updating location template');
      }
      reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));
    } else {
      if (templateData?.sms_logo_url instanceof File) {
        const url = await uploadImage(templateData?.sms_logo_url, 'template', location_id);
        templateData.sms_logo_url = url;
      } else {
        delete templateData.logo_url;
      }

      const finalTemplateData = { ...templateData, location_id };
      const { error: errorInsert } = await supabaseClient
        .from('sms_location_template')
        .insert(finalTemplateData);
      if (errorInsert) {
        const newLogsForm: CreateLogsForm = {
          action: LogsActions.ADD_LOCATION_TEMPLATE,
          message: `${errorInsert.message} | Error inserting location template`,
          is_error: true,
          entry_data: JSON.parse(JSON.stringify(location_id, templateData)),
        };
        createNewLog(newLogsForm);
        throw new Error('Error inserting location template');
      }
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.ADD_LOCATION_TEMPLATE,
      message: `Location template updated successfully`,
      is_error: false,
    };
    createNewLog(newLogsForm);
    reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));
  };
  const get_location_template = async (location_id: string) => {
    const { error, data } = await supabaseClient
      .from('email_location_template')
      .select('*')
      .eq('location_id', location_id)
      .single();
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATION_TEMPLATE,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify(location_id)),
      };
      createNewLog(newLogsForm);
      throw new Error('Error fetching location template');
    }
    return data;
  };
  /**
   * ADD Location SERVICE
   * @param {object} data
   * @returns {Promise<object>} - Resolves with the company data.
   * @throws {Error}
   *
   */
  const addLocation = async ({ data }: { data: any }): Promise<any> => {
    const {
      user: { my_locations },
    } = store.getState();
    const finaleData = {
      ...data,
      company_id: company?.id,
      timezone_id: data.timezone,
    };
    delete finaleData.timezone;
    if (my_locations.length > 0 && !isTestUser) {
      await axiosBackendWithoutVirsionInstance.get('/stripe/subscription/incrementQuantity');
    }
    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('locations')
      .insert([{ ...finaleData }])
      .select('id')
      .maybeSingle();
    if (locationDataError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.CREATE_LOCATION,
        message: locationDataError.message,
        is_error: true,
        user_email: userConnect.email,
        user_id: userConnect.id,
        entry_data: JSON.parse(JSON.stringify({ data, company_id: company?.id })),
      };
      createNewLog(newLogsForm);
      throw new Error(locationDataError.message);
    }
    const { error: companyLocationError } = await supabaseClient.from('users_locations').insert([
      {
        location_id: locationData?.id,
        user_id: userConnect.id,
        role: account_type.OWNER,
      },
    ]);
    if (companyLocationError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.CREATE_LOCATION,
        message: companyLocationError.message,
        is_error: true,
        user_email: userConnect.email,
        user_id: userConnect.id,
        entry_data: JSON.parse(JSON.stringify({ data, company_id: company?.id })),
      };
      createNewLog(newLogsForm);
      throw new Error(companyLocationError.message);
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.CREATE_LOCATION,
      message: 'Location created successfully',
      is_error: false,
      user_email: userConnect.email,
      user_id: userConnect.id,
    };
    createNewLog(newLogsForm);
    const locationsOwn = await getLocationsOwn(userConnect?.id as string);

    reduxDispatch(
      userInfoUpdated({
        locations: await getLocationsByUserId(userConnect.id),
        my_locations: locationsOwn,
      })
    );

    return locationData;
  };

  /**
   * GET Location SERVICE - Get Locations by user id and location_id of user_company is null.
   * @param {object} data
   * @returns {Promise<object>} - Resolves with the company data.
   * @throws {Error}
   */
  const UpdateLocationByUserId = async (data: any, isOnboarding?: boolean) => {
    const { data: company_result, error: companyError } = await supabaseClient
      .from('user_company')
      .select('company_id')
      .eq('user_id', userConnect.id)
      .single();
    if (companyError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_LOCATION,
        message: companyError.message,
        is_error: true,
        user_email: userConnect.email,
        user_id: userConnect.id,
        entry_data: JSON.parse(JSON.stringify(data)),
      };
      createNewLog(newLogsForm);
      throw new Error(companyError.message);
    }
    delete data.timezone;

    const finale_data = {
      ...data,
      company_id: company_result?.company_id,
    };

    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('locations')
      .insert([{ ...finale_data }])
      .select('id')
      .single();
    if (locationDataError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_LOCATION,
        message: locationDataError.message,
        is_error: true,
        user_email: userConnect.email,
        user_id: userConnect.id,
        entry_data: JSON.parse(JSON.stringify(data)),
      };
      createNewLog(newLogsForm);
      throw new Error(locationDataError.message);
    }
    const { data: testExists } = await supabaseClient
      .from('users_locations')
      .select('id')
      .eq('user_id', userConnect.id)
      .eq('location_id', locationData?.id);

    if (testExists?.length === 0) {
      if (isOnboarding) {
        const { error: companyLocationError } = await supabaseClient
          .from('users_locations')
          .insert([
            {
              location_id: locationData?.id,
              user_id: userConnect.id,
              role: account_type.OWNER,
            },
          ]);
        if (companyLocationError) {
          const newLogsForm: CreateLogsForm = {
            action: LogsActions.UPDATE_LOCATION,
            message: companyLocationError.message,
            is_error: true,
            user_email: userConnect.email,
            user_id: userConnect.id,
            entry_data: JSON.parse(JSON.stringify(data)),
          };
          createNewLog(newLogsForm);
          throw new Error(companyLocationError.message);
        }
      } else {
        const { error: companyLocationError } = await supabaseClient
          .from('users_locations')
          .insert([
            {
              location_id: locationData?.id,
              user_id: userConnect.id,
            },
          ]);
        if (companyLocationError) {
          const newLogsForm: CreateLogsForm = {
            action: LogsActions.UPDATE_LOCATION,
            message: companyLocationError.message,
            is_error: true,
            user_email: userConnect.email,
            user_id: userConnect.id,
            entry_data: JSON.parse(JSON.stringify(data)),
          };
          createNewLog(newLogsForm);
          throw new Error(companyLocationError.message);
        }
      }
    }

    /* update user */
    const { error: userError } = await supabaseClient.auth.updateUser({
      data: {
        ...userConnect?.user_metadata,
        first_sign_in: false,
      },
    });
    if (userError) {
      throw new Error(userError.message);
    }

    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_LOCATION,
      message: 'Location updated successfully',
      is_error: false,
      user_email: userConnect.email,
      user_id: userConnect.id,
    };
    createNewLog(newLogsForm);
    reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));
  };

  /**
   * ASSIGN SELECTED LOCATIONS TO USERCOMPANY SERVICE .
   * @returns {Promise<object>}
   * @throws {Error}
   */
  const assignSelectedLocationsToUserCompany = async (locationId: string) => {
    const { error: companyLocationError } = await supabaseClient
      .from('locations')
      .update([
        {
          location_id: locationId,
        },
      ])
      .eq('user_id', userConnect.id)
      .is('location_id', null);
    if (companyLocationError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.ASSIGN_LOCATION,
        message: companyLocationError.message,
        is_error: true,
        user_email: userConnect.email,
        user_id: userConnect.id,
        entry_data: JSON.parse(JSON.stringify({ locationId })),
      };
      createNewLog(newLogsForm);
      throw new Error(companyLocationError.message);
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.ASSIGN_LOCATION,
      message: 'Location assigned successfully',
      is_error: false,
      user_email: userConnect.email,
      user_id: userConnect.id,
    };
    createNewLog(newLogsForm);
    reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));
  };

  /**
   * UPDATE Location SERVICE
   * @param {object} data
   * @returns {Promise<object>} - Resolves with the company data.
   * @throws {Error}
   *
   */
  const updateLocation = async ({ data, id }: { data: any; id: string }): Promise<any[]> => {
    const finaleData = {
      ...data,
      timezone_id: data.timezone,
    };
    delete finaleData.timezone;
    const { data: updatedLocation, error: locationDataError } = await supabaseClient
      .from('locations')
      .update(finaleData)
      .eq('id', id)
      .select('*')
      .maybeSingle();
    if (locationDataError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_LOCATION,
        message: locationDataError.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ data, id })),
      };
      createNewLog(newLogsForm);
      throw new Error(locationDataError.message);
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_LOCATION,
      message: 'Location updated successfully',
      is_error: false,
    };
    createNewLog(newLogsForm);

    reduxDispatch(userInfoUpdated({ locations: await getLocationsByUserId(userConnect.id) }));

    return updatedLocation;
  };

  /**
   * DELETE Location SERVICE
   * @param {object} data
   * @returns {Promise<object>} - Resolves with the company data.
   * @throws {Error}
   *
   */
  const deleteLocation = async (ids: String[]) => {
    try {
      const {
        user: { my_locations },
      } = store.getState();
      if (my_locations.length === 1 && !isTestUser) {
        throw new Error('At least one should be available for the user');
      }
      await axiosBackendWithoutVirsionInstance.get('/stripe/subscription/decrementQuantity');
      const { error: locationDataError } = await supabaseClient
        .from('locations')
        .delete()
        .in('id', ids);
      if (locationDataError) {
        const newLogsForm: CreateLogsForm = {
          action: LogsActions.DELETE_LOCATION,
          message: locationDataError.message,
          is_error: true,
          entry_data: JSON.parse(JSON.stringify({ ids })),
        };
        createNewLog(newLogsForm);
        throw new Error(locationDataError.message);
      }
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.DELETE_LOCATION,
        message: 'Location deleted successfully',
        is_error: false,
      };

      const locationsOwn = await getLocationsOwn(userConnect?.id as string);

      reduxDispatch(
        userInfoUpdated({
          locations: await getLocationsByUserId(userConnect.id),
          my_locations: locationsOwn,
        })
      );

      createNewLog(newLogsForm);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  /**
   * GET LOCATIONS SERVICE - Get the locations ids data by userConnect.
   * @returns {Promise<object>} - Resolves with the locations data.
   * @throws {Error}
   */
  const getLocationsForAuthUser = async () => {
    const { data: userLocation, error: userLocationError } = (await supabaseClient
      .from('users_locations')
      .select('location_id')) as any;

    if (userLocationError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATIONS,
        message: userLocationError.message,
        is_error: true,
      };
      createNewLog(newLogsForm);
      throw new Error(userLocationError.message);
    }

    return userLocation;
  };

  /**
   * GET LOCATIONS SERVICE - Get the company data by userConnect.
   * @returns {Promise<object>} - Resolves with the company data.
   * @throws {Error}
   */
  const getLocations = async ({ currentPage = 0, rowPerPage = 4 }) => {
    const { data: allLocations, error: allLocationsError } = await supabaseClient
      .from('locations')
      .select('*,company(*),email_location_template(*),sms_location_template(*)')
      .range(currentPage * rowPerPage, currentPage * rowPerPage + rowPerPage - 1);

    if (allLocationsError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATIONS,
        message: allLocationsError.message,
        is_error: true,
      };
      createNewLog(newLogsForm);
      throw new Error(allLocationsError.message);
    }
    const { count } = await supabaseClient.from('locations').select('*', {
      count: 'exact',
      head: true,
    });

    return {
      data: allLocations,
      count,
    };
  };

  const getAllLocation = async (companyId: string): Promise<CompanyLocation[]> => {
    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('user_company')
      .select('location_id(*)')
      .eq('company_id', companyId);
    if (locationDataError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_LOCATIONS,
        message: locationDataError.message,
        is_error: true,
      };
      createNewLog(newLogsForm);

      throw new Error(locationDataError.message);
    }
    return locationData as unknown as CompanyLocation[];
  };

  const getLocationById = async (id: string) => {
    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('locations')
      .select(
        '*,email_location_template(*),sms_location_template(*),facebook_pages(id,facebook_providers(name)),google_business_locations(id,google_business_accounts(account_name))'
      )
      .eq('id', id)
      .maybeSingle();
    if (locationDataError) {
      throw new Error(locationDataError.message);
    }
    return locationData;
  };

  const getLocationsByUser = async () => {
    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('users_locations')
      .select('locations(*,email_location_template(*),sms_location_template(*))')
      .eq('user_id', userConnect?.id);
    if (locationDataError) {
      throw new Error(locationDataError.message);
    }
    return locationData;
  };
  const getLocationsByUserId = async (id: string) => {
    /*   const { user: userConnect } = store.getState(); */

    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('users_locations')
      .select(
        '...locations(*,email_location_template(*),sms_location_template(*),facebook_pages(id,facebook_providers(name)),google_business_locations(id,google_business_accounts(account_name)))'
      )
      .eq('user_id', id);
    if (locationDataError) {
      throw new Error(locationDataError.message);
    }
    return locationData;
  };
  const getLocationsOwn = async (id: string) => {
    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('users_locations')
      .select(
        'status,...locations(*,email_location_template(*),sms_location_template(*),facebook_pages(id,facebook_providers(name)),google_business_locations(id,google_business_accounts(account_name)))'
      )
      .eq('user_id', id)
      .in('role', [account_type.OWNER, account_type.MANAGER]);
    if (locationDataError) {
      throw new Error(locationDataError.message);
    }
    return locationData;
  };

  const getAccessLocations = async () => {
    const { data: locationData, error: locationDataError } = await supabaseClient
      .from('users_locations')
      .select(
        'status,...locations(*,email_location_template(*),sms_location_template(*), facebook_pages(id), google_business_locations(id))'
      );
    if (locationDataError) {
      throw new Error(locationDataError.message);
    }
    return locationData;
  };

  const getLocationsWithDependencies = async (): Promise<any[]> => {
    const { data: locationData, error: locationDataError } = await supabaseClient.rpc(
      'get_locations_with_dependencies'
    );
    if (locationDataError) {
      throw new Error(locationDataError.message);
    }
    return locationData || [];
  };
  return {
    getUserAssignedLocation,
    getUnlinkedLocations,
    add_location_template,
    get_location_template,
    addLocation,
    UpdateLocationByUserId,
    assignSelectedLocationsToUserCompany,
    updateLocation,
    deleteLocation,
    getLocationsForAuthUser,
    getLocations,
    getAllLocation,
    getLocationById,
    getLocationsByUser,
    getLocationsOwn,
    sms_location_template,
    getLocationsByUserId,
    getAccessLocations,
    getLocationsWithDependencies,
  };
}
export const getUserAssignedLocation = async (user_id: string, options?: any) => {
  const query = supabaseClient
    .from('users_locations')
    .select('...location_id(name,id)')
    .eq('user_id', user_id);

  // query.ilike('location_id.name', `%${options?.name}%`);

  const { data, error } = await query;

  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.GET_LOCATIONS,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(user_id, options)),
    };
    createNewLog(newLogsForm);
    return [];
  }
  return data || [];
};
