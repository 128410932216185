import { LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { isNaN } from 'lodash';
import React, { useEffect } from 'react';
import { fShortenNumber } from 'src/utils/format-number';

type UsageProps = {
  quota: number;
  used: number;
  caption: string;
};
type ProgressBarColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'inherit';
function UsageProgress({ quota, used, caption }: UsageProps) {
  const [color, setColor] = React.useState<ProgressBarColor>('primary');

  const progress = (used / quota) * 100;

  useEffect(() => {
    if (used < 0.5 * quota) {
      setColor('success');
    } else if (used < 0.8 * quota) {
      setColor('warning');
    } else {
      setColor('error');
    }
  }, [used, quota]);

  return (
    <Stack minWidth={150}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
        <Typography variant="caption">{caption}</Typography>
        <Typography variant="caption">{fShortenNumber(quota)}</Typography>
      </Stack>
      <Tooltip title={used}>
        <LinearProgress
          variant="determinate"
          value={isNaN(progress) ? 0 : progress}
          color={color}
          sx={{
            height: 8,
          }}
        />
      </Tooltip>
    </Stack>
  );
}

export default UsageProgress;
