import * as Yup from 'yup';
import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useLocation } from 'src/Services/location';
import { useGoogle } from 'src/Services/google';
import { useState } from 'react';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { useCompany } from 'src/Services/company';
import { ITimezone } from 'src/types/company';
import { countries } from 'src/assets/data';

type Props = {
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  isIntegraionsOnboarding?: boolean;
};
export default function LocationsOnBoardingFrom({ setActiveStep, isIntegraionsOnboarding }: Props) {
  const { UpdateLocationByUserId } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const LocationsSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address1: Yup.string().required('Address is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    timezone: Yup.string().required('Timezone is required'),
    country: Yup.string().required('Country is required'),
  });

  const defaultValues = {
    name: '',
    address1: '',
    email: '',
    timezone: '',
    country: '',
  };
  const methods = useForm({
    resolver: yupResolver(LocationsSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {
    const newData = { ...data }; // Create a new object by spreading 'data'

    try {
      await UpdateLocationByUserId(newData, true);
      enqueueSnackbar('Location added successfully!', {
        variant: 'success',
      });
      if (setActiveStep && isIntegraionsOnboarding) {
        setActiveStep(2);
      } else {
        window.location.reload();
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    }
  };
  const { searchGoogleLocations } = useGoogle();
  const watch_name = watch('name');
  const [enabled, setEnabled] = useState(false);
  const { data: locationData, isPending } = useQuery({
    queryFn: () => searchGoogleLocations(watch_name),
    queryKey: ['searchGoogleLocations', watch_name],
    enabled,
  });
  const handleSearch = () => {
    if (setEnabled) setEnabled(true);
  };
  const { getTimezoneList } = useCompany();

  const { data: timezoneList } = useQuery({
    queryFn: () => getTimezoneList() as ITimezone[] | any,
    queryKey: ['TimeZoneList'],
    retry: false,
  });

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" align="center">
        Location Settings
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          mb: 7,
        }}
      >
        Please fill the empty fields to get started.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <RHFTextField name="name" label="Location Name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField name="email" label="Location Email" />
        </Grid>
        <Grid item xs={12} md={12}>
          <RHFAutocomplete
            name="address1"
            label="Address"
            onMouseEnter={handleSearch}
            options={
              locationData?.results
                ? locationData?.results?.map((rslt: any) => rslt?.formatted_address)
                : []
            }
            loading={isPending}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFAutocomplete
            name="country"
            label="Country"
            options={countries ? countries?.map((_country) => _country?.label) : []}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => {
              if (!countries) return null;
              const { code, label, phone } = countries.filter(
                (_country) => _country?.label === option
              )[0]!;

              if (!label) {
                return null;
              }

              return (
                <li {...props} key={label}>
                  <Iconify
                    key={label}
                    icon={`circle-flags:${code?.toLowerCase()}`}
                    width={28}
                    sx={{ mr: 1 }}
                  />
                  {label} ({code}) +{phone}
                </li>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFSelect name="timezone" label="Timezone">
            {timezoneList?.map((timezone: any) => (
              <MenuItem key={timezone?.id} value={timezone?.id}>
                {timezone?.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
      </Grid>

      <Stack spacing={3} alignItems="center" sx={{ mt: 8 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                if (setActiveStep) setActiveStep(0);
              }}
            >
              Go back
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} fullWidth>
              {isIntegraionsOnboarding ? (
                <>
                  Save and continue <Iconify style={{ marginLeft: '5px' }} icon="mdi:arrow-right" />
                </>
              ) : (
                'Save and exit'
              )}
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
}
