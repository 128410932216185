import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { getCredits } from 'src/Services/usage';

interface UsageState {
  email: {
    quota: number;
    used: number;
  };
  sms: {
    quota: number;
    used: number;
  };
  isLoading: boolean;
  error: string | null;
}

const initialState: UsageState = {
  email: {
    quota: 0,
    used: 0,
  },
  sms: {
    quota: 0,
    used: 0,
  },
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // email
    setUsedEmail(state, action) {
      state.email.used = action.payload ?? 0;
    },
    setQuotaEmail(state, action) {
      state.email.quota = action.payload ?? 0;
    },
    increaseUsedEmailAmount(state, action) {
      state.email.used += action.payload;
    },
    descreaseUsedEmailAmount(state, action) {
      state.email.used -= action.payload;
    },
    // sms
    setUsedSMS(state, action) {
      state.sms.used = action.payload ?? 0;
    },
    setQuotaSMS(state, action) {
      state.sms.quota = action.payload ?? 0;
    },
    increaseUsedsmsAmount(state, action) {
      state.sms.used += action.payload;
    },
    descreaseUsedsmsAmount(state, action) {
      state.sms.used -= action.payload;
    },
    updateQuota(state, action) {
      const quotaInfo = action.payload;
      return {
        ...state,
        ...quotaInfo,
      };
    },
    resetUsage() {
      return {
        ...initialState,
      };
    },
  },
});
// Reducer
export default slice.reducer;

// Actions
export const { increaseUsedEmailAmount, setUsedEmail, setQuotaEmail, updateQuota, resetUsage } =
  slice.actions;

export function initCreditStatFn(credit: any) {
  return async (dispatch: Dispatch) => {
    try {
      const { sms_credits, email_credits } = credit;

      dispatch(slice.actions.startLoading());
      dispatch(slice.actions.setUsedEmail(email_credits.monthly_credits));
      dispatch(slice.actions.setQuotaEmail(email_credits.max_credits));
      dispatch(slice.actions.setUsedSMS(sms_credits.monthly_credits));
      dispatch(slice.actions.setQuotaSMS(sms_credits.max_credits));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      // handle error here
    }
  };
}
export function increaseUsedEmailAmountFn(amount: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.increaseUsedEmailAmount(amount));
    } catch (error) {
      // handle error here
    }
  };
}
export function descreaseUsedEmailAmountFn(amount: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.descreaseUsedEmailAmount(amount));
    } catch (error) {
      // handle error here
    }
  };
}
export function increaseUsedsmsAmountFn(amount: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.increaseUsedsmsAmount(amount));
    } catch (error) {
      // handle error here
    }
  };
}
export function descreaseUsedsmsAmountFn(amount: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.descreaseUsedsmsAmount(amount));
    } catch (error) {
      // handle error here
    }
  };
}
export function refetchUsageFn(company_id: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      // fetch data here
      const credits = await getCredits(company_id);
      initCreditStatFn(credits);
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}
