import { memo } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function PayementIllustration({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      width="653"
      height="653"
      viewBox="0 0 653 653"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        fill="#ECFAF6"
        d="M625.469 446.329c-11.419-25.033-31.265-46.904-42.605-74.555-5.422-13.226-7.836-27.433-5.795-41.686 2.258-15.843 10.125-29.645 18.459-42.917 32.433-51.703 50.8-135.325-11.512-172.307-20.874-12.4-46.733-20.282-70.708-23.756-15.889-2.306-32.433-1.433-47.839-6.48C419.592 69.61 342.653-3.31 296.48 50.542c-33.243 38.773-24.457 115.946-85.023 126.539-17.65 3.084-35.72 3.24-53.464 5.81-19.908 2.882-39.63 7.369-58.417 14.784-36.841 14.55-73.325 44.802-82.048 85.99-7.836 36.95 16.466 69.523 31.42 100.882 6.746 14.113 12.182 28.943 13.647 44.677 1.729 18.631-2.617 37.309-1.06 55.987 1.698 20.376-3.333 27.339-3.333 44.007 0 14.223 8.069 28.367 8.069 28.367h548.402c34.77-41.016 18.32-94.744 10.796-111.241v-.016z"
      />
      <path
        fill="#C4FCEA"
        d="M308.818 527.007c26.28-29.847 59.492-55.644 63.838-97.611 1.277-12.353 5.156-32.729-3.474-43.462-9.845-12.275-29.629-2.025-40.284 3.552-6.543 3.411-11.169 9.066-14.519 15.095 12.385-86.738 13.974-144.236-16.886-175.064-36.702-36.67-58.433 19.69-70.085 62.919-3.053-79.26-122.348-165.53-155.965-138.659-31.81 25.423 35.112 78.762 67.561 187.246C35.069 239.954-40.873 301.05 72.081 384.08c20.096 20.485 44.927 33.15 87.75 96.396-51.687-40.347-124.747 2.056-45.097 40.284 11.527 5.53 60.94 31.857 63.137 40.238 5.187 19.721 21.217 28.694 40.595 29.909 13.397.841 27.215.997 40.534-1.012 20.033-3.007 23.476-26.031 32.589-40.409 5.016-7.914 10.92-15.313 17.229-22.479z"
      />
      <path
        fill="#94C2C2"
        d="M98.735 174.589c11.528 7.555 20.843 17.65 29.505 28.181 1.573 1.9 4.876 6.09 6.356 7.882 1.106 1.589 8.412 11.917 9.705 13.771 2.196 3.069 5.109 8.116 7.165 11.387 2.119 3.521 5.063 7.992 6.948 11.528l6.356 11.839c3.77 6.589 8.708 17.073 11.917 24.083l5.686 12.167 5.078 12.431c2.835 7.243 7.431 17.681 9.861 24.971 2.96 8.708 10.608 29.489 13.023 38.072 3.162 10.562 7.446 24.8 10.593 35.315 3.365 13.382 9.113 35.424 12.369 48.712 1.106 5.141 5.826 27.37 6.979 32.76 1.464 7.088 4.221 19.083 5.327 26.265l9.222 52.793a3.196 3.196 0 01-2.601 3.707 3.21 3.21 0 01-3.723-2.726c-1.932-10.655-9.316-56.376-11.434-65.738-3.225-14.846-7.509-37.48-11.092-52.202-2.492-9.954-7.945-31.919-10.515-42.06-1.667-5.654-4.891-16.59-6.605-22.385a5652.579 5652.579 0 00-5.67-19.176c-4.206-11.731-10.842-32.543-15.594-43.946a8335.577 8335.577 0 00-9.938-24.722l-5.593-12.088c-3.131-6.901-7.976-17.354-11.699-23.896-2.056-3.755-7.228-13.943-9.596-17.479-3.24-5.14-7.056-11.932-10.484-16.933-2.321-3.193-8.957-13.163-11.543-16.185-6.62-8.552-14.067-17.214-22.074-24.489-3.302-2.913-6.807-5.841-10.328-8.038a2.24 2.24 0 01-.7-3.084 2.223 2.223 0 013.068-.701l.031-.016z"
      />
      <path
        stroke="#94C2C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.87"
        d="M276.587 302.623s-38.321 54.008-48.447 143.223M54.977 318.59s99.402 32.963 181.809 166.045M117.148 496.661s61.673.904 129.436 52.949M339.32 418.398s-52.015 41.001-87.673 140.886"
      />
      <path
        fill="#94C2C2"
        d="M73.219 567.088c16.17 21.576 36.81 35.378 64.367 36.904 28.212 1.558 56.159-3.754 84.214.748 23.476 3.754 41.484 18.584 65.443 18.803 23.164.202 47.388-16.762 65.894-29.583 16.279-11.294 66.268-21.933 112.846-8.427 16.045 4.642 54.071 13.879 77.266 13.879 45.176 0 71.425-41.826 71.425-41.826H66.271c2.29 3.178 4.596 6.371 6.948 9.518v-.016z"
      />
      <path
        fill="#FFB728"
        d="M111.776 515.871c26.773-8.241 41.796-36.626 33.554-63.399-8.241-26.773-36.626-41.795-63.399-33.554-26.773 8.242-41.796 36.627-33.554 63.399 8.241 26.773 36.626 41.796 63.399 33.554z"
      />
      <path
        fill="#FFD561"
        d="M110.608 514.652c26.106-7.59 41.117-34.905 33.527-61.011-7.589-26.106-34.905-41.117-61.01-33.527-26.106 7.589-41.117 34.905-33.528 61.011 7.59 26.105 34.905 41.116 61.011 33.527z"
      />
      <path
        fill="#FFB728"
        d="M96.85 506.039c21.346 0 38.649-17.303 38.649-38.648 0-21.345-17.303-38.649-38.648-38.649-21.346 0-38.65 17.304-38.65 38.649 0 21.345 17.304 38.648 38.65 38.648z"
      />
      <path
        fill="#C9A133"
        d="M115.824 485.336l4.206 6.247-6.465 4.362-4.019-5.966c-4.813 2.757-10.297 4.221-14.44 4.003l-3.023-12.026c4.502.156 9.487-1.153 13.023-3.536 3.069-2.072 3.646-3.599 2.742-4.954-3.333-4.954-18.397 10.998-28.29-3.692-4.283-6.356-4.112-14.238 2.54-20.594l-4.128-6.138 6.465-4.361 4.05 6.013c3.66-1.885 7.68-3.054 11.512-3.007l3.225 11.808c-4.487.156-8.116 1.215-10.92 3.1-3.163 2.134-3.412 3.941-2.509 5.281 3.178 4.72 18.289-11.169 28.056 3.349 4.097 6.076 4.066 13.771-2.025 20.08v.031z"
      />
      <path
        fill="#FFD561"
        d="M114.235 485.04l4.206 6.247-6.465 4.362-4.019-5.966c-4.813 2.757-10.297 4.221-14.44 4.003l-3.022-12.026c4.501.156 9.486-1.153 13.023-3.536 3.068-2.072 3.645-3.599 2.741-4.954-3.333-4.954-18.397 10.998-28.29-3.692-4.283-6.356-4.112-14.238 2.54-20.594l-4.128-6.137 6.465-4.362 4.05 6.028c3.66-1.884 7.68-3.053 11.512-3.006l3.225 11.808c-4.487.156-8.116 1.215-10.92 3.1-3.163 2.134-3.412 3.941-2.508 5.281 3.177 4.72 18.288-11.17 28.055 3.349 4.097 6.075 4.066 13.771-2.025 20.08v.015z"
      />
      <path
        fill="#FFB728"
        d="M165.564 277.884c25.868-10.752 38.121-40.437 27.37-66.304-10.752-25.868-40.437-38.121-66.305-27.37-25.867 10.752-38.12 40.437-27.369 66.305 10.752 25.867 40.437 38.12 66.304 27.369z"
      />
      <path
        fill="#FFD561"
        d="M190.17 252.937c12.101-24.345 2.175-53.89-22.171-65.991-24.345-12.101-53.89-2.175-65.991 22.17-12.101 24.345-2.175 53.891 22.17 65.991 24.345 12.101 53.891 2.176 65.992-22.17z"
      />
      <path
        fill="#FFB728"
        d="M184.347 236.457c3.004-21.132-11.693-40.699-32.825-43.703-21.133-3.004-40.699 11.693-43.703 32.825-3.004 21.133 11.692 40.699 32.825 43.703 21.133 3.004 40.699-11.692 43.703-32.825z"
      />
      <path
        fill="#C9A133"
        d="M159.161 253.647l2.259 7.181-7.446 2.337-2.15-6.854c-5.405 1.277-11.06 1.137-14.986-.265l.514-12.385c4.284 1.434 9.425 1.589 13.491.312 3.536-1.106 4.517-2.415 4.034-3.972-1.791-5.702-20.765 5.312-26.077-11.575-2.29-7.306.094-14.814 8.288-19.02l-2.213-7.057 7.447-2.337 2.181 6.917c4.05-.779 8.24-.732 11.886.389l-.265 12.244c-4.347-1.121-8.132-1.137-11.341-.124-3.645 1.137-4.393 2.804-3.91 4.362 1.714 5.436 20.703-5.515 25.953 11.184 2.196 6.995 0 14.363-7.633 18.678l-.032-.015z"
      />
      <path
        fill="#FFD561"
        d="M157.744 252.899l2.259 7.182-7.446 2.336-2.15-6.854c-5.406 1.262-11.06 1.137-14.986-.265l.514-12.384c4.284 1.433 9.425 1.589 13.491.311 3.536-1.106 4.517-2.414 4.034-3.972-1.791-5.701-20.765 5.312-26.077-11.574-2.29-7.306.093-14.815 8.287-19.021l-2.212-7.057 7.446-2.336 2.181 6.932c4.051-.779 8.241-.732 11.886.389l-.265 12.244c-4.346-1.121-8.131-1.137-11.34-.124-3.645 1.137-4.393 2.804-3.91 4.362 1.713 5.436 20.703-5.515 25.952 11.184 2.197 6.995 0 14.363-7.633 18.678l-.031-.031z"
      />
      <path
        fill="#FFB728"
        d="M406.11 142.549c5.11-20.742-7.562-41.699-28.304-46.81-20.742-5.11-41.699 7.563-46.809 28.305-5.111 20.742 7.562 41.699 28.304 46.809 20.742 5.11 41.699-7.562 46.809-28.304z"
      />
      <path
        fill="#FFD561"
        d="M395.136 159.849c14.661-14.661 14.661-38.432 0-53.093-14.662-14.662-38.432-14.662-53.094 0-14.661 14.661-14.661 38.432 0 53.093 14.662 14.661 38.432 14.661 53.094 0z"
      />
      <path
        fill="#FFB728"
        d="M383.884 158.468c13.91-8.454 18.333-26.584 9.879-40.494-8.454-13.91-26.584-18.333-40.494-9.879-13.91 8.454-18.333 26.584-9.879 40.494 8.454 13.91 26.584 18.333 40.494 9.879z"
      />
      <path
        fill="#C9A133"
        d="M368.295 153.232l-1.34 5.593-5.779-1.387 1.277-5.327c-4.035-1.278-7.68-3.599-9.705-6.044l5.187-7.914c2.244 2.602 5.546 4.736 8.708 5.483 2.742.655 3.895.187 4.191-1.012 1.059-4.424-15.671-4.642-12.54-17.774 1.355-5.686 5.857-9.659 12.867-9.207l1.309-5.483 5.779 1.386-1.293 5.39c2.944 1.075 5.67 2.742 7.633 4.907l-4.969 7.914c-2.415-2.431-4.876-3.926-7.368-4.534-2.836-.669-3.973.109-4.269 1.325-1.012 4.221 15.703 4.502 12.603 17.478-1.293 5.437-5.624 9.393-12.307 9.238l.016-.032z"
      />
      <path
        fill="#FFD561"
        d="M367.656 152.173l-1.34 5.592-5.779-1.386 1.277-5.328c-4.035-1.277-7.68-3.598-9.705-6.044l5.187-7.914c2.244 2.618 5.546 4.736 8.708 5.484 2.742.654 3.895.187 4.191-1.013 1.059-4.424-15.672-4.642-12.54-17.774 1.355-5.686 5.857-9.658 12.867-9.206l1.308-5.484 5.78 1.387-1.293 5.389c2.944 1.075 5.67 2.742 7.633 4.907l-4.969 7.914c-2.415-2.43-4.876-3.926-7.369-4.533-2.835-.67-3.972.109-4.268 1.324-1.013 4.222 15.703 4.502 12.603 17.478-1.293 5.437-5.624 9.394-12.307 9.238l.016-.031z"
      />
      <path
        fill="#333744"
        d="M562.082 138.605s-5.95 32.713 23.881 43.929c29.816 11.232 21.373 34.256 3.973 50.644-24.832 23.398-46.843-2.337-81.068 0-34.224 2.337-24.831-37.247-4.751-59.834 20.064-22.573 0-49.6 0-49.6l57.981 14.845-.016.016z"
      />
      <path
        fill="#6A7EC4"
        d="M529.821 347.816c2.71 20.921-13.911 91.66-19.722 115.026 6.231 20.47 6.496 33.275 6.231 47.248 0 0-4.361 31.888-4.346 37.356l-19.161 2.149c-.451-5.421-7.991-75.365-13.381-101.084-4.891-23.398 4.424-80.553 12.68-121.289 19.052-3.459 35.798 5.904 37.699 20.594z"
      />
      <path
        fill="#141C23"
        d="M500.519 547.071l-12.572 4.113c-6.745 1.511-11.87 3.037-15.593 4.284-2.414.794-3.941 2.819-2.71 4.658.451.685 1.152 1.121 2.04 1.261l37.854-.046c1.589 0 2.867-1.06 2.882-2.368v-2.041h.016v-9.845h-11.917v-.016z"
      />
      <path
        fill="#86A0FA"
        d="M559.512 319.152c5.343 7.321 2.913 26.435-2.867 42.839-6.994 19.893 8.864 58.9-5.125 96.162 13.008 14.908 13.273 33.228 13.008 47.216 0 0-.577 37.59-.561 43.073l-17.385 2.275c-.452-5.422-15.967-72.001-22.401-97.471-10.795-42.761-20.08-88.825-24.582-126.726 19.052-3.458 40.783-4.424 59.928-7.368h-.015z"
      />
      <path
        fill="#141C23"
        d="M552.004 547.071l-11.964 4.113c-6.745 1.511-11.871 3.037-15.594 4.284-2.414.794-3.941 2.819-2.71 4.658.452.685 1.153 1.121 2.041 1.261l37.854-.046c1.589 0 2.866-1.06 2.882-2.368v-2.041h.015v-9.845h-12.524v-.016z"
      />
      <path
        fill="#E3B4B1"
        d="M499.272 230.312l-51.563 35.05-.701.483c-4.283 2.913-10.078 1.963-13.256-2.041 0 0-38.82-49.21-38.836-49.21-7.041-9.565 6.185-20.329 14.129-11.481l40.253 48.104-13.957-1.573 46.235-41.827c5.873-5.312 14.954-4.86 20.266 1.013 5.842 6.356 4.596 16.699-2.57 21.497v-.015z"
      />
      <path
        fill="#7A72D9"
        d="M483.585 201.974l-31.654 30.564 16.404 21.311 43.633-36.203.966-24.785-7.882-2.274c-8.615-.078-17.479 7.742-21.467 11.403v-.016z"
      />
      <path
        fill="#fff"
        d="M457.976 226.697l-6.06 5.858 16.403 21.31 6.979-5.795c-5.53-7.322-11.341-14.409-17.322-21.373z"
      />
      <path
        fill="#E3B4B1"
        d="M391.084 197.832c.125.374.078.28.156.405-.592-2.119-2.555-3.972-4.128-5.733-2.088-2.118-4.455-4.128-6.932-6.044a2.822 2.822 0 01-.53-3.988 2.832 2.832 0 013.427-.825c6.091 3.022 12.914 6.278 16.887 12.197 3.801 6.621-6.481 11.216-8.88 3.972v.016z"
      />
      <path
        fill="#E3B4B1"
        d="M397.97 196.943c.109.499.062.327.156.53.171-.312-.888-1.994-1.246-2.555-2.586-3.739-5.951-6.963-9.409-10.094a2.823 2.823 0 01-.25-4.004 2.818 2.818 0 013.49-.576c4.455 2.539 8.926 5.296 12.789 8.895 1.714 1.713 3.567 3.567 4.175 6.106.95 6.434-8.428 8.07-9.705 1.698z"
      />
      <path
        fill="#E3B4B1"
        d="M390.43 205.418c-2.103-4.315-6.013-7.929-9.83-11.013-1.978-2.025-7.368-3.474-5.25-7.104a2.83 2.83 0 013.443-1.028c5.593 2.337 10.936 5.297 15.578 9.316 1.745 1.557 3.365 3.1 4.689 5.109 2.975 5.78-5.39 10.36-8.646 4.72h.016z"
      />
      <path
        fill="#E3B4B1"
        d="M390.29 211.96c-4.97-5.951-10.702-11.528-16.622-16.59a2.822 2.822 0 01-.343-3.988 2.827 2.827 0 013.568-.608 160.55 160.55 0 0110.437 6.667c3.489 2.446 6.714 4.83 10.016 7.649 5.188 4.876-2.336 12.198-7.072 6.87h.016z"
      />
      <path
        fill="#DB706C"
        d="M396.599 217.787c5.28 2.772 15.951-2.399 15.951-2.399 2.197-10.484-.342-16.31-2.741-19.691l-13.055-4.704s-4.564 4.393-4.58 4.393c-1.994 1.916-3.925 4.159-4.579 6.916-.873 3.754 4.019 12.852 9.004 15.469v.016z"
      />
      <path
        fill="#E3B4B1"
        d="M404.497 184.637c5.452 3.1 9.129 9.051 8.833 15.407-.203 4.362-1.387 10.998-7.228 9.642-2.945-.778-4.502-4.174-3.178-6.916.56-1.184 1.324-2.368 1.604-3.661 1.153-3.676-.919-7.555-3.598-10.032-2.259-2.415.747-6.122 3.551-4.455l.016.015z"
      />
      <path
        fill="#8E84FF"
        d="M552.502 212.257c-3.646-15.687-12.572-21.778-27.308-21.809-6.076 0-12.136-.047-18.211-.078a9.6 9.6 0 00-1.636.125c-7.82 1.448-12.82 7.586-12.758 14.363 0 .607-.015 1.199-.124 1.791-.437 2.477-2.01 9.596-7.618 23.694-1.698 4.253-4.486 15.656 4.533 23.678 1.979 1.761 3.225 4.206 3.287 6.87.436 19.784 0 42.419-3.209 59.585-.919 4.97 2.415 9.736 7.368 10.718 21.233 4.19 42.185 5.25 60.66 1.947 4.954-.888 9.487-5.826 7.571-10.484-16.201-39.396-10.001-99.418-12.555-110.416v.016z"
      />
      <path
        fill="#DB706C"
        d="M530.366 193.236s-14.052 13.117-17.432 11.014c-1.916-1.184-2.664-11.154-2.664-11.154s2.913-11.309-.67-23.117c2.399-5.281 16.824-6.372 20.937.732-1.932 10.297-.156 22.525-.156 22.525h-.015z"
      />
      <path
        fill="#333744"
        d="M542.859 171.863s-1.34 1.589.389 4.767c0 0-3.894-1.932-3.037-5.126l2.648.359z"
      />
      <path
        fill="#333744"
        d="M550.01 161.939s2.025 7.057-11.435 11.746l9.815-19.02 1.62 7.29v-.016z"
      />
      <path
        fill="#333744"
        d="M545.274 169.012s-.328 3.583 5.14 3.941c0 0-4.112 1.542-6.87-1.449l1.73-2.492zM498.088 129.164s-11.683 20.999-.67 34.147l4.097-.779-3.411-33.368h-.016z"
      />
      <path
        fill="#DB706C"
        d="M491.951 152.095c1.059 4.315 3.863 7.352 6.278 6.76 2.414-.592 3.505-4.564 2.445-8.894-1.059-4.316-3.863-7.353-6.278-6.761-2.414.592-3.505 4.564-2.445 8.895z"
      />
      <path
        fill="#FFC9C7"
        d="M549.339 126.189c-5.483-7.976-14.004-13.257-23.756-13.678h-.997c-.327-.031-.67-.062-.997-.077-9.767-.405-13.459-1.138-19.846 6.137-8.225 9.378-6.013 28.352-6.262 34.365-.717 17.058.514 29.364 16.372 29.941 16.606.607 33.835-10.033 35.564-27.713.592-5.998 3.35-24.021-.078-28.991v.016z"
      />
      <path
        fill="#333744"
        d="M512.56 104.116c3.162-1.06 6.776-1.402 9.44-1.574 9.331-.592 19.582-.358 27.339 5.25 15.235 11.045 17.728 33.134 4.549 49.195-3.567 3.006-46.484-5.92-57.56-28.928-3.271-6.792 8.879-8.054 9.206-16.248.156-4.175 3.287-6.434 7.042-7.695h-.016z"
      />
      <path
        fill="#333744"
        d="M512.763 116.001s.717 24.208-19.503 27.121c0 0-9.082-27.183 18.568-38.742l.919 11.621h.016z"
      />
      <path
        fill="#FFC9C7"
        d="M553.872 156.987c-1.666 4.969-5.265 8.256-8.038 7.321-2.773-.934-3.676-5.717-2.009-10.686 1.666-4.969 5.265-8.256 8.038-7.322 2.773.935 3.676 5.718 2.009 10.687z"
      />
      <path
        fill="#1C76B3"
        d="M413.905 152.64h-217.03c-8.44 0-15.282 6.842-15.282 15.282v132.474c0 8.44 6.842 15.282 15.282 15.282h217.03c8.44 0 15.282-6.842 15.282-15.282V167.922c0-8.44-6.842-15.282-15.282-15.282z"
      />
      <path
        fill="#2497E5"
        d="M410.931 155.616H193.9c-8.44 0-15.282 6.842-15.282 15.282v132.474c0 8.44 6.842 15.281 15.282 15.281h217.031c8.44 0 15.281-6.841 15.281-15.281V170.898c0-8.44-6.841-15.282-15.281-15.282z"
      />
      <path
        fill="#FFE784"
        d="M238.203 213.269h-40.502a6.091 6.091 0 00-6.091 6.091v24.488a6.091 6.091 0 006.091 6.091h40.502a6.091 6.091 0 006.091-6.091V219.36a6.091 6.091 0 00-6.091-6.091z"
      />
      <path
        stroke="#FDFEFF"
        strokeMiterlimit="10"
        strokeWidth="0.82"
        d="M191.61 225.092h52.684M191.61 238.115h52.684M207.609 238.115v-13.023M228.296 238.115v-13.023"
      />
      <path
        fill="#E7FEFD"
        d="M237.035 263.585h-42.497a2.928 2.928 0 00-2.928 2.929v7.446a2.928 2.928 0 002.928 2.928h42.497a2.929 2.929 0 002.929-2.928v-7.446a2.93 2.93 0 00-2.929-2.929zM294.782 263.585h-42.496a2.929 2.929 0 00-2.929 2.929v7.446a2.928 2.928 0 002.929 2.928h42.496a2.928 2.928 0 002.929-2.928v-7.446a2.929 2.929 0 00-2.929-2.929zM352.544 263.585h-42.496a2.93 2.93 0 00-2.929 2.929v7.446a2.929 2.929 0 002.929 2.928h42.496a2.928 2.928 0 002.929-2.928v-7.446a2.929 2.929 0 00-2.929-2.929zM410.292 263.585h-42.497a2.928 2.928 0 00-2.928 2.929v7.446a2.928 2.928 0 002.928 2.928h42.497a2.928 2.928 0 002.928-2.928v-7.446a2.928 2.928 0 00-2.928-2.929z"
      />
      <path
        fill="#1D7DBD"
        d="M410.105 294.149H277.397a3.1 3.1 0 00-3.1 3.1v7.212a3.1 3.1 0 003.1 3.1h132.708a3.1 3.1 0 003.1-3.1v-7.212a3.1 3.1 0 00-3.1-3.1zM379.541 281.796h-12.416c-.843 0-1.526.684-1.526 1.527v4.081c0 .844.683 1.527 1.526 1.527h12.416c.843 0 1.527-.683 1.527-1.527v-4.081c0-.843-.684-1.527-1.527-1.527zM411.102 281.796h-12.416c-.843 0-1.527.684-1.527 1.527v4.081c0 .844.684 1.527 1.527 1.527h12.416c.843 0 1.526-.683 1.526-1.527v-4.081c0-.843-.683-1.527-1.526-1.527z"
      />
      <path
        fill="#FFC9C7"
        d="M543.638 239.969l-42.231 51.594c-2.259 3.162-6.076 4.86-9.737 4.408-21.653-2.57-55.13-6.605-76.518-8.785l-2.243-.187-.343-.032c-12.743-1.511-11.356-19.939 1.496-19.519 22.728 1.745 56.937 5.499 79.914 7.758l-9.736 4.409 33.695-57.545c4.377-7.477 14.004-10.001 21.481-5.623 8.288 4.751 10.313 16.169 4.222 23.491v.031z"
      />
      <path
        fill="#FFC9C7"
        d="M395.134 257.853c6.636-.483 12.151 4.58 13.989 10.686 2.259 6.294-6.87 9.845-9.456 3.676-.685-1.557-.046-3.209-.264-4.766a6.507 6.507 0 00-2.197-4.347c-.81-.763-1.869-1.293-2.788-1.713-1.745-.779-1.169-3.505.716-3.536z"
      />
      <path
        fill="#FFC9C7"
        d="M406.257 264.225c2.337.638 4.907 1.448 7.244 3.66 1.713 1.636 2.85 8.599 1.542 10.936-3.163 5.67-8.568 8.1-12.852 9.284-3.458.951-8.241 1.106-10.281 1.434-.966.155-1.169-1.169-1.106-2.135l-.032-15.562c4.549-.592 15.5-7.633 15.5-7.633l-.015.016z"
      />
      <path
        fill="#FFC9C7"
        d="M395.212 277.029c-.763.14-1.339.109-2.04.063-6.637-.717-12.883-3.319-18.99-5.733a2.086 2.086 0 01-1.137-2.726 2.099 2.099 0 012.524-1.215c5.748 1.62 12.945 3.723 18.257 3.489 4.066-.81 5.421 5.11 1.386 6.138v-.016z"
      />
      <path
        fill="#FFC9C7"
        d="M397.159 280.083c-.778.514-1.495.716-2.336.903-5.624.981-11.138-1.044-16.217-3.069-1.744-.732-3.427-1.542-5.094-2.368a2.103 2.103 0 01-.965-2.804 2.076 2.076 0 012.663-1.012c1.667.623 3.334 1.23 5.016 1.76 4.269 1.184 9.332 2.757 13.491 1.34 2.741-1.916 7.01 2.212 3.442 5.25z"
      />
      <path
        fill="#FFC9C7"
        d="M395.851 286.033c-.717.264-.997.264-1.62.311-4.035.078-7.851-1.542-11.45-3.022-3.567-1.573-6.948-3.38-10.266-5.265-.997-.577-1.355-1.854-.779-2.851.546-.95 1.73-1.324 2.695-.857 5.001 2.228 10.064 4.362 15.36 5.499 1.293.218 2.866.53 3.957.296-.078.016.031-.031-.312.094a3.144 3.144 0 014.113 1.698 3.145 3.145 0 01-1.698 4.112v-.015z"
      />
      <path
        fill="#FFC9C7"
        d="M402.238 287.747c-7.836 4.845-17.665 1.62-25.096-2.337l-2.975-1.729a2.07 2.07 0 01-.748-2.851c.545-.95 1.729-1.293 2.71-.841l.125.063 2.866 1.386c5.344 2.29 11.653 4.206 17.292 2.337.623-.203 1.511-.624 1.885-.935 3.271-2.524 7.119 2.274 3.925 4.907h.016z"
      />
      <path
        fill="#8E84FF"
        d="M527.359 266.607l-29.769-12.135 19.036-36.016 28.414-19.269s23.024 23.74-17.681 67.405v.015z"
      />
      <path
        fill="#fff"
        d="M502.528 245.142l-4.938 9.331 29.769 12.135a144.49 144.49 0 007.181-8.288c-10.608-4.517-21.295-8.863-32.012-13.163v-.015z"
      />
      <path
        fill="#6569D9"
        d="M515.099 221.354c-4.221 11.808-9.97 23.086-17.509 33.118 4.05-11.886 10.141-22.993 17.509-33.118zM527.359 266.607c9.861-12.945 18.756-27.012 22.712-42.917.733-2.243.904-4.58 1.465-6.901.436 19.099-10.032 37.496-24.162 49.818h-.015z"
      />
      <path
        fill="#fff"
        d="M529.743 186.304l5.608 4.175a4.559 4.559 0 011.261 5.888l-5.016 9.004-20.344 15.266 1.667-16.372 10.53-6.512 6.294-11.418v-.031zM511.252 186.974l-3.256 2.399c-1.075.779-1.838 1.9-2.212 3.178l-3.49 12.259 1.511 10.998 9.098-11.543-2.056-8.692.389-8.584.016-.015z"
      />
      <path
        fill="#84ACFF"
        d="M450.156 412.167c.265-.093.53-.14.779-.218l.826-.218c.747-.124 1.511-.249 2.29-.265 10.141-.264 18.413 11.325 18.6 26.109.186 14.705-7.696 27.308-17.837 28.445v.031l-64.804 21.217-4.284-1.776v-56.236l64.43-17.089z"
      />
      <path
        fill="#557BC7"
        d="M390.026 431.111l72.281 31.732s-4.798 2.882-7.493 3.178v.031l-64.804 21.217-4.284-1.776v-56.236l4.284 1.838.016.016z"
      />
      <path
        fill="#2756B8"
        d="M390.026 487.267v-56.158l60.13-15.951c.265-.094.53-.141.779-.218l.826-.219c.747-.124 1.511-.249 2.29-.264 10.141-.265 18.413 11.325 18.6 26.108.186 14.706-7.696 27.308-17.837 28.445v.031l-64.804 18.242.016-.016z"
      />
      <path
        fill="#BFAD63"
        d="M461.356 441.017c-.296 7.228-5.281 13.428-11.683 14.16-7.088.81-13.631-5.249-13.895-13.973-.281-9.16 6.433-16.185 14.144-15.36 6.901.748 11.746 7.633 11.434 15.173z"
      />
      <path
        fill="#FFE784"
        d="M462.852 442.513c-.296 7.228-5.281 13.428-11.684 14.161-7.088.81-13.63-5.25-13.895-13.974-.281-9.16 6.434-16.185 14.145-15.36 6.901.748 11.745 7.634 11.434 15.173z"
      />
      <path
        fill="#DBC771"
        d="M458.225 442.325c-.187 4.611-3.365 8.568-7.446 9.02-4.518.514-8.692-3.349-8.864-8.911-.171-5.841 4.097-10.328 9.02-9.783 4.408.483 7.493 4.876 7.29 9.674z"
      />
      <path
        fill="#4B71C2"
        d="M402.286 347.831H121.947c-7.789 0-14.098 6.309-14.098 14.098v190.128c0 7.788 6.309 14.098 14.098 14.098h275.634l-.857.732 6.091 5.125 9.378-9.939a14.06 14.06 0 004.191-10.032V361.913c0-7.789-6.309-14.098-14.098-14.098v.016z"
      />
      <path
        fill="#628FEB"
        d="M403.298 361.773l9.503-9.237c-2.586-2.882-6.325-4.721-10.5-4.721H121.947c-7.789 0-14.098 6.309-14.098 14.098v190.128c0 7.789 6.309 14.098 14.098 14.098h115.837c24.504-28.243 165.514-204.366 165.514-204.366z"
      />
      <path
        fill="#2756B8"
        d="M393.188 357.505H112.849c-7.786 0-14.098 6.312-14.098 14.098V561.73c0 7.787 6.312 14.098 14.098 14.098h280.339c7.786 0 14.098-6.311 14.098-14.098V371.603c0-7.786-6.312-14.098-14.098-14.098z"
      />
      <path
        fill="#416EC9"
        d="M146.778 370.123h232.733c8.053 0 14.612 6.542 14.612 14.612v165.327c0 8.054-6.543 14.612-14.612 14.612H146.778c-8.054 0-14.612-6.542-14.612-14.612V384.735c0-8.054 6.542-14.612 14.612-14.612z"
      />
      <path
        fill="#4F96B3"
        d="M369.868 376.899H152.837c-8.44 0-15.282 6.842-15.282 15.282v132.474c0 8.44 6.842 15.282 15.282 15.282h217.031c8.44 0 15.282-6.842 15.282-15.282V392.181c0-8.44-6.842-15.282-15.282-15.282z"
      />
      <path
        fill="#43C7E5"
        d="M366.893 379.89H149.862c-8.44 0-15.282 6.842-15.282 15.282v132.474c0 8.44 6.842 15.282 15.282 15.282h217.031c8.439 0 15.281-6.842 15.281-15.282V395.172c0-8.44-6.842-15.282-15.281-15.282z"
      />
      <path
        fill="#9BBBFF"
        d="M194.166 437.545h-40.503a6.09 6.09 0 00-6.09 6.091v24.488a6.09 6.09 0 006.09 6.091h40.503a6.091 6.091 0 006.091-6.091v-24.488a6.091 6.091 0 00-6.091-6.091z"
      />
      <path
        stroke="#FFFDFD"
        strokeMiterlimit="10"
        strokeWidth="0.82"
        d="M147.573 449.369h52.684M147.573 462.377h52.684M163.571 462.377v-13.008M184.258 462.377v-13.008"
      />
      <path
        fill="#FEE8BC"
        d="M192.998 487.86h-42.497a2.929 2.929 0 00-2.928 2.929v7.446a2.928 2.928 0 002.928 2.929h42.497a2.928 2.928 0 002.928-2.929v-7.446a2.929 2.929 0 00-2.928-2.929zM250.745 487.86h-42.497a2.929 2.929 0 00-2.928 2.929v7.446a2.928 2.928 0 002.928 2.929h42.497a2.928 2.928 0 002.928-2.929v-7.446a2.929 2.929 0 00-2.928-2.929zM308.491 487.86h-42.496a2.93 2.93 0 00-2.929 2.929v7.446a2.93 2.93 0 002.929 2.929h42.496a2.929 2.929 0 002.929-2.929v-7.446a2.93 2.93 0 00-2.929-2.929zM366.254 487.86h-42.496a2.93 2.93 0 00-2.929 2.929v7.446a2.93 2.93 0 002.929 2.929h42.496a2.929 2.929 0 002.929-2.929v-7.446a2.93 2.93 0 00-2.929-2.929z"
      />
      <path
        fill="#9BA1BD"
        d="M366.067 518.423H233.359a3.1 3.1 0 00-3.1 3.1v7.212a3.1 3.1 0 003.1 3.1h132.708a3.1 3.1 0 003.1-3.1v-7.212a3.1 3.1 0 00-3.1-3.1zM335.503 506.056h-12.415c-.843 0-1.527.683-1.527 1.526v4.082c0 .843.684 1.526 1.527 1.526h12.415c.843 0 1.527-.683 1.527-1.526v-4.082c0-.843-.684-1.526-1.527-1.526zM367.064 506.056h-12.416c-.843 0-1.526.683-1.526 1.526v4.082c0 .843.683 1.526 1.526 1.526h12.416c.843 0 1.527-.683 1.527-1.526v-4.082c0-.843-.684-1.526-1.527-1.526z"
      />
      <path
        fill="#668EE0"
        d="M132.166 447.716v102.331c0 8.069 6.542 14.612 14.612 14.612h232.733c8.069 0 14.612-6.543 14.612-14.612V447.716H132.166z"
      />
      <path
        fill="#436EC4"
        d="M132.166 501.896v48.152c0 8.069 6.542 14.612 14.612 14.612h232.733c8.069 0 14.612-6.543 14.612-14.612v-48.152H132.166z"
      />
      <path
        fill="#416EC9"
        d="M305.874 606.937l-16.574 8.334.95-214.226 18.678-3.256v204.226a5.542 5.542 0 01-3.038 4.938l-.016-.016z"
      />
      <path
        fill="#628FEB"
        d="M308.912 397.773s.685-4.128-3.895-5.125c-3.193-.701-189.13-43.447-189.13-43.447l-17.152 5.297 189.505 51.235 20.656-7.96h.016z"
      />
      <path
        fill="#4777D9"
        d="M287.29 399.331L98.751 354.498v206.858a14.415 14.415 0 0011.123 14.036l173.147 40.596c5.811 1.371 11.388-3.053 11.388-9.02V408.351c0-4.3-2.944-8.023-7.119-9.02z"
      />
      <path
        stroke="#E7FEFD"
        strokeMiterlimit="10"
        strokeWidth="0.96"
        d="M98.751 366.758l4.346 1.028M111.821 369.858l158.38 37.667a15.98 15.98 0 0112.275 15.547v160.327c0 10.313-9.627 17.93-19.675 15.547l-155.357-36.842M103.113 561.06l-4.362-1.028"
      />
      <path
        fill="#668EE0"
        d="M123.785 347.831h-18.351a6.664 6.664 0 00-6.667 6.667l25.034-6.667h-.016z"
      />
      <path
        fill="#BAA960"
        d="M244.248 487.657c1.231 8.008-3.365 15.376-10.266 16.435-6.901 1.059-13.521-4.564-14.752-12.571-1.231-8.007 3.365-15.376 10.266-16.435 6.901-1.059 13.521 4.564 14.752 12.571z"
      />
      <path
        fill="#FFE784"
        d="M242.441 488.202c1.215 7.882-3.364 15.142-10.219 16.201-6.87 1.059-13.412-4.471-14.643-12.353-1.215-7.883 3.365-15.142 10.219-16.201 6.87-1.06 13.413 4.471 14.643 12.353z"
      />
      <path
        fill="#DBC771"
        d="M237.784 488.919c.763 4.923-2.103 9.456-6.387 10.11-4.284.67-8.381-2.788-9.145-7.711-.763-4.923 2.104-9.456 6.387-10.11 4.284-.67 8.381 2.788 9.145 7.711z"
      />
    </Box>
  );
}

export default memo(PayementIllustration);
