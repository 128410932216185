// ----------------------------------------------------------------------

export type IBilling = {
  id?: string;
  name: string;
  max_email_requests?: number;
  max_sms_requests?: number;
  price: number;
  current_period_start?: string;
  current_period_end?: string;

  created_at?: string;
};

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCEL = 'canceled',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  INCOMPLETE = 'incomplete',
  SUCCEEDED = 'succeeded',
  PAST_DUE = 'past_due',
}
