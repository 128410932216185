import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// import { Roles } from 'src/enumerate/roles';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/settings/Settings'));
const TemplateLocation = lazy(() => import('src/pages/settings/template/template-location'));
const TextTemplateLocation = lazy(
  () => import('src/pages/settings/template/text-template-locations')
);

// ----------------------------------------------------------------------

export const settingsRoutes = [
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'template-locations',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <TemplateLocation />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'text-template-locations',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <TextTemplateLocation />
          </RoleBasedGuard>
        ),
      },
    ],
  },
];
