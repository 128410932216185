import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JWTAuthAdminONLY = lazy(() => import('src/pages/auth/jwt/jwtAuth'));
const Verify = lazy(() => import('src/sections/auth/jwt/verify-email-view'));
const Verified = lazy(() => import('src/sections/auth/jwt/email-verified-view'));
const ResetPassword = lazy(() => import('src/sections/auth/jwt/reset-password-view'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <AuthClassicLayout>
              <JwtLoginPage />
            </AuthClassicLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'jad',
        element: (
          <GuestGuard>
            <AuthClassicLayout>
              <JWTAuthAdminONLY />
            </AuthClassicLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'verify',
        element: (
          <GuestGuard>
            <Verify />
          </GuestGuard>
        ),
      },
      {
        path: 'email-verified',
        element: (
          <GuestGuard>
            <Verified />
          </GuestGuard>
        ),
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
    ],
  },
];
