export type InotificationData = {
  id: string;
  created_at?: string;
  updated_at?: string;
  reviewer_name?: string;
  review_rating?: number;
  review_type?: string;
  notification_type?: string;
  description?: string;
  location?: string;
  reviewer_avatar_url?: string;
  posted_at?: string;
  location_id?: string;
  contact_actions_id?: string;
  google_reviews_id?: string;
  facebook_reviews_id?: string;
  unread?: boolean;
};

export type NotificationPaginationData = {
  items: InotificationData[];
  nbPages: number;
  nbItems: number;
  total: number;
  page: number;
  totalUnread: number;
  filter: NotificationFilter;
};
export enum NotificationFilter {
  ALL = 'all',
  UNREAD = 'unread',
  READ = 'read',
}
