/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { m } from 'framer-motion';
import { useState, useCallback, useEffect, useRef } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';

// _mock
// import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
// import { useAuthContext } from 'src/auth/hooks';
import { NotificationFilter, NotificationPaginationData } from 'src/types/notification';
import NotifSkeleton from 'src/components/skeleton/notification-skeleton';
import NotifSkeletonItem from 'src/components/skeleton/notif-skeleton-item';
import { Tooltip } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { useNotification } from 'src/Services/notifications';
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: NotificationFilter.ALL,
    label: 'All',
  },
  {
    value: NotificationFilter.UNREAD,
    label: 'Unread',
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { getNotifications, setAllNotificationsRead } = useNotification();
  const { user } = useSelector((state) => state);
  const [notificationSupported, setNotificationSupported] = useState<boolean>(
    'Notification' in window
  );
  const [permission, setPermission] = useState<NotificationPermission>(Notification.permission);

  const [notifications, setNotifications] = useState<NotificationPaginationData>({
    items: [],
    nbPages: 1,
    nbItems: 0,
    total: 0,
    page: 1,
    totalUnread: 0,
    filter: NotificationFilter.ALL,
  }); //

  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(5);
  const [currentTab, setCurrentTab] = useState(NotificationFilter.ALL);
  const [isCurrentTabLoading, setIsCurrentTabLoading] = useState<boolean>(true);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { notification: new_notification } = useSelector((state) => state);
  const scrollToBottom = () => {
    if (scrollRef.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  // isLoading: isFetchingNotifications,
  const {
    data,
    status,
    isPending,
    refetch,
    // error: GetNotificationsError,
  } = useQuery({
    queryFn: () => getNotifications(user, page, limit, currentTab),
    queryKey: ['GetNotifications', user?.id, page, limit, currentTab],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (status === 'error') {
      enqueueSnackbar('Error fetching contacts actions', {
        variant: 'error',
      });
    } else if (status === 'success') {
      const { items } = data as NotificationPaginationData;
      const { items: notificationsItems } = notifications;
      const ids = notificationsItems?.map((item) => item.id) as string[];
      if (items && items.some((item) => ids.includes(item.id))) {
        return;
      }
      setNotifications((prev) => {
        if (currentTab !== prev?.filter) return { ...data, items: data?.items ?? [] };
        return {
          ...prev,
          ...data,
          items: prev?.items?.concat(data?.items ?? []),
          totalUnread: prev.totalUnread + data.totalUnread,
          total: prev.total + data.total,
        };
      });
      if (isCurrentTabLoading) setIsCurrentTabLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data]);

  useEffect(() => {
    if (!notificationSupported) return;

    if (permission !== 'granted') {
      Notification.requestPermission().then((st: any) => {
        setPermission(st);
      });
    }

    if (permission === 'denied') {
      enqueueSnackbar('Please enable notifications to receive updates', {
        variant: 'warning',
      });
    }
  }, [notificationSupported, permission]);

  const setupSupabaseChannel = useCallback(() => {
    if (!('Notification' in window)) {
      return;
    }

    if (!new_notification?.id) return;

    setNotifications((prev: any) => ({
      ...prev,
      items: [new_notification].concat(prev?.items?.slice(0, -1)),
      totalUnread: prev.totalUnread + 1,
      total: prev.total + 1,
    }));
    /* if (permission === 'granted' && new_notification?.location_id) { */

    const reviewFromFacebook = !!new_notification?.facebook_reviews_id;
    const reviewFromGoogle = !!new_notification?.google_reviews_id;
    const text_from = [''];
    if (reviewFromFacebook) text_from.push('Facebook');
    if (reviewFromGoogle) text_from.push('Google');
    const textFrom = text_from.join(', ');
    const n = new Notification('Revcall Notification', {
      body: `${new_notification.reviewer_name}  have a new review ${textFrom} : "${new_notification.description ?? ''}"`, // Include more information
      icon: 'https://btxagmebtodadmmgejxy.supabase.co/storage/v1/object/public/reviews-dev/1-removebg-preview.png', // Replace with your application's logo URL
      data: { url: `/dashboard`, Label: 'View' },
      badge:
        'https://btxagmebtodadmmgejxy.supabase.co/storage/v1/object/public/reviews-dev/1-removebg-preview.png',
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // The tab has become visible so clear the now-stale Notification.
        n.close();
      }
    });
    n.onclick = () => {
      window.focus();
      n.close();
    };
    /* } */
  }, [new_notification]);

  useEffect(() => {
    const cleanup = setupSupabaseChannel(); // Call the setup function
    return cleanup; // Return cleanup function to unsubscribe when component is unmounted or updated
  }, [setupSupabaseChannel]);

  useEffect(() => {
    scrollToBottom();
  }, [page, limit, currentTab, notifications?.items?.length]);

  const drawer = useBoolean();

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: NotificationFilter) => {
      setCurrentTab(newValue);
      setPage(1);
    },
    []
  );

  const { mutate, isPending: isLoadingSetAllNotificationsRead } = useMutation({
    mutationFn: () => setAllNotificationsRead(user),
    onSuccess: () => {
      setNotifications({
        items: [],
        nbPages: 1,
        nbItems: 0,
        total: 0,
        page: 1,
        totalUnread: 0,
        filter: NotificationFilter.ALL,
      });
      refetch();
    },
    onError: () => {
      enqueueSnackbar('Something wrong happend ! \n Please try again', { variant: 'error' });
    },
  });

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!!notifications?.totalUnread && (
        <Tooltip title="Mark all as read">
          <IconButton
            color="primary"
            onClick={() => {
              mutate();
            }}
          >
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      <IconButton onClick={drawer.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            tab.value === NotificationFilter.UNREAD ? (
              <Label variant="soft" color="info">
                {notifications?.totalUnread ?? 0}
              </Label>
            ) : (
              <></>
            )
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const filtereNotifications = notifications?.items?.filter((item) => item.id !== undefined);

  const unreadNotifications = filtereNotifications?.filter((item) => item.unread);

  const renderList = (
    <Scrollbar ref={scrollRef}>
      <List disablePadding>
        {(currentTab === NotificationFilter.ALL || currentTab === NotificationFilter.READ) &&
          filtereNotifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              user={user}
              setNotifications={setNotifications}
              currentTab={currentTab}
            />
          ))}
        {currentTab === NotificationFilter.UNREAD &&
          unreadNotifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              user={user}
              setNotifications={setNotifications}
              currentTab={currentTab}
            />
          ))}

        {isPending && (
          <>
            <NotifSkeletonItem />
            <NotifSkeletonItem />
          </>
        )}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={notifications?.totalUnread} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        // slotProps={{
        //   backdrop: { invisible: true },
        // }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />

        {isCurrentTabLoading || isLoadingSetAllNotificationsRead ? (
          <NotifSkeleton />
        ) : (
          <>
            {renderList}
            {page < (notifications?.nbPages || 1) && (
              <Box sx={{ p: 1 }}>
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    // setIsFetchingNotifications(true);
                    setPage((prev) => {
                      if (prev < (notifications?.nbPages || 1)) return prev + 1;
                      return prev;
                    });
                  }}
                >
                  View more
                </Button>
              </Box>
            )}
          </>
        )}
      </Drawer>
    </>
  );
}
