export enum Template {
  EMAIL = 'email',
  TEXT = 'text',
}

export enum reply_to {
  COMPANY_EMAIL = 'company_email',
  BUSINESS_EMAIL = 'business_email',
}

export enum links_providers {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
}
