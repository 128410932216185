/* eslint-disable @typescript-eslint/no-unused-vars */
import { SxProps, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import PaymentRequire from 'src/components/payment/payment-require';
import { usePayment } from 'src/hooks/use-payment';

type PayementGuardProp = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function PayementGuard({ children, sx }: PayementGuardProp) {
  const {
    user: { company },
  } = useSelector((state: any) => state);

  const { isPlanActive } = usePayment();

  if (!isPlanActive) {
    return (
      <PaymentRequire
        text_content={
          company?.is_owner
            ? 'You need to subscribe to a plan to access this feature'
            : 'Please contact your company owner to subscribe to a plan'
        }
      >
        {!company?.is_owner && (
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {company?.email}
          </Typography>
        )}
      </PaymentRequire>
    );
  }

  return <>{children}</>;
}
