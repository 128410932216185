import { LogsActions } from 'src/enumerate/logsActions';
import { CreateLogsForm } from 'src/types/logs.type';
import axiosBackendInstance from 'src/utils/axios-backend-instance';
import { supabaseClient } from 'src/utils/supabaseClient';
import { store, useDispatch } from 'src/redux/store';
import { userInfoUpdated } from 'src/redux/slices/user.slice';
import { createNewLog } from './logs';
import { useLocation } from './location';

export function useFacebook() {
  const { getLocationsByUserId } = useLocation();
  const dispatch = useDispatch();
  const getPagesDetails = async ({
    start = 0,
    rowPerPage = 5,
    user_id,
  }: {
    start: number;
    rowPerPage: number;
    user_id: string;
  }) => {
    const { data: res } = await supabaseClient.auth.getUser();

    if (!res) throw new Error('User not found');

    const { data, error } = await supabaseClient
      .from('facebook_pages')
      .select('name,id,auto_reply,category,location:location_id(id,name),is_reviews_fetched')
      .eq('user_id', user_id)
      .order('created_at', { ascending: false })
      .range(start, start + (rowPerPage || 4));

    if (error) {
      throw new Error(error.message);
    }
    return data;
  };
  const deletePage = async ({ id }: { id: string }) => {
    const { data, error } = await supabaseClient.from('facebook_pages').delete().eq('id', id);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.DELETE_FACEBOOK_PAGE,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ facebook_page_id: id })),
      };
      createNewLog(newLogsForm);

      throw new Error(error.message);
    }

    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DELETE_FACEBOOK_PAGE,
      message: 'Facebook Page deleted successfully',
      is_error: false,
    };
    createNewLog(newLogsForm);
    return data;
  };

  const deleteMultiplePages = async (ids: string[]) => {
    const { data, error } = await supabaseClient.from('facebook_pages').delete().in('id', ids);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.DELETE_MULTIPLE_FACEBOOK_PAGES,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ facebook_page_ids: ids })),
      };
      createNewLog(newLogsForm);
      throw new Error(error.message);
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DELETE_MULTIPLE_FACEBOOK_PAGES,
      message: 'Facebook Page deleted successfully',
      is_error: false,
    };
    createNewLog(newLogsForm);
    return data;
  };

  const getFacebookPagesFilters = async () => {
    // get auth User

    const { data: pagesDetails, error } = await supabaseClient
      .from('facebook_pages')
      .select('page_id,category,name');

    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_FACEBOOK_PAGE_FILTERS,
        message: error.message,
        is_error: true,
      };
      createNewLog(newLogsForm);

      return {
        pages: [],
        categories: [],
      };
    }
    const pages = pagesDetails?.map((page) => ({
      label: page.name,
      value: page.page_id,
    }));
    const categories = pagesDetails?.map((page) => ({
      label: page.category,
      value: page.page_id,
    }));

    return {
      pages,
      categories,
    };
  };

  const updateFacebookPage = async (page_id: string | null, location_id: any) => {
    const { error } = await supabaseClient
      .from('facebook_pages')
      .update({
        location_id,
      })
      .eq('id', page_id);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_FACEBOOK_PAGE,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ facebook_page_id: page_id, location_id })),
      };
      createNewLog(newLogsForm);

      throw new Error(error.message);
    }

    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_FACEBOOK_PAGE,
      message: 'Facebook Page updated successfully',
      is_error: false,
    };
    createNewLog(newLogsForm);
  };

  const getFBOAuthPage = async (options: Object): Promise<any> => {
    const params = {};
    if (options) {
      Object.assign(params, { state: { options } });
    }
    try {
      const { data } = await axiosBackendInstance.get('/facebook/login', { params });
      return data;
    } catch (error) {
      throw new Error('Error authenticated with google');
    }
  };
  const getFBOAuth = async (options: Object): Promise<any> => {
    const params = {};
    if (options) {
      Object.assign(params, { state: { options } });
    }
    try {
      const { data } = await axiosBackendInstance.get('/facebook/auth', { params });
      return data;
    } catch (error) {
      throw new Error('Error authenticated with google');
    }
  };
  const fetchFBPageReviews = async (page_id: string) => {
    const { data } = await axiosBackendInstance.post(`/facebook/fetch-reviews`, {
      pageIds: [page_id],
    });
    return data;
  };

  const linkFBPageToLocation = async (paylaod: any) => {
    try {
      const { user } = store.getState();
      const checker = await checkPageStatus(paylaod.page_id);

      if (checker) {
        throw new Error(` The ${paylaod?.name ?? ''} page is already linked to another location.`);
      }
      await unLinkFBPageToLocation(paylaod.location_id);

      const { data, error } = await supabaseClient
        .from('facebook_pages')
        .upsert(
          { ...paylaod, user_id: paylaod?.user_id },
          {
            onConflict: 'location_id,page_id',
          }
        )
        .select('id,page_id,name,user_id(email)')
        .maybeSingle();

      if (error) {
        throw new Error(error.message);
      }
      const linkLogForm: CreateLogsForm = {
        action: LogsActions.LINK_FACEBOOK_PAGE,
        is_error: false,
        entry_data: JSON.parse(JSON.stringify({ ...paylaod, user_id: paylaod?.user_id })),
      };

      createNewLog(linkLogForm);
      if (data && data?.id) {
        fetchFBPageReviews(data?.id);
      }
      const store_data = store.getState();
      const locations = await getLocationsByUserId(user?.id as string);
      dispatch(
        userInfoUpdated({
          ...store_data.user,
          ...store_data.billing,
          locations,
        })
      );
      return data;
    } catch (error) {
      const linkLogForm: CreateLogsForm = {
        action: LogsActions.LINK_FACEBOOK_PAGE,
        is_error: true,
        message: error.message,
        entry_data: JSON.parse(JSON.stringify({ ...paylaod })),
      };

      createNewLog(linkLogForm);
      throw new Error(error.message);
    }
  };
  const unLinkFBPageToLocation = async (location_id: any) => {
    const { user } = store.getState();
    const { error } = await supabaseClient
      .from('facebook_pages')
      .delete(location_id)
      .eq('location_id', location_id);
    if (error) {
      const unlinklogForm: CreateLogsForm = {
        action: LogsActions.UNLINK_FACEBOOK_PAGE,
        message: error.message,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ location_id })),
      };

      createNewLog(unlinklogForm);

      throw new Error(error.message);
    }
    const unlinklogForm: CreateLogsForm = {
      action: LogsActions.UNLINK_FACEBOOK_PAGE,
      is_error: false,
      entry_data: JSON.parse(JSON.stringify({ location_id })),
    };

    createNewLog(unlinklogForm);
    const store_data = store.getState();
    const locations = await getLocationsByUserId(user?.id as string);
    dispatch(
      userInfoUpdated({
        ...store_data.user,
        ...store_data.billing,
        locations,
      })
    );
  };

  const checkPageStatus = async (page_id: string) => {
    const { data, error } = await supabaseClient.rpc('check_facebook_page_exists', {
      page_id_to_check: page_id,
    });

    console.log('🚀 ~ checkPageStatus ~ data:', data);
    console.log('🚀 ~ checkPageStatus ~ error:', error);
    if (error) {
      return false;
    }
    return (data || 0) > 0;
  };
  return {
    getPagesDetails,
    deletePage,
    deleteMultiplePages,
    getFacebookPagesFilters,
    updateFacebookPage,
    getFBOAuthPage,
    getFBOAuth,
    fetchFBPageReviews,
    linkFBPageToLocation,
    unLinkFBPageToLocation,
    checkPageStatus,
  };
}
export async function getFacebookPages(): Promise<
  {
    label: string;
    value: string;
  }[]
> {
  const { data, error } = await supabaseClient.from('facebook_pages').select('value:id,label:name');

  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.GET_FACEBOOK_PAGE_FILTERS,
      message: error.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    return [];
  }
  return data || [];
}
