import { Stack } from '@mui/material';
import clsx from 'clsx';
import OnboardingMenu from './Menu';
import styles from './styles.module.css';

type Props = {
  startSettingsAnimation: boolean;
  setStartSettingsAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  startLocationAnimation: boolean;
  setStartLocationAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  startIntegrationAnimation: boolean;
  setStartIntegrationAnimation: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function IllustrationsOnboardingSection({
  startSettingsAnimation,
  setStartLocationAnimation,
  setStartIntegrationAnimation,
  setStartSettingsAnimation,
  startLocationAnimation,
  startIntegrationAnimation,
}: Props) {
  return (
    <div
      className={clsx(
        'md:w-full md:h-full flex flex-row justify-center items-center mr-14',
        styles.IlustrationSection
      )}
    >
      <Stack direction="column" spacing={5}>
        <Stack
          direction="column"
          spacing={5}
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            marginTop: '10vh',
          }}
        >
          <OnboardingMenu
            title="Company Settings"
            icon="mdi:office-building"
            color="primary"
            startAnimation={startSettingsAnimation}
            setStartAnimation={setStartSettingsAnimation}
            sx={{
              cursor: 'pointer',
            }}
          />
          <OnboardingMenu
            title="Location Settings"
            icon="carbon:location-filled"
            color="warning"
            setStartAnimation={setStartLocationAnimation}
            startAnimation={startLocationAnimation}
            sx={{
              cursor: 'pointer',
            }}
          />
          <OnboardingMenu
            title="Integrations"
            icon="bi:diagram-2"
            color="error"
            setStartAnimation={setStartIntegrationAnimation}
            startAnimation={startIntegrationAnimation}
            sx={{
              cursor: 'pointer',
            }}
          />
        </Stack>
      </Stack>
    </div>
  );
}
