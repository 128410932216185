import { supabaseClient } from 'src/utils/supabaseClient';
import { IPorfile } from 'src/types/user';
import { LogsActions } from 'src/enumerate/logsActions';
import { CreateLogsForm } from 'src/types/logs.type';
import { store } from 'src/redux/store';
import axiosBackendInstance from 'src/utils/axios-backend-instance';
import { register } from './auth';
import { createNewLog } from './logs';
import { uploadImage } from './supabaseUpload';

export function useUsers() {
  const { user: userConnect } = store.getState();

  return {
    userConnect,
    updateUser,
    inviteMember,
    getUsers,
    addUser,
    deleteUser,
    deactivateUser,
    getProfile,
    updateAccount,
    old_password_check,
    updatePassword,
    updatePasswordAndProfile,
  };
}
const inviteMember = async (inviteData: {
  email: string;
  locations: string[];
  name: string;
  role: string;
}) => {
  try {
    await axiosBackendInstance.post(`admin/user-management/inviteMember`, inviteData);
  } catch (e) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.CREATE_USER,
      message: e.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(inviteData)),
    };
    createNewLog(newLogsForm);
    throw new Error(e.message);
  }
};

const old_password_check = async (oldPassword: string): Promise<boolean> => {
  try {
    const { data, error } = await supabaseClient.rpc('verify_user_password', {
      password: oldPassword,
    });
    if (error) {
      throw new Error(error.message);
    }
    return data;
  } catch (e) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_PASSWORD,
      message: e.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(e.message);
  }
};

/**
 * get list of users from supabase
 * @returns {Promise<Object>} - The list of users.
 * @throws {Error} - If an error occurs during the login process.
 * */
async function getUsers() {
  const { data: userCompany, error: userCompanyError } = await supabaseClient
    .rpc('get_users_company_list')
    .select('*');
  if (userCompanyError) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.GET_USERS,
      message: userCompanyError.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(userCompanyError.message);
  }

  const { data: users, error } = await supabaseClient
    .from('profiles')
    .select('*')
    .order('id', { ascending: true });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.GET_USERS,
      message: error.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }

  users.forEach((user) => {
    const userCompanyData: any = userCompany.find((item) => item.user_id === user.user_id);
    user.company = userCompanyData?.company?.name;
  });

  return users;
}

/**
 * add user to supabase
 * @param {Object} user - The user to add.
 * @returns {Promise<Object>} - The user added.
 * @throws {Error} - If an error occurs during the login process.
 * */
async function addUser(user: {
  id: string;
  email: string;
  role: string;
  created_at: string;
  name: string;
  avatar: string | Blob | null | undefined;
  address: string;
  phone_number: string;
  password: string;
}) {
  try {
    await register({
      email: user.email,
      password: user.password,
      data: {
        name: user.name,
        role: user.role,
      },
    });
  } catch (e) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.CREATE_USER,
      message: e.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(user)),
    };
    createNewLog(newLogsForm);

    throw new Error(e.message);
  }

  const { data: newUser, error } = await supabaseClient
    .from('profiles')
    .update({
      phone_number: user.phone_number,
      address: user.address,
    })
    .match({ email: user.email });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.CREATE_USER,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(user)),
    };
    createNewLog(newLogsForm);

    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.CREATE_USER,
    message: 'User created successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);

  return newUser;
}

/**
 * delete user from supabase
 * @param {string} id - The id of the user to delete.
 * @returns {Promise<Object>} - The user deleted.
 * @throws {Error} - If an error occurs during the login process.
 * */
async function deleteUser(id: string) {
  const { error: deleteError } = await supabaseClient.auth.admin.deleteUser(id);
  if (deleteError) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DELETE_USER,
      message: deleteError.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify({ id })),
    };
    createNewLog(newLogsForm);
    throw new Error(deleteError.message);
  }
  const { data: user, error } = await supabaseClient.from('profiles').delete().match({ id });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DELETE_USER,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify({ id })),
    };
    createNewLog(newLogsForm);

    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.DELETE_USER,
    message: 'User deleted successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);

  return user;
}

/**
 * update user from supabase
 * @param {Object} user - The user to update.
 * @returns {Promise<Object>} - The user updated.
 * @throws {Error} - If an error occurs during the login process.
 * */
async function updateUser(
  id: string,
  user: {
    id: string;
    email: string;
    role: string;
    created_at: string;
    name: string;
    avatar: string | Blob | null | undefined;
    address: string;
    phone_number: string;
    is_activated: boolean;
  }
) {
  if (user?.avatar instanceof File) {
    const url = await uploadImage(user?.avatar, 'user_profile', id);
    user.avatar = url;
  }

  const { data: newUser, error } = await supabaseClient
    .from('profiles')
    .update({
      phone_number: user.phone_number,
      address: user.address,
      role: user.role,
      avatar_url: user.avatar,
    })
    .match({ id });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_USER,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(user)),
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.UPDATE_USER,
    message: 'User updated successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);

  return newUser;
}

/**
 * deactivate user from supabase
 * @param {string} id - The id of the user to deactivate.
 * @returns {Promise<Object>} - The user deactivated.
 * @throws {Error} - If an error occurs during the login process.
 * */
async function deactivateUser(id: string, isActivated: boolean) {
  const { data: user, error } = await supabaseClient
    .from('profiles')
    .update({ is_activated: isActivated })
    .match({ id });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DEACTIVATE_USER,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify({ id, isActivated })),
    };
    createNewLog(newLogsForm);

    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.DEACTIVATE_USER,
    message: isActivated ? 'User Activated Successfully ' : 'User deactivated successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);

  return user;
}

/**
 * get user by id from supabase
 * @param {string} id - The id of the user to get.
 * @returns {Promise<Object>} - The profile.
 * @throws {Error} - If an error occurs during the login process.
 * */
async function getProfile(id: string) {
  const { data: user, error } = await supabaseClient
    .from('profiles')
    .select('*')
    .match({ id })
    .single();

  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.GET_PROFILE,
      message: error.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }

  return user as unknown as IPorfile;
}

async function updateAccount(id: string, user: any) {
  if (user?.avatar_url instanceof File) {
    const url = await uploadImage(user?.avatar_url, 'user_profile', id);
    user.avatar_url = url;
  }

  const { data: newUser, error } = await supabaseClient
    .from('profiles')
    .update({
      ...user,
      email: undefined,
    })
    .match({ user_id: id })
    .select('*');

  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_PROFILE,
      message: error.message,
      is_error: true,
      entry_data: JSON.parse(JSON.stringify(user)),
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.UPDATE_PROFILE,
    message: 'Profile updated successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);

  return newUser;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
async function updatePassword(password: string) {
  await supabaseClient.auth.updateUser({ password });
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.UPDATE_PASSWORD,
    message: 'Password updated successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);
}

/**
 * UPDATE PASSWORD AND PROFILE
 * @param {string} password - The id of the user to get.
 * @throws {Error} - If an error occurs during the login process.
 */
async function updatePasswordAndProfile(password: string) {
  const { error: userConnectError } = await supabaseClient.auth.getUser();
  if (userConnectError) {
    throw new Error(userConnectError.message);
  }
  const { error } = await supabaseClient.auth.updateUser({
    password,
    data: {
      is_password_enter: false,
    },
  });
  if (error) {
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_PROFILE,
      message: error.message,
      is_error: true,
    };
    createNewLog(newLogsForm);
    throw new Error(error.message);
  }
  const newLogsForm: CreateLogsForm = {
    action: LogsActions.UPDATE_PROFILE,
    message: 'Profile with Password updated successfully',
    is_error: false,
  };
  createNewLog(newLogsForm);
}
