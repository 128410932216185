/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUsers } from 'src/Services/UserService';
import { updateWalkTour } from 'src/Services/profile';
import { UseWalktourProps, useWalktour } from 'src/components/walktour';
import Walktour from 'src/components/walktour/walktour';
import { WalktourPageEnum } from 'src/enumerate/walktour-pages';
import { userInfoUpdated } from 'src/redux/slices/user.slice';
import { WalktourType } from 'src/types/walktour';

// Create a context for Walktour
const WalktourContext = createContext<any>({});

export const useWalktourContext = () => useContext(WalktourContext);

export function WalktourProvider({ children }: { children: React.ReactNode }) {
  const {
    userConnect: { id, profile },
  } = useUsers();
  const dispatch = useDispatch();

  const [steps, setSteps] = useState<UseWalktourProps['steps']>([]);
  const [page, setPage] = useState<string>('');
  const currentWalktour: WalktourType = profile?.walktour;

  const savedWalktour = () => {
    switch (page) {
      case WalktourPageEnum.DASHBOARD:
        return { ...currentWalktour, dashboard: true };
      case WalktourPageEnum.CONTACTS:
        return { ...currentWalktour, contacts: true };
      case WalktourPageEnum.INBOX:
        return { ...currentWalktour, inbox: true };
      case WalktourPageEnum.REVIEWS:
        return { ...currentWalktour, reviews: true };
      case WalktourPageEnum.CAMPAIGNS:
        return { ...currentWalktour, campaigns: true };
      case WalktourPageEnum.AUTOMATION:
        return { ...currentWalktour, automation: true };
      case WalktourPageEnum.EMBED:
        return { ...currentWalktour, embed: true };
      case WalktourPageEnum.EMBED_SELECT:
        return { ...currentWalktour, embed_select: true };
      case WalktourPageEnum.EMBED_CONFIGURATION:
        return { ...currentWalktour, embed_configuration: true };
      default:
        return currentWalktour;
    }
  };

  const [run, setRun] = useState(false);
  const walktour = useWalktour({
    defaultRun: true,
    showProgress: true,
    handleFinish: async () => {
      const walktourAttribute = savedWalktour();

      setRun(false);
      try {
        await updateWalkTour(walktourAttribute, id);
        dispatch(
          userInfoUpdated({
            profile: {
              ...profile,
              walktour: walktourAttribute,
            },
          })
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    steps,
  });

  const startWalktour = (initialRun: boolean) => {
    setRun(initialRun);
  };

  const toggleWalktour = () => {
    setRun((prev) => !prev);
  };

  return useMemo(
    () => (
      <WalktourContext.Provider
        value={{ steps, run, page, startWalktour, toggleWalktour, setSteps, setPage }}
      >
        <Walktour
          continuous
          showProgress
          showSkipButton
          disableOverlayClose
          steps={steps}
          run={run}
          callback={walktour.onCallback}
          getHelpers={walktour.setHelpers}
          scrollDuration={500}
        />
        {children}
      </WalktourContext.Provider>
    ),
    [steps, run]
  );
}
