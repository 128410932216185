import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useLocation } from 'src/Services/location';
import { LocationContext } from './location-context';
import { ActionMapType, LocationStateType } from './types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  GET = 'GET',
  POST = 'POST',
}

type Payload = {
  [Types.INITIAL]: {
    locations: any[];
  };
  [Types.POST]: {
    locations: any[];
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: LocationStateType = {
  locations: [],
  loading: true,
};

const reducer = (state: LocationStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      locations: action.payload.locations,
    };
  }
  if (action.type === Types.POST) {
    return {
      ...state,
      locations: action.payload.locations,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function LocationProvider({ children }: Props) {
  const { getAccessLocations } = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const locations = await getAccessLocations();

      dispatch({
        type: Types.INITIAL,
        payload: { locations },
      });
    } catch (error) {
      // Handle any errors that occur during the process
      console.error(error);

      // Set the user to null in case of an error
      dispatch({
        type: Types.INITIAL,
        payload: { locations: [] },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // ----------------------------------------------------------------------

  const refetchLocations = useCallback(async () => {
    try {
      const locations = await getAccessLocations();

      dispatch({
        type: Types.POST,
        payload: { locations },
      });
    } catch (error) {
      // Handle any errors that occur during the process
      console.error(error);

      // Set the user to null in case of an error
      dispatch({
        type: Types.INITIAL,
        payload: { locations: [] },
      });
    }
  }, [getAccessLocations]);
  // ----------------------------------------------------------------------

  const status = state.loading;

  const memoizedValue = useMemo(
    () => ({
      locations: state.locations,
      loading: status,
      refetchLocations,
    }),
    [refetchLocations, state.locations, status]
  );

  return <LocationContext.Provider value={memoizedValue}>{children}</LocationContext.Provider>;
}
