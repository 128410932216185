import { useSelector } from 'react-redux';
import { SubscriptionStatus } from 'src/types/billing';

const Email_For_Testing = [
  'mouhebmh@gmail.com',
  'mouhebmh+2302@gmail.com',
  'mhamdi.mouheb1@gmail.com',
];

export function usePayment() {
  const {
    billing: { active_plan },
    user,
  } = useSelector((state: any) => state);

  const user_email = user?.email;

  const isTestUser = Email_For_Testing.includes(user_email);

  const current_date = new Date().getTime();
  const current_period = new Date(active_plan?.current_period_end).getTime();
  const isPlanActive =
    active_plan &&
    active_plan.status !== SubscriptionStatus.PAST_DUE &&
    !Number.isNaN(current_period) &&
    current_date < current_period;

  return {
    isPlanActive: isPlanActive || isTestUser,
    isTestUser,
  };
}
