import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useOnboardingService } from 'src/Services/onboarding';
import ManageGoogleAccountButtonAction from '../locations/view/Locations/location-row-social-actions/mange-google-account-btn-action';
import ManageFacebookPageButtonAction from '../locations/view/Locations/location-row-social-actions/mange-facebook-pages-btn-action';

export default function IntegrationsOnboarding() {
  const { getFirstLocationForOnboarding } = useOnboardingService();
  const { data: locationList } = useQuery({
    queryFn: () => getFirstLocationForOnboarding() as any,
    queryKey: ['First Location For Onboarding'],
  });

  return (
    <>
      <Typography variant="h3" align="center">
        Integrations
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          mb: 4,
        }}
      >
        Connect your Google business account and Facebook page to collect your customers reviews,
        manage them and collect more!
      </Typography>

      <Typography
        align="center"
        sx={{
          fontWeight: 'bold',
          fontSize: '0.9rem',
        }}
      >
        Connect with Google
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',

          mt: 3,
        }}
      >
        <ManageGoogleAccountButtonAction
          item={{
            location_id: locationList?.id,
            title: locationList?.name,
            email: locationList?.email,
          }}
          linked={!!locationList?.google_location}
          linkedSx={{
            backgroundColor: '#ffffff',
            color: '#727272',
            width: '100%',
            minHeight: '43px',
            maxWidth: '350px',
            border: '1px solid #DDD',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',

            '&:hover': {
              backgroundColor: '#ffffff',
              color: '#000000',
              border: 'none',
            },
            '@media screen and (max-width: 600px)': {
              fontSize: '10px !important',
            },
          }}
          sx={{
            backgroundColor: '#ffffff',
            color: '#727272',
            width: '100%',
            minHeight: '43px',
            maxWidth: '350px',
            border: '1px solid #DDD',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',

            '&:hover': {
              backgroundColor: '#ffffff',
              color: '#000000',
              border: 'none',
            },
            '@media screen and (max-width: 600px)': {
              fontSize: '10px !important',
            },
          }}
        />
      </Stack>
      <Typography
        align="center"
        sx={{
          fontWeight: 'bold',
          fontSize: '0.9rem',
          pt: 5,
          width: '100%',
          minHeight: '43px',
          maxWidth: '350px',
        }}
      >
        Connect with Facebook
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',

          mt: 3,
        }}
      >
        {locationList?.id && (
          <ManageFacebookPageButtonAction
            item={{
              location_id: locationList?.id,
              ...locationList?.facebook_pages[0],
            }}
            linked={!!locationList?.facebook_pages[0]?.id}
            linkedSx={{
              backgroundColor: '#ffffff',
              color: '#727272',
              width: '100%',
              minHeight: '43px',
              maxWidth: '350px',
              border: '1px solid #DDD',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',

              '&:hover': {
                backgroundColor: '#ffffff',
                color: '#000000',
                border: 'none',
              },
              '@media screen and (max-width: 600px)': {
                fontSize: '10px !important',
              },
            }}
            sx={{
              backgroundColor: '#1877f2',
              color: '#ffffff',
              width: '100%',
              minHeight: '43px',
              maxWidth: '350px',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              border: '1px solid #DDD',
              '@media screen and (max-width: 600px)': {
                fontSize: '10px !important',
              },

              '&:hover': {
                backgroundColor: '#1877f2',
                color: '#ffffff',
              },
            }}
          />
        )}

        <Stack spacing={3} alignItems="center" sx={{ mt: 8 }}>
          <Button
            variant="contained"
            sx={{ width: '100%', minHeight: '43px', maxWidth: '350px' }}
            fullWidth
            onClick={() => {
              window.location.reload();
            }}
          >
            Finish
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
