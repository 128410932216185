/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth

import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { Roles } from 'src/enumerate/roles';
import PayementGuard from 'src/auth/guard/payement-guard';
import DomainGuard from 'src/auth/guard/domain-validate-gurad';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/Dashboard'));
const Reviews = lazy(() => import('src/pages/dashboard/reviews/reviews'));
const CustomTestimonials = lazy(() => import('src/pages/dashboard/reviews/custom-reviews'));
const CampaignsPage = lazy(() => import('src/pages/dashboard/campaigns/campaigns'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar/calendar'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat/Chat'));
const Profile = lazy(() => import('src/pages/dashboard/User/profile'));
const Listings = lazy(() => import('src/pages/dashboard/listings/listings'));
const AddCampaignPage = lazy(() => import('src/pages/dashboard/campaigns/campaign/add-campaign'));
const AddAutomationPage = lazy(() => import('src/pages/dashboard/automation/add-automation'));
const AddTemplatePage = lazy(() => import('src/pages/dashboard/campaigns/template/add-template'));
const EditTemplatePage = lazy(() => import('src/pages/dashboard/campaigns/template/edit-template'));
const ViewCampaignPage = lazy(() => import('src/pages/dashboard/campaigns/campaign/campaign'));
const ViewTemplatePage = lazy(() => import('src/pages/dashboard/campaigns/template/template'));
const ViewEmbedPage = lazy(() => import('src/pages/dashboard/embed/embed-reviews'));
const EmbedDetails = lazy(() => import('src/pages/dashboard/embed/emded-details'));
const ReviewEmbedConfiguration = lazy(
  () => import('src/pages/dashboard/embed/embed-configuration')
);

const EmbedReviewPreview = lazy(() => import('src/pages/dashboard/embed/embed-preview'));
const EditAutomationPage = lazy(() => import('src/pages/dashboard/automation/edit-automation'));
const ViewAutomationPage = lazy(() => import('src/pages/dashboard/automation/automation'));
const FeedBacksPage = lazy(() => import('src/pages/dashboard/feedbacks/feedbacks'));
const ChangePasswordAfterInvitation = lazy(() => import('src/pages/dashboard/User/ChangePassword'));
const ContactsPage = lazy(() => import('src/pages/dashboard/contacts/contacts'));
const OnBoarding = lazy(() => import('src/pages/settings/onboarding/OnBoarding'));
const AddTemplateTextPage = lazy(
  () => import('src/pages/dashboard/campaigns/template/text/add-template-text')
);
const EditTemplateTextPage = lazy(
  () => import('src/pages/dashboard/campaigns/template/text/edit-template-text')
);

const Automation = lazy(() => import('src/pages/dashboard/automation/automation'));

const AddTemplateAutomation = lazy(
  () => import('src/pages/dashboard/automation/template/add-template')
);

const A2PVerification = lazy(() => import('src/pages/dashboard/a2p/index'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/change-password',
    element: (
      <RoleBasedGuard roles={[Roles.USER, Roles.ADMIN]} hasContent sx={{ py: 10 }}>
        <ChangePasswordAfterInvitation />
      </RoleBasedGuard>
    ),
  },
  {
    path: 'onboarding',
    element: (
      <AuthGuard>
        <RoleBasedGuard hasContent roles={[Roles.USER, Roles.ADMIN]} sx={{ py: 10 }}>
          <OnBoarding />
        </RoleBasedGuard>
      </AuthGuard>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'reviews',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <Reviews />
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: 'reviews/custom-testimonials',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <CustomTestimonials />
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'listings',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <Listings />
          </RoleBasedGuard>
        ),
        index: true,
      },
      {
        path: 'reviews/feedbacks',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <FeedBacksPage />
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'embed-reviews',

        children: [
          {
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <ViewEmbedPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add/',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <ReviewEmbedConfiguration />
              </RoleBasedGuard>
            ),
            index: true,
          },

          {
            path: 'edit/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <ReviewEmbedConfiguration forUpdate />
              </RoleBasedGuard>
            ),
            index: true,
            roles: [Roles.ADMIN],
          },

          {
            path: 'preview/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <EmbedReviewPreview />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'details/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <EmbedDetails />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      {
        path: 'chat',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <PayementGuard>
              <ChatPage />
            </PayementGuard>
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'Contacts',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <PayementGuard>
              <ContactsPage />
            </PayementGuard>
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'calendar',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <PayementGuard>
              <CalendarPage />
            </PayementGuard>
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'automation',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <Automation />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add-automation',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <AddAutomationPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add-template',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <AddTemplateAutomation />
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'edit-automation/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <EditAutomationPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'automation/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <ViewAutomationPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: 'a2p',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <A2PVerification />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'campaigns',
        children: [
          {
            path: 'list',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <CampaignsPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add-campaign',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <AddCampaignPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          /*  {
             path: 'edit-campaign/:id',
             element: (
               <RoleBasedGuard hasContent sx={{ py: 10 }}>
                 <EditCampaignPage />
               </RoleBasedGuard>
             ),
             index: true,
           }, */

          {
            path: ':id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <ViewCampaignPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add-template-text',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <DomainGuard>
                    <AddTemplateTextPage />
                  </DomainGuard>
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add-template',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <AddTemplatePage />
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'edit-template/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <EditTemplatePage />
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'edit-template-text/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <EditTemplateTextPage />
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },

          {
            path: 'template/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <PayementGuard>
                  <ViewTemplatePage />
                </PayementGuard>
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: 'profile',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <Profile />
          </RoleBasedGuard>
        ),
        index: true,
      },
    ],
  },
];
