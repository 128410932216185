import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify/iconify';
import IcRoundPayment from 'src/assets/icons/payement-icon';

// ----------------------------------------------------------------------

const icon = (iconClass: string) => <Iconify icon={`${iconClass}`} />;

const ICONS = {
  dashboard: icon('clarity:dashboard-solid'),
  ecommerce: icon('clarity:shopping-cart-solid'),
  analytics: icon('clarity:bar-chart-solid'),
  user: icon('majesticons:users-line'),
  chat: icon('material-symbols:chat'),
  calendar: icon('solar:calendar-bold-duotone'),
  reviewsParent: icon('carbon:review'),
  customReviews: icon('material-symbols:rate-review-sharp'),
  reviews: icon('streamline:travel-hotel-three-star-three-stars-reviews-review-rating-hotel-star'),
  avertedFeedback: icon('material-symbols:feedback'),
  campaigns: icon('ic:baseline-campaign'),
  listings: icon('tabler:pointer-pin'),
  embed: icon('fluent-mdl2:embed'),
  contacts: icon('clarity:users-solid'),
  automation: icon('carbon:workflow-automation'),
  payement: <IcRoundPayment />,
  email: icon('bx:bxs-envelope'),
  sms: icon('mdi:cellphone-message'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      /*  {
        subheader: 'Review',
        items: [{ title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard }],
      }, */

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            id: 'side_dash',
          },
          {
            title: 'Contacts',
            path: paths.dashboard.contacts.root,
            icon: ICONS.contacts,
            payement_icon: ICONS.payement,
            id: 'side_contacts',
          },

          {
            title: 'inbox',
            path: paths.dashboard.chat,
            icon: ICONS.chat,
            payement_icon: ICONS.payement,
            id: 'side_inbox',
          },
          {
            title: 'reviews',
            path: paths.dashboard.reviews.root,
            icon: ICONS.reviewsParent,
            id: 'side_reviews',
            children: [
              {
                title: 'Reviews',
                path: paths.dashboard.reviews.root,
              },
              {
                title: 'Testimonials',
                path: paths.dashboard.customTestimonials.root,
              },

              {
                title: 'Averted Feedbacks',
                path: paths.dashboard.feedbacks.root,
              },
            ],
          },
          /* {
            title: 'Listings',
            path: paths.dashboard.listings.root,
            icon: ICONS.listings,
          }, */
          {
            title: 'Campaigns',
            path: paths.dashboard.campaigns.list,
            icon: ICONS.campaigns,
            id: 'side_campaigns',
            payement_icon: ICONS.payement,
          },
          {
            title: 'Automations',
            path: paths.dashboard.automation.root,
            icon: ICONS.automation,
            payement_icon: ICONS.payement,
            id: 'side_automations',
          },
          /*  {
            title: 'calendar',
            path: paths.dashboard.calendar.root,
            icon: ICONS.calendar,
          }, */
          {
            title: 'Embed Reviews',
            path: paths.dashboard.embedReviews.root,
            icon: ICONS.embed,
            id: 'side_embed',
          },
        ],
      },
    ],
    []
  );

  return data;
}
