import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
// ----------------------------------------------------------------------

function SeoIllustration({ ...other }: BoxProps) {


  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 480 360"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <image href="/assets/illustrations/characters/dashboard.png" height="300" x="0" y="30" />
    </Box>
  );
}

export default memo(SeoIllustration);
