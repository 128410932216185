export enum LogsActions {
  REGISTER = 'Register',
  UPDATE_PASSWORD = 'Update_Password',

  // Company
  CREATE_COMPANY = 'Create_Company',
  UPDATE_COMPANY = 'Update_Company',
  DELETE_COMPANY = 'Delete_Company',
  INVITE_MEMBER = 'Invite_Member', // still not implemented

  // Contacts
  CREATE_CONTACT = 'Create_Contact',
  UPDATE_CONTACT = 'Update_Contact',
  DELETE_CONTACT = 'Delete_Contact',
  ADD_CONTACT_ACTION = 'Add_Contact_Action',
  UPDATE_CONTACT_ACTION = 'Update_Contact_Action',
  DELETE_MULTIPLE_CONTACTS = 'Delete_Multiple_Contacts',
  EXPORT_CSV_CONTACTS = 'EXPORT_CSV_CONTACTS',
  IMPORT_CSV_CONTACTS = 'IMPORT_CSV_CONTACTS',

  // message
  SEND_MESSAGE = 'Send_Message',
  SEND_MESSAGE_TO_CONTACT = 'Send_Message_To_Contact',
  START_CONVERSATION = 'Start_Conversation',

  // Embed Review
  CREATE_EMBED_REVIEW = 'Create_Embed_Review',
  UPDATE_EMBED_REVIEW = 'Update_Embed_Review',
  DELETE_EMBED_REVIEW = 'Delete_Embed_Review',
  BULK_DELETE_EMBED_REVIEW = 'Bulk_Delete_Embed_Review',

  // Facebook_Pages
  UPDATE_FACEBOOK_PAGE = 'Update_Facebook_Page',
  DELETE_FACEBOOK_PAGE = 'Delete_Facebook_Page',
  DELETE_MULTIPLE_FACEBOOK_PAGES = 'Delete_Multiple_Facebook_Pages',
  RETRIEVE_FACEBOOK_PAGES_AND_REVIEWS = 'Retrieve_Facebook_Pages_And_Reviews',
  GET_FACEBOOK_PAGE_FILTERS = 'Get_Facebook_Page_Filters',
  FACEBOOK_PROVIDER = 'Facebook_Provider',
  FACEBOOK_AUTO_REPLY = 'Facebook_Auto_Reply',
  FACEBOOK_REPLY = 'Facebook_Reply',

  // GOOGLE
  GET_GOOGLE_LOCATIONS = 'Get_GOOGLE_Locations',
  CHANGE_AUTO_REPLY_GOOGLE = 'Change_Auto_Reply_Google',
  SAVE_GOOGLE_LOCATIONS = 'Save_Google_Locations',
  UPDATE_GOOGLE_LOCATION_COMPANY = 'Update_Google_Location_Company',
  DELETE_LOCATION_FROM_GOOGLE = 'Delete_Location_From_Google',
  FETCH_GOOGLE_REVIEWS = 'Fetch_Google_Reviews',
  GOOGLE_PROVIDER = 'Google_Provider',
  GOOGLE_REPLY = 'Google_Reply',

  // Locations
  CREATE_LOCATION = 'Create_Location',
  UPDATE_LOCATION = 'Update_Location',
  DELETE_LOCATION = 'Delete_Location',
  DELETE_MULTIPLE_LOCATIONS = 'Delete_Multiple_Locations',
  ADD_LOCATION_TEMPLATE = 'Add_Location_Template',
  ASSIGN_LOCATION = 'Assign_Location',
  LINK_FACEBOOK_PAGE = 'Link_Facebook_Page',
  LINK_GOOGLE_MAP = 'Link_Google_Map',
  UNLINK_FACEBOOK_PAGE = 'Link_Facebook_Page',
  UNLINK_GOOGLE_MAP = 'Link_Google_Map',
  // ONBOARDING
  ONBOARDING = 'Onboarding',

  // REwIEWS
  FACEBOOK_REVIEWS = 'Facebook_Reviews',
  GOOGLE_REVIEWS = 'Google_Reviews',
  PROVIDERS = 'Providers',

  // USERS
  CREATE_USER = 'Create_User',
  DELETE_USER = 'Delete_User',
  UPDATE_USER = 'Update_User',
  DEACTIVATE_USER = 'Deactivate_User',
  UPDATE_PROFILE = 'Update_Account',

  // UPLOAD Image
  UPLOAD_IMAGE = 'Upload_Image',
  DELETE_IMAGE = 'Delete_Image',

  // errors for All gets function
  GET_COMPANY = 'Get_Company',
  GET_LOCATIONS = 'Get_Locations',
  GET_USERS = 'Get_Users',
  GET_USER = 'Get_User',
  GET_CONTACTS = 'Get_Contacts',
  GET_LOCATION_CONTACTS_INFO = 'Get_Location_Contacts_Info',
  GET_CONTACTS_ACTIONS = 'Get_Contacts_Actions',
  GET_CONVERSATION = 'Get_Conversation',
  GET_CONVERSATION_SID = 'Get_Conversation_Sid',
  GET_MESSAGES = 'Get_Messages',
  GET_EMBED_REVIEW = 'Get_Embed_Review',
  GET_EMBED_REVIEWS = 'Get_Embed_Reviews',
  GET_EMBED_REVIEW_BY_USER = 'GET_EMBED_REVIEW',
  GET_EMBED_REVIEW_BY_ID = 'GET_EMBED_REVIEW_BY_ID',
  GET_USER_ASSIGNED_LOCATIONS = 'Get_User_Assigned_Locations',
  GET_UNLINKED_LOCATIONS = 'Get_Unlinked_Locations',
  GET_LOCATION_TEMPLATE = 'Get_Location_Template',
  GET_PROFILE = 'Get_Profile',
  GET_NOTIFICATIONS = 'Get_Notifications',
  SET_NOTIFICATION_READ = 'Set_Notification_Read',
  SET_ALL_NOTIFICATIONS_READ = 'Set_All_Notifications_Read',
}
