import { Button, Card, Container, SxProps, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import PayementIllustration from 'src/assets/illustrations/payement-illustration';
import { paths } from 'src/routes/paths';
import { MotionContainer } from '../animate';

type Props = {
  children?: React.ReactNode;
  title?: string;
  text_content?: string;
  sx?: SxProps<Theme>;
};

export default function PaymentRequire({ children, title, text_content, sx }: Props) {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="md"
      component={MotionContainer}
      sx={{ textAlign: 'center', position: 'relative', ...sx }}
    >
      <Card
        sx={{
          filter: 'drop-shadow(0px 30px 120px rgba(24, 119, 242, 0.62))',
          backgroundColor: 'transparent',

          border: 'none',
          boxShadow: 'none',
          padding: 10,
        }}
      >
        <PayementIllustration sx={{ height: 260, margin: 'auto' }} />

        <Typography gutterBottom variant="h3">
          {title || 'Payment Required'}
        </Typography>
        <Typography variant="body2">
          {text_content || 'You need to pay to access this page'}
        </Typography>
        {children || (
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 8 }}
            onClick={() => {
              navigate(paths.settings.withTab('billing'));
            }}
          >
            Upgrade Now
          </Button>
        )}
      </Card>
    </Container>
  );
}
