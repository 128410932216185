import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';
import { SplashScreen } from 'src/components/loading-screen';
import AuthSectionView from 'src/layouts/auth/AuthSectionView';
import clsx from 'clsx';
import { useSelector } from 'src/redux/store';
import IllustrationsOnboardingSection from './IllustrationsOnboardingSection';
import styles from './styles.module.css';
import OnboardingSection from './onboardingSection';

export default function OnBoarding() {
  const steps = ['Company', 'Locations', 'Integrations'];
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const upMd = useResponsive('up', 'md');
  const [color, setColor] = useState<string>();
  const [transitioning, setTransitioning] = useState(false);
  const [startSettingsAnimation, setStartSettingsAnimation] = useState(false);
  const [startLocationAnimation, setStartLocationAnimation] = useState(false);
  const [startIntegrationAnimation, setStartIntegrationAnimation] = useState(false);
  const getUserMetadataValue = (metadataField: any) => user?.user_metadata?.[metadataField];

  const activeStep = (() => {
    const companyOnboarding = getUserMetadataValue('company_onboarding');
    const locationsOnboarding = getUserMetadataValue('locations_onboarding');
    const integrationsOnboarding =
      getUserMetadataValue('integrations_facebook_onboarding') ||
      getUserMetadataValue('integrations_google_onboarding');

    if (companyOnboarding) return 0;
    if (locationsOnboarding) return 1;
    if (integrationsOnboarding) return 2;

    return 0;
  })();

  const [activeStepState, setActiveStep] = useState(activeStep);

  const isStepSkipped = (step: any) => {
    const isCompanyOnboarding = getUserMetadataValue('company_onboarding');
    const isLocationsOnboarding = getUserMetadataValue('locations_onboarding');
    const isIntegrationsOnboarding =
      getUserMetadataValue('integrations_facebook_onboarding') &&
      getUserMetadataValue('integrations_google_onboarding');

    switch (step) {
      case 0:
        return !isCompanyOnboarding || false;
      case 1:
        return !isLocationsOnboarding || false;
      case 2:
        return !isIntegrationsOnboarding || false;
      default:
        return false;
    }
  };

  const heandleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const makeRandomColor = useCallback(() => {
    const colors = ['#2a3ca6', '#4d3395', '#53688f'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    const randomColor = colors[randomIndex];
    return randomColor;
  }, []);

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
      bgColor={color}
      transitioning={transitioning}
      isWhiteLogo
    />
  );

  const handleChangeAnimation = useCallback((animationType: string) => {
    setStartSettingsAnimation(animationType === 'settings');
    setStartLocationAnimation(animationType === 'location');
    setStartIntegrationAnimation(animationType === 'integration');
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true);
      const colorMaterial = makeRandomColor();
      setColor(colorMaterial);
    }, 6000);
    return () => clearInterval(interval);
  }, [makeRandomColor]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTransitioning(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [color]);

  useEffect(() => {
    if (activeStepState === 0) handleChangeAnimation('settings');
    if (activeStepState === 1) handleChangeAnimation('location');
    if (activeStepState === 2) handleChangeAnimation('integration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepState]);

  const divsStart = (
    <IllustrationsOnboardingSection
      setStartIntegrationAnimation={setStartIntegrationAnimation}
      setStartLocationAnimation={setStartLocationAnimation}
      setStartSettingsAnimation={setStartSettingsAnimation}
      startIntegrationAnimation={startIntegrationAnimation}
      startLocationAnimation={startLocationAnimation}
      startSettingsAnimation={startSettingsAnimation}
    />
  );
  const renderSection = <AuthSectionView color={color} transitioning={transitioning} />;

  if (color === '#fff' || !color) {
    return <SplashScreen />;
  }
  return (
    <div
      className={clsx(
        ' md:overflow-hidden md:w-full md:h-full flex flex-row justify-center items-center mr-20 ',
        styles.onboarding
      )}
    >
      {upMd && renderLogo}
      {upMd && renderSection}
      {upMd && divsStart}

      <div
        className={clsx(
          styles.container,
          ` md:overflow-y-scroll md:overflow-x-hidden sm:overflow-y-scroll sm:overflow-x-hidden sm:w-full sm:h-full  md:w-full md:h-full md:mx-auto md:ml-auto flex justify-center items-center md:min-h-screen ${
            upMd ? 'md:mr-20 md:mb-30 md:mb-0' : ''
          }`
        )}
        style={{
          width: '100%',
          maxWidth: '68vh',
          height: '100%',
        }}
      >
        <OnboardingSection
          activeStepState={activeStepState}
          setActiveStep={setActiveStep}
          steps={steps}
          isStepSkipped={isStepSkipped}
          heandleLogout={heandleLogout}
        />
      </div>
    </div>
  );
}
