import { Card, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomizedSteppers from 'src/sections/app/view/customized-steppers';
import IntegrationsOnboarding from 'src/sections/settings/onboarding/integrationOnboarding';
import OnbordingCompany from 'src/sections/settings/onboarding/onBoardingForms/companyOnboardingForm';
import LocationsOnBoardingFrom from 'src/sections/settings/onboarding/onBoardingForms/locations';
import clsx from 'clsx';
import { useSelector } from 'src/redux/store';
import styles from './styles.module.css';

type Props = {
  activeStepState: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  steps: string[];
  isStepSkipped: (step: number) => boolean;
  heandleLogout: () => void;
};

export default function OnboardingSection({
  activeStepState,
  setActiveStep,
  steps,
  isStepSkipped,
  heandleLogout,
}: Props) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const user = useSelector((state) => state.user);

  return (
    <Card className={clsx(styles.onboardCard)}>
      <Stack
        sx={{
          position: 'absolute',
          right: '0',
          top: '0',
          margin: '2rem',
        }}
      >
        <Tooltip title="Logout">
          <IconButton
            onClick={heandleLogout}
            sx={{
              color: isDark ? '#000 !important' : '#fff !important',
              backgroundColor: isDark ? '#fff !important' : '#000 !important',
            }}
          >
            <Iconify icon="mdi:logout" width={18} />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack
        className={clsx(styles.CardContent)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '150vh',
          height: '100%',
          margin: '0 auto',
          mb: 5,
        }}
      >
        <Stack
          sx={{
            mb: 3,
          }}
        >
          <CustomizedSteppers
            activeStep={activeStepState}
            STEPS={steps}
            isStepSkipped={isStepSkipped}
          />
        </Stack>
        {activeStepState === 0 && (
          <OnbordingCompany
            isLocationOnboarding={user?.user_metadata?.locations_onboarding}
            isIntegraionsOnboarding={
              !user?.user_metadata?.integrations_facebook_onboarding ||
              !user?.user_metadata?.integrations_google_onboarding
            }
            setActiveStep={setActiveStep}
          />
        )}
        {activeStepState === 1 && (
          <LocationsOnBoardingFrom
            setActiveStep={setActiveStep}
            isIntegraionsOnboarding={
              user?.user_metadata?.integrations_facebook_onboarding ||
              user?.user_metadata?.integrations_google_onboarding
            }
          />
        )}
        {activeStepState === 2 && <IntegrationsOnboarding />}
      </Stack>
    </Card>
  );
}
