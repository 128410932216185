import { Card, Grid, Stack } from '@mui/material';
import Image from 'src/components/image';

export default function IllustrationsAuthSection() {
  return (
    <div className="md:overflow-hidden md:w-full md:h-full flex flex-row justify-center items-center">
      <Grid container>
        <Grid item xs={12} md={12}>
          <Stack direction="column" spacing={5}>
            <Stack
              direction="row"
              spacing={5}
              sx={{
                /* hide on mobile and ipads  */

                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                marginRight: '48px',
                marginTop: '55px',

                '@media (max-width: 960px)': {
                  display: 'none',
                },
                '@media (max-width: 1133px) and (max-height: 903px)': {
                  display: 'none',
                },
              }}
            >
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '150px',
                  boxShadow: '-10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
                  height: '139px',
                }}
              >
                <Image
                  src="
                    /assets/1.png"
                  alt="revcall"
                />
              </Card>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '150px',
                  boxShadow: '-10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
                  height: '139px',
                }}
              >
                <Image
                  src="
                    /assets/2.png"
                  alt="revcall"
                />
              </Card>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '150px',
                  boxShadow: '-10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
                  height: '139px',
                }}
              >
                <Image
                  src="
                    /assets/3.png"
                  alt="revcall"
                />
              </Card>
            </Stack>
            <Stack
              direction="row"
              spacing={5}
              sx={{
                /* hide on mobile and ipads  */

                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',

                '@media (max-width: 960px)': {
                  display: 'none',
                },
                '@media (max-width: 1133px) and (max-height: 903px)': {
                  display: 'none',
                },
              }}
            >
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '200px',
                  boxShadow: '-10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
                  height: '200px',
                }}
              >
                <Image
                  src="
                    /assets/4.png"
                  alt="revcall"
                />
              </Card>

              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '350px',
                  boxShadow: '-10px 10px 10px 10px rgba(0, 0, 0, 0.5)',
                  height: '200px',
                  padding: '20px',
                }}
              >
                <Image
                  src="
                    /assets/5.png"
                  alt="revcall"
                />
              </Card>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
