interface CharToColorMap {
  [key: string]: string;
}

const charToColorMap: CharToColorMap = {
  A: '#FF5733', // A shade of red
  B: '#3498DB', // A shade of blue
  C: '#2ECC71', // A shade of green
  D: '#FFA726', // A shade of orange
  E: '#9B59B6', // A shade of purple
  F: '#36D7B7', // A shade of cyan
  G: '#FF6E98', // A shade of pink
  H: '#F1C40F', // A shade of yellow
  I: '#E91E63', // A shade of magenta
  J: '#8D6E63', // A shade of brown
  K: '#00BCD4', // A shade of teal
  L: '#9C27B0', // A shade of violet
  M: '#3F51B5', // A shade of indigo
  N: '#607D8B', // A shade of slate gray
  O: '#795548', // A shade of brown
  P: '#E74C3C', // A shade of coral
  Q: '#8BC34A', // A shade of green
  R: '#03A9F4', // A shade of light blue
  S: '#388E3C', // A shade of green
  T: '#0288D1', // A shade of blue
  U: '#FF5722', // A shade of red
  V: '#9C27B0', // A shade of violet
  W: '#D50000', // A shade of dark red
  X: '#FFD600', // A shade of gold
  Y: '#CDDC39', // A shade of lime
  Z: '#FF4081', // A shade of pink
  a: '#FF5733', // A shade of red
  b: '#3498DB', // A shade of blue
  c: '#2ECC71', // A shade of green
  d: '#FFA726', // A shade of orange
  e: '#9B59B6', // A shade of purple
  f: '#36D7B7', // A shade of cyan
  g: '#FF6E98', // A shade of pink
  h: '#F1C40F', // A shade of yellow

  i: '#E91E63', // A shade of magenta
  j: '#8D6E63', // A shade of brown
  k: '#00BCD4', // A shade of teal

  l: '#9C27B0', // A shade of violet
  m: '#3F51B5', // A shade of indigo
  n: '#607D8B', // A shade of slate gray
  o: '#795548', // A shade of brown
  p: '#E74C3C', // A shade of coral

  q: '#8BC34A', // A shade of green
  r: '#03A9F4', // A shade of light blue
  s: '#388E3C', // A shade of green
  t: '#0288D1', // A shade of blue
  u: '#FF5722', // A shade of red
  v: '#9C27B0', // A shade of violet
  w: '#D50000', // A shade of dark red
  x: '#FFD600', // A shade of gold
  y: '#CDDC39', // A shade of lime
  z: '#FF4081', // A shade of pink
  0: '#FF5733', // A shade of red
  1: '#3498DB', // A shade of blue
  2: '#2ECC71', // A shade of green
  3: '#FFA726', // A shade of orange
  4: '#9B59B6', // A shade of purple
  5: '#36D7B7', // A shade of cyan
  6: '#FF6E98', // A shade of pink
  7: '#F1C40F', // A shade of yellow
  8: '#E91E63', // A shade of magenta
  9: '#8D6E63', // A shade of brown
  ' ': '#607D8B', // A shade of slate gray
  '.': '#795548', // A shade of brown
  ',': '#E74C3C', // A shade of coral
  '?': '#8BC34A', // A shade of green
  '!': '#03A9F4', // A shade of light blue
  '@': '#388E3C', // A shade of green
  '#': '#0288D1', // A shade of blue
  $: '#FF5722', // A shade of red
  '%': '#9C27B0', // A shade of violet
  '^': '#D50000', // A shade of dark red
  '&': '#FFD600', // A shade of gold
  '*': '#CDDC39', // A shade of lime
  '(': '#FF4081', // A shade of pink
  ')': '#FF5733', // A shade of red
  '-': '#3498DB', // A shade of blue

  _: '#2ECC71', // A shade of green
  '=': '#FFA726', // A shade of orange
  '+': '#9B59B6', // A shade of purple
  '[': '#36D7B7', // A shade of cyan
  ']': '#FF6E98', // A shade of pink
  '{': '#F1C40F', // A shade of yellow
  '}': '#E91E63', // A shade of magenta
  '|': '#8D6E63', // A shade of brown
  ';': '#00BCD4', // A shade of teal
  ':': '#9C27B0', // A shade of violet
  "'": '#3F51B5', // A shade of indigo
  '"': '#607D8B', // A shade of slate gray
  '<': '#795548', // A shade of brown
  '>': '#E74C3C', // A shade of coral
  '/': '#8BC34A', // A shade of green
  '\\': '#03A9F4', // A shade of light blue
  '`': '#388E3C', // A shade of green
  '~': '#0288D1', // A shade of blue
};

export function generateRandomColor(firstCharColor: string) {
  if (charToColorMap[firstCharColor]) {
    return charToColorMap[firstCharColor];
  }

  const randomColorPart = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColorPart}`;
}
