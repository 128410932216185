import { useTheme } from '@mui/material/styles';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';
import { useCallback, useEffect, useState } from 'react';
import { SplashScreen } from 'src/components/loading-screen';
import clsx from 'clsx';
import styles from './styles.module.css';
import AuthSectionView from './AuthSectionView';
import IllustrationsAuthSection from './IllustrationsAuthSection';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const [color, setColor] = useState<string>();
  const [transitioning, setTransitioning] = useState(false); // New state for animation

  const makeRandomColor = useCallback(() => {
    const colors = ['#2a3ca6', '#4d3395', '#53688f'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    const randomColor = colors[randomIndex];
    return randomColor;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransitioning(true);
      const colorMaterial = makeRandomColor();
      setColor(colorMaterial);
    }, 2000);
    return () => clearInterval(interval);
  }, [makeRandomColor, theme.palette]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTransitioning(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [color]);

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
      bgColor={color}
      transitioning={transitioning}
      isWhiteLogo
    />
  );

  const renderSection = <AuthSectionView color={color} transitioning={transitioning} />;

  if (color === '#fff' || !color) {
    return <SplashScreen />;
  }

  return (
    <div className="md:overflow-hidden md:w-full md:h-full flex flex-row justify-center items-center">
      {upMd && renderLogo}

      {upMd && renderSection}

      {upMd && <IllustrationsAuthSection />}

      <div
        className={clsx(
          styles.container,
          `md:overflow-y-scroll md:overflow-x-hidden md:w-full md:h-full md:mx-auto md:ml-auto flex justify-center items-center md:min-h-screen ${upMd ? 'md:mr-20 md:mb-30' : ''
          }`
        )}
        style={{
          width: '100%',
          maxWidth: '58vh',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
}
