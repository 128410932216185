// ----------------------------------------------------------------------

const ROOTS = {
  ROOT: '/',
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
};

// ----------------------------------------------------------------------

export const paths = {
  changePassword: `${ROOTS.ROOT}change-password`,
  onBoarding: `${ROOTS.ROOT}onboarding`,
  reviews: (id: string) => `${ROOTS.ROOT}/reviews/${id}`,
  apiDocs: `${ROOTS.ROOT}api-docs`,

  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
      recoverPassword: `${ROOTS.AUTH}/recover-password`,
      resetPassword: `${ROOTS.AUTH}/reset-password`,
    },
    verify: {
      root: `${ROOTS.AUTH}/verify`,
      emailVerified: `${ROOTS.AUTH}/email-verified`,
    },
  },
  notfound: '/404',
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      add: `${ROOTS.DASHBOARD}/users/add-user`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/users/edit-user/${id}`,
    },
    reviews: {
      root: `${ROOTS.DASHBOARD}/reviews`,
    },
    customTestimonials: {
      root: `${ROOTS.DASHBOARD}/reviews/custom-testimonials`,
    },

    embedReviews: {
      root: `${ROOTS.DASHBOARD}/embed-reviews`,
      add: `${ROOTS.DASHBOARD}/embed-reviews/add`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/embed-reviews/edit/${id}`,
      preview: (id: string) => `${ROOTS.DASHBOARD}/embed-reviews/preview/${id}`,
      details: (id: string) => `${ROOTS.DASHBOARD}/embed-reviews/details/${id}`,
    },
    chat: `${ROOTS.DASHBOARD}/chat`,
    feedbacks: {
      root: `${ROOTS.DASHBOARD}/reviews/feedbacks`,
    },

    listings: {
      root: `${ROOTS.DASHBOARD}/listings`,
    },

    contacts: {
      root: `${ROOTS.DASHBOARD}/contacts`,
    },
    locations: {
      root: `${ROOTS.DASHBOARD}/locations`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/locations/edit/${id}`,
    },
    automation: {
      root: `${ROOTS.DASHBOARD}/automation`,
      add: `${ROOTS.DASHBOARD}/automation/add-automation`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/automation/edit-automation/${id}`,
      view: (id: string) => `${ROOTS.DASHBOARD}/automation/automation/${id}`,
      details: (id: string) =>
        `${ROOTS.DASHBOARD}/automation/edit-automation/${id}?current=details`,
      templates: {
        add: `${ROOTS.DASHBOARD}/automation/add-template`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/automation/edit-template/${id}`,
        view: (id: string) => `${ROOTS.DASHBOARD}/automation/template/${id}`,
        addText: `${ROOTS.DASHBOARD}/automation/add-template-text`,
        editText: (id: string) => `${ROOTS.DASHBOARD}/automation/edit-template-text/${id}`,
      },
    },
    campaigns: {
      list: `${ROOTS.DASHBOARD}/campaigns/list`,
      campaigns: {
        add: `${ROOTS.DASHBOARD}/campaigns/add-campaign`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/campaigns/edit-campaign/${id}`,
        view: (id: string) => `${ROOTS.DASHBOARD}/campaigns/${id}`,
      },
      automations: {
        add: `${ROOTS.DASHBOARD}/campaigns/add-automation`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/campaigns/edit-automation/${id}`,
        view: (id: string) => `${ROOTS.DASHBOARD}/campaigns/automation/${id}`,
      },

      templates: {
        add: `${ROOTS.DASHBOARD}/campaigns/add-template`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/campaigns/edit-template/${id}`,
        view: (id: string) => `${ROOTS.DASHBOARD}/campaigns/template/${id}`,
        addText: `${ROOTS.DASHBOARD}/campaigns/add-template-text`,
        editText: (id: string) => `${ROOTS.DASHBOARD}/campaigns/edit-template-text/${id}`,
      },
    },
    calendar: {
      root: `${ROOTS.DASHBOARD}/calendar`,
    },
    profile: {
      root: `${ROOTS.DASHBOARD}/profile`,
    },
    a2pVerification: {
      root: `${ROOTS.DASHBOARD}/a2p`,
      successRequest: `${ROOTS.DASHBOARD}/a2p/success-request`,
    },
  },

  settings: {
    root: ROOTS.SETTINGS,
    templateLocations: `${ROOTS.SETTINGS}/template-locations`,
    textTemplateLocations: `${ROOTS.SETTINGS}/text-template-locations`,
    withTab: (tab: string) => `${ROOTS.SETTINGS}?tab=${tab}`,
  },
};
