import { store } from 'src/redux/store';
import { CreateLogsForm } from 'src/types/logs.type';
import { supabaseClient } from 'src/utils/supabaseClient';

export async function createNewLog(log: CreateLogsForm) {
  console.log('🚀 ~ createNewLog ~ log:', log);
  const { user: userConnect } = store.getState();
  console.log('🚀 ~ createNewLog ~ userConnect:', userConnect);

  if (userConnect && !log.user_id && !log.user_email && !userConnect) {
    log.user_id = userConnect.id;
    log.user_email = userConnect.email;
  }
  await supabaseClient.from('activity_logs').insert(log);
}

export const getCSVLogs = async (): Promise<any[]> => {
  const {
    data: { user },
  } = await supabaseClient.auth.getUser();
  if (!user?.id) {
    throw new Error("You're not authenticated");
  }

  const { data: logs, error } = await supabaseClient
    .from('activity_logs')
    .select('id,action ,message,is_error,user:user_id(id:user_id,name),created_at')
    .eq('table_name', 'contacts')
    .eq('user_id', user?.id)
    .order('created_at', { ascending: false });

  if (error) {
    return [];
  }

  return logs || [];
};
