/* eslint-disable import/no-extraneous-dependencies */
// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// ----------------------------------------------------------------------

// routes
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';

// locales
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from 'src/locales';
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';

// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { SnackbarProvider } from 'src/components/snackbar';
import { store } from './redux/store';
// react-qeury client
import queryClient from './utils/react-query-client';
import './index.scss';
import './tailwind.css';
import { LocationProvider } from './contexts/locations';
import { WalktourProvider } from './contexts/walktour/walktour-context';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <ReduxProvider store={store}>
      <AuthProvider>
        <LocationProvider>
          <LocalizationProvider>
            <SettingsProvider
              defaultSettings={{
                themeMode: 'light', // 'light' | 'dark'
                themeDirection: 'ltr', //  'rtl' | 'ltr'
                themeContrast: 'bold', // 'default' | 'bold'
                themeLayout: 'mini', // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: false,
              }}
            >
              <ThemeProvider>
                <WalktourProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <SettingsDrawer />
                      <ProgressBar />
                      <AuthConsumer>
                        <QueryClientProvider client={queryClient}>
                          <ReactQueryDevtools initialIsOpen={false} />

                          <Router />
                        </QueryClientProvider>
                      </AuthConsumer>
                    </SnackbarProvider>
                  </MotionLazy>
                </WalktourProvider>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </LocationProvider>
      </AuthProvider>
    </ReduxProvider>
  );
}
