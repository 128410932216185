import { useDispatch } from 'react-redux';
import { billingInfoUpdated } from 'src/redux/slices/billing';
import { store } from 'src/redux/store';
import { IBillingAddress } from 'src/types/address';
import { ICARD_STRIPE } from 'src/types/card';
import axiosBackendWithoutVirsionInstance from 'src/utils/axios-backend-without-virsion';
import { supabaseClient } from 'src/utils/supabaseClient';

export function useCards() {
  const dispatch = useDispatch();
  const addCard = async (card_data: ICARD_STRIPE) => {
    try {
      await axiosBackendWithoutVirsionInstance.post('/stripe/card', card_data);
      const cards = await getCards();
      dispatch(
        billingInfoUpdated({
          user_cards: cards,
        })
      );
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        throw new Error(e.response.data.message);
      } else {
        throw new Error(e.message);
      }
    }
  };
  const updateCard = async (card_data: ICARD_STRIPE) => {
    try {
      const data = await axiosBackendWithoutVirsionInstance.put(`/stripe/card`, card_data);
      const cards = await getCards();
      dispatch(
        billingInfoUpdated({
          user_cards: cards,
        })
      );
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || e?.message);
    }
  };
  const deleteCard = async (card_id: string) => {
    try {
      const data = await axiosBackendWithoutVirsionInstance.delete(`/stripe/card/${card_id}`);
      const cards = await getCards();
      dispatch(
        billingInfoUpdated({
          user_cards: cards,
        })
      );
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || e?.message);
    }
  };

  return { addCard, updateCard, deleteCard, getCards };
}
export const getCards = async () => {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.get('/stripe/card');
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
};

async function addSubscription(input_data: { price_id: string; default_card?: string }) {
  try {
    const {
      user: { my_locations },
    } = store.getState();
    const { data } = await axiosBackendWithoutVirsionInstance.post('/stripe/subscription', {
      quantity: my_locations?.length,
      price_id: input_data.price_id,
      default_card: input_data.default_card,
    });
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message || e?.message);
  }
}

export function useAddSubscription() {
  const dispatch = useDispatch();
  const insertSubscription = async (input_data: { price_id: string; default_card?: string }) => {
    try {
      const {
        user: { company },
      } = store.getState();
      const data = await addSubscription(input_data);
      const billing_address = await getBillingAddress();
      const billing = await getBillingData();
      const active_plans = await getActiveSubscription(company?.id as string);
      const user_cards = await getCards();
      dispatch(
        billingInfoUpdated({
          active_plan: active_plans,
          plans: billing,
          billing_address,
          user_cards,
        })
      );

      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || e?.message);
    }
  };

  return { insertSubscription };
}

export function useUpdatePlan() {
  const dispatch = useDispatch();
  const {
    user: { company },
  } = store.getState();

  const updatePlan = async (subscription_id: string, price_id: string) => {
    try {
      const { data } = await axiosBackendWithoutVirsionInstance.put(
        `/stripe/subscription/update-plan/${subscription_id}/${price_id}`
      );
      const billing_address = await getBillingAddress();
      const billing = await getBillingData();
      const active_plans = await getActiveSubscription(company?.id as string);
      const user_cards = await getCards();
      dispatch(
        billingInfoUpdated({
          active_plan: active_plans,
          plans: billing,
          billing_address,
          user_cards,
        })
      );
      return data;
    } catch (e) {
      throw new Error(e?.response?.data?.message || e?.message);
    }
  };

  return { updatePlan };
}

export async function addCustomerWithoutBilling() {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.post('/stripe/init-customer');
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function addCustomer() {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.post('/stripe/customer');
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function getPlanById(id: string) {
  const { data, error: err } = await supabaseClient
    .from('plans')
    .select('*')
    .eq('id', id)
    .maybeSingle();
  if (err) {
    throw new Error(`Error fetching billing: ${err.message}`);
  }
  return data;
}

export async function getBillingData() {
  const { data, error: err } = await supabaseClient
    .from('plans')
    .select('*')
    .eq('active', true)
    .order('price', { ascending: true });
  if (err) {
    throw new Error(`Error fetching billing: ${err.message}`);
  }

  /* i need to display data of plan normal and add selected_plan variable contain subscription */

  return data;
}

export async function getActiveSubscription(company: string) {
  if (!company) return null;

  const { data: subscription, error } = await supabaseClient
    .from('subscriptions')
    .select(
      'next_billing_date,status,subscription_id,current_period_start,current_period_end,latest_invoice,...plan_id(*)'
    )
    .eq('company_id', company)
    .maybeSingle();
  if (error) {
    throw new Error(`Error fetching billing: ${error.message}`);
  }
  return subscription;
}

export async function getInvoices() {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.get('/stripe/subscription/invoices');
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function retryPayment() {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.put(
      `/stripe/subscription/retry-invoice`
    );
    return data;
  } catch (e) {
    throw new Error(e?.response?.data?.message || e?.message);
  }
}

export async function reactivateSubscription(subscription_id: string) {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.put(
      `/stripe/subscription/reactivate/${subscription_id}`
    );
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function checkAddLocation(): Promise<Boolean> {
  const { data } = await axiosBackendWithoutVirsionInstance.get(
    '/stripe/subscription/check-subscription'
  );
  return data as Boolean;
}

async function addBillingAddress(address: IBillingAddress) {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.post(
      '/stripe/billing-address',
      address
    );
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

async function updateBillingAddress(address: IBillingAddress) {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.put(
      `/stripe/billing-address/${address.id}`,
      address
    );
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

async function deleteBillingAddress(id: string) {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.delete(
      `/stripe/billing-address/${id}`
    );
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function addFeedBackCancelSubscription(data: any) {
  try {
    const { user } = store.getState();
    const finale_data = {
      ...data,
      user_id: user?.id,
    };
    const { error } = await supabaseClient
      .from('feedback_canceling_subscription')
      .insert([finale_data]);
    if (error) {
      throw new Error(`Error fetching billing: ${error.message}`);
    }
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function setDefaultBillingAddress(id: string) {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.put(
      `/stripe/billing-address/${id}/default`
    );
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function getCredits(company_id: string) {
  try {
    const { data: sms_credits, error } = await supabaseClient
      .from('sms_credits')
      .select('max_credits,monthly_credits')
      .eq('company_id', company_id)
      .maybeSingle();

    if (error) {
      throw new Error(`Error fetching billing: ${error.message}`);
    }
    const { data: email_credits, error: err } = await supabaseClient
      .from('email_credits')
      .select('max_credits,monthly_credits')
      .eq('company_id', company_id)
      .maybeSingle();
    if (err) {
      throw new Error(`Error fetching billing: ${err.message}`);
    }
    return {
      sms_credits,
      email_credits,
    };
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function cancelSubscription(subscription_id: string) {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.delete(
      `/stripe/subscription/cancel/${subscription_id}`
    );
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export async function getBillingAddress() {
  try {
    const { data } = await axiosBackendWithoutVirsionInstance.get('/stripe/billing-address');
    return data;
  } catch (e) {
    throw new Error(`Error fetching billing: ${e.message}`);
  }
}

export function useBillingAddress() {
  const dispatch = useDispatch();
  const createBillingAddress = async (address: IBillingAddress) => {
    try {
      const data = await addBillingAddress(address);
      const billing_address = await getBillingAddress();
      dispatch(
        billingInfoUpdated({
          billing_address,
        })
      );
      return data;
    } catch (e) {
      throw new Error(`Error fetching billing: ${e.message}`);
    }
  };
  const changeBillingAddress = async (address: IBillingAddress) => {
    try {
      const data = await updateBillingAddress(address);
      const billing_address = await getBillingAddress();
      dispatch(
        billingInfoUpdated({
          billing_address,
        })
      );
      return data;
    } catch (e) {
      throw new Error(`Error fetching billing: ${e.message}`);
    }
  };
  const deleteBillingAddr = async (id: string) => {
    try {
      const data = await deleteBillingAddress(id);
      const billing_address = await getBillingAddress();
      dispatch(
        billingInfoUpdated({
          billing_address,
        })
      );
      dispatch(
        billingInfoUpdated({
          billing_address,
        })
      );
      return data;
    } catch (e) {
      throw new Error(`Error fetching billing: ${e.message}`);
    }
  };
  return { createBillingAddress, changeBillingAddress, deleteBillingAddr, getBillingAddress };
}
