// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axiosBackendInstance from 'src/utils/axios-backend-instance';
import { supabaseClient } from 'src/utils/supabaseClient';
import { LogsActions } from 'src/enumerate/logsActions';
import { CreateLogsForm } from 'src/types/logs.type';
import { userInfoUpdated } from 'src/redux/slices/user.slice';
import { store, useDispatch } from 'src/redux/store';
import { getUserAssignedLocation, useLocation } from './location';
import { createNewLog } from './logs';

export function useGoogle() {
  const dispatch = useDispatch();
  const { getLocationsByUserId } = useLocation();
  const getGoogleLocations = async () => {
    const {
      data: { user },
    } = await supabaseClient.auth.getUser();
    if (!user) {
      throw new Error('Not authenticated');
    }
    const userAssignedLocation = (await getUserAssignedLocation(user?.id, null)) as any[];
    const mappedId = userAssignedLocation?.map((location) => location?.id);
    const { data, error } = await supabaseClient
      .from('google_business_locations')
      .select('id,title,maps_uri,location_id(id,name),auto_reply,is_reviews_fetched')
      .in('location_id.id', mappedId);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.GET_GOOGLE_LOCATIONS,
        message: error.message,
        is_error: true,
      };
      createNewLog(newLogsForm);
      throw new Error('Error getting google locations');
    }

    return data || [];
  };

  const ChangesAutoReplyGoogleReviewsStatus = async ({
    id,
    status = false,
  }: {
    id: string;
    status: boolean;
  }) => {
    const { error } = await supabaseClient
      .from('google_business_locations')
      .update({ auto_reply: status })
      .eq('id', id);
    if (error) {
      if (error) {
        const newLogsForm: CreateLogsForm = {
          action: LogsActions.CHANGE_AUTO_REPLY_GOOGLE,
          message: `${error.message} | Error getting google locations`,
          is_error: true,
          entry_data: JSON.parse(JSON.stringify({ id, status })),
        };
        createNewLog(newLogsForm);
        throw new Error('Error getting google locations');
      }
      throw new Error('Error update auto reply status');
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.CHANGE_AUTO_REPLY_GOOGLE,
      message: `Auto reply status changed to ${status}`,
      is_error: false,
    };
    createNewLog(newLogsForm);
  };

  const saveGoogleLocations = async (locations: any[]) => {
    const { error } = await supabaseClient
      .from('google_business_locations')
      .upsert(locations, { onConflict: 'name' });
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.SAVE_GOOGLE_LOCATIONS,
        message: `${error.message} | Error saving google locations`,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify(locations)),
      };
      createNewLog(newLogsForm);
      throw new Error('Error saving google locations');
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.SAVE_GOOGLE_LOCATIONS,
      message: `Google locations saved successfully`,
      is_error: false,
    };
    createNewLog(newLogsForm);
  };

  const UpdateGoogleLocationWithCompnayLocation = async ({
    googleLocationId,
    companyLocationId,
  }: {
    googleLocationId: string;
    companyLocationId: string;
  }) => {
    const { error } = await supabaseClient
      .from('google_business_locations')
      .update({ location_id: companyLocationId })
      .eq('id', googleLocationId);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_GOOGLE_LOCATION_COMPANY,
        message: `${error.message}`,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ googleLocationId, companyLocationId })),
      };
      createNewLog(newLogsForm);
      throw new Error('Error update auto reply status');
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_GOOGLE_LOCATION_COMPANY,
      message: `Google location updated successfully`,
      is_error: false,
    };
    createNewLog(newLogsForm);
  };

  const deletelocation = async (id?: string) => {
    if (!id) throw new Error('id is required');
    const { error } = await supabaseClient.from('google_business_locations').delete().eq('id', id);
    if (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.DELETE_LOCATION_FROM_GOOGLE,
        message: `${error.message}`,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify({ location_id: id })),
      };
      createNewLog(newLogsForm);
      throw new Error('Error deleting location');
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.DELETE_LOCATION_FROM_GOOGLE,
      message: `Google location deleted successfully`,
      is_error: false,
    };
    createNewLog(newLogsForm);
  };

  const fetchGoogleReviews = async (ids: string[]) => {
    try {
      const { data } = await axiosBackendInstance.post(`/google/fetch-reviews`, {
        locationIds: ids,
      });

      return data;
    } catch (error) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.FETCH_GOOGLE_REVIEWS,
        message: `${error.message}`,
        is_error: true,
        entry_data: JSON.parse(JSON.stringify(ids)),
      };
      createNewLog(newLogsForm);
      throw new Error('Error fetching google reviews');
    }
  };

  const searchGoogleLocations = async (search: string) => {
    try {
      const { data } = await axiosBackendInstance.get(`/google/location/${search}`);
      return data;
    } catch (error) {
      throw new Error('Error fetching google locations');
    }
  };

  const getGoogleOAuthURL = async (options: Object): Promise<any> => {
    const params = {};
    if (options) {
      Object.assign(params, { state: { options, oauth_reason: 'google-accounts-persisting' } });
    }
    try {
      const {
        data: { url: OAuth2Url },
      } = await axiosBackendInstance.get(`/google/auth-url`, {
        params,
      });
      return OAuth2Url;
    } catch (error) {
      throw new Error('Error authenticated with google');
    }
  };

  const linkMapToLocation = async (paylaod: any) => {
    const checker = await checkLinkedMap(paylaod.name);

    if (checker) {
      throw new Error('Account already linked');
    }

    const {
      data: { user },
      error: userError,
    } = await supabaseClient.auth.getUser();

    if (userError || !user) throw new Error('Not authenticated');

    const { data, error } = await supabaseClient
      .from('google_business_locations')
      .upsert(
        { ...paylaod, user_id: user?.id },
        {
          onConflict: 'location_id',
        }
      )
      .select('id')
      .maybeSingle();

    if (error) {
      const linkLogForm: CreateLogsForm = {
        action: LogsActions.LINK_GOOGLE_MAP,
        is_error: true,
        message: error.message,
        entry_data: JSON.parse(JSON.stringify({ ...paylaod, user_id: user?.id })),
      };

      createNewLog(linkLogForm);
      throw new Error(error.message);
    }
    if (data && data?.id) {
      fetchGoogleReviews([data?.id]);
    }
    const linkLogForm: CreateLogsForm = {
      action: LogsActions.LINK_GOOGLE_MAP,
      is_error: false,
      entry_data: JSON.parse(JSON.stringify({ ...paylaod, user_id: user?.id })),
    };
    createNewLog(linkLogForm);

    const store_data = store.getState();
    const locations = await getLocationsByUserId(user?.id as string);
    dispatch(
      userInfoUpdated({
        ...store_data.user,
        ...store_data.billing,
        locations,
      })
    );
  };

  const unLinkMapFromLocation = async (location_id: any) => {
    const { user } = store.getState();
    const { error } = await supabaseClient
      .from('google_business_locations')
      .delete(location_id)
      .eq('location_id', location_id);

    if (error) {
      throw new Error(error.message);
    }

    const store_data = store.getState();
    const locations = await getLocationsByUserId(user?.id as string);
    dispatch(
      userInfoUpdated({
        ...store_data.user,
        ...store_data.billing,
        locations,
      })
    );
  };

  const checkLinkedMap = async (map_name: string) => {
    const { data, error } = await supabaseClient.rpc('check_google_map_exists', {
      google_map: map_name,
    });
    if (error) {
      return false;
    }
    return (data || 0) > 0;
  };
  return {
    getGoogleLocations,
    ChangesAutoReplyGoogleReviewsStatus,
    saveGoogleLocations,
    UpdateGoogleLocationWithCompnayLocation,
    deletelocation,
    searchGoogleLocations,
    fetchGoogleReviews,
    getGoogleOAuthURL,
    linkMapToLocation,
    unLinkMapFromLocation,
    checkLinkedMap,
  };
}
