export enum Roles {
  ADMIN = 'admin',
  USER = 'accountOwner',
  SUPER_ADMIN = 'superAdmin',
}

export enum CompanyRoles {
  MEMBER = 0,
  OWNER = 1,
}

export enum account_type {
  MANAGER = 'manager',
  ADMIN = 'admin',
  OWNER = 'owner',
}
