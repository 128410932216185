import { useTheme } from '@mui/material';
import React from 'react';

export default function IcRoundPayment() {
  const theme = useTheme();

  const PRIMARY_DARK = theme.palette.primary.dark;

  const PRIMARY_DARKER = theme.palette.primary.darker;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" fill="none" viewBox="0 0 78 78">
      <path
        fill={PRIMARY_DARK}
        fillRule="evenodd"
        d="M39 52c12.565 0 22.75-10.185 22.75-22.75C61.75 16.686 51.565 6.5 39 6.5c-12.564 0-22.75 10.186-22.75 22.75C16.25 41.815 26.436 52 39 52zm0-32.5c-.923 0-1.54 1.108-2.776 3.323l-.32.573c-.35.63-.526.945-.8 1.152-.273.208-.614.285-1.295.44l-.62.14c-2.399.542-3.598.814-3.883 1.731-.285.917.532 1.873 2.167 3.785l.423.495c.465.543.697.815.801 1.15.105.337.07.7 0 1.424l-.064.66c-.248 2.55-.371 3.826.376 4.393.747.567 1.87.05 4.115-.984l.58-.267c.639-.294.958-.44 1.296-.44.338 0 .657.146 1.295.44l.581.267c2.246 1.034 3.368 1.551 4.115.984.747-.567.623-1.842.376-4.393l-.064-.66c-.07-.725-.105-1.087 0-1.423.104-.336.336-.608.8-1.151l.424-.495c1.635-1.912 2.452-2.868 2.167-3.785-.285-.917-1.484-1.189-3.883-1.731l-.62-.14c-.681-.155-1.022-.232-1.296-.44-.273-.207-.449-.522-.8-1.152l-.32-.573C40.542 20.608 39.924 19.5 39 19.5z"
        clipRule="evenodd"
      />
      <path
        fill={PRIMARY_DARKER}
        d="M21.821 56.3l2.07-7.55L26 42.25h26l2.109 6.5 2.07 7.55c2.042 7.448 3.063 11.172 1.7 13.211a4.946 4.946 0 01-1.861 1.667c-2.121 1.08-5.396-.627-11.946-4.04-2.18-1.135-3.269-1.703-4.427-1.826a6.08 6.08 0 00-1.29 0c-1.158.123-2.248.69-4.427 1.826-6.55 3.413-9.825 5.12-11.946 4.04a4.946 4.946 0 01-1.861-1.667c-1.363-2.039-.342-5.763 1.7-13.211z"
        opacity="0.5"
      />
    </svg>
  );
}
