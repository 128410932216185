import { createSlice } from '@reduxjs/toolkit';

interface INotification {
  contact_actions_id?: string | null;
  created_at?: string;
  description?: string | null;
  facebook_reviews_id?: string | null;
  google_reviews_id?: string | null;
  id?: string;
  location?: string | null;
  location_id?: string;
  notification_type?: string | null;
  posted_at?: string | null;
  review_rating?: number | null;
  review_type?: string | null;
  reviewer_avatar_url?: string | null;
  reviewer_name?: string | null;
  unread?: boolean;
  updated_at?: string;
}

const initialState: INotification = {};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationInfoUpdated(state, action) {
      const notificationInfo = action.payload;
      return {
        ...state,
        ...notificationInfo,
      };
    },
    notificationReset() {
      return initialState;
    },
  },
});

export const { notificationInfoUpdated, notificationReset } = notificationSlice.actions;
export default notificationSlice.reducer;
