import { createSlice } from '@reduxjs/toolkit';

interface ActivePlanState {
  id: string;
  next_billing_date: string;
  status: string;
  subscription_id: string;
  current_period_start: string;
  current_period_end: string;
  latest_invoice: string;
  stripe_product_id: string;
  created_at: string;
  price_id: string;
  name: string;
  price: number;
  active: boolean;
  max_email_request: number;
  max_sms_request: number;
}
interface PlansState {
  id: string;
  stripe_product_id: string;
  created_at: string;
  price_id: string;
  name: string;
  price: number;
  active: boolean;
  max_email_requests: number;
  max_sms_requests: number;
}

interface BillingAddress {
  id: string;
  city: string;
  country: string;
  line1: string;
  postal_code: string;
  state: string;
  is_default: boolean;
  created_at: string;
  user_id: string;
}
interface CardsState {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  is_default: boolean;
  created_at: string;
  user_id: string;
  card_id: string;
}
interface BilingState {
  active_plan: ActivePlanState;
  plans: PlansState[];
  billing_address: BillingAddress[];
  user_cards: CardsState[];
}

const initialState: BilingState = {
  active_plan: {
    id: '',
    next_billing_date: '',
    status: '',
    subscription_id: '',
    current_period_start: '',
    current_period_end: '',
    latest_invoice: '',
    stripe_product_id: '',
    created_at: '',
    price_id: '',
    name: '',
    price: 0,
    active: false,
    max_email_request: 0,
    max_sms_request: 0,
  },
  plans: [
    {
      id: '',
      stripe_product_id: '',
      created_at: '',
      price_id: '',
      name: '',
      price: 0,
      active: false,
      max_email_requests: 0,
      max_sms_requests: 0,
    },
  ],
  billing_address: [
    {
      id: '',
      city: '',
      country: '',
      line1: '',
      postal_code: '',
      state: '',
      is_default: false,
      created_at: '',
      user_id: '',
    },
  ],
  user_cards: [
    {
      id: '',
      brand: '',
      last4: '',
      exp_month: 0,
      exp_year: 0,
      is_default: false,
      created_at: '',
      user_id: '',
      card_id: '',
    },
  ],
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    billingInfoUpdated(state, action) {
      const billingInfo = action.payload;
      return {
        ...state,
        ...billingInfo,
      };
    },
    billingReset() {
      return {
        ...initialState,
      };
    },
  },
});

export const { billingInfoUpdated, billingReset } = billingSlice.actions;

export default billingSlice.reducer;
