/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import uuidv4 from 'src/utils/uuidv4';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'src/redux/store';
import { useFacebook } from 'src/Services/facebook-pages';
import { useBoolean } from 'src/hooks/use-boolean';
import { SocialAction } from './types';

function ManageFacebookPageButtonAction({
  linked = false,
  item,
  sx,
  linkedSx,
  refetchChanges,
  onChangeState,
}: SocialAction) {
  const user = useSelector((state: any) => state.user);
  const [connected, setConnected] = useState<boolean>(linked);
  const { getFBOAuthPage, linkFBPageToLocation, unLinkFBPageToLocation, checkPageStatus } =
    useFacebook();
  const [facebookPage] = useState<any>(item);
  const [enableListener, setEnableListener] = useState<Boolean>(false);
  const [providerResponse, setProviderResponse] = useState<any>([]);
  const modalToggle = useBoolean(false);
  const [selectedPage, setSelectedPage] = useState<any | null>(null);
  const componentId = uuidv4();
  // alert
  const { enqueueSnackbar } = useSnackbar();

  const { data: isExist, isLoading } = useQuery({
    queryFn: () => checkPageStatus(selectedPage),
    queryKey: ['checkPageStatus', selectedPage],
    enabled: !!selectedPage,
  });

  const linkProvider = useMutation({
    mutationFn: (data: any) => linkFBPageToLocation(data),
    gcTime: 0,
    onSuccess: () => {
      enqueueSnackbar('Successfully linked !', {
        variant: 'success',
      });
      modalToggle.onFalse();
      if (onChangeState) onChangeState();
      if (refetchChanges) refetchChanges();
      setConnected(true);
    },
    onError: (error: any) => {
      enqueueSnackbar(error.message ?? 'Something went wrong please try again !', {
        variant: 'warning',
      });
    },
  });
  const unlinkProvider = useMutation({
    mutationFn: (data: any) => unLinkFBPageToLocation(data),
    gcTime: 0,

    onSuccess: () => {
      enqueueSnackbar('Unlinked', {
        variant: 'info',
      });
      if (onChangeState) onChangeState();
      if (refetchChanges) refetchChanges();
      setConnected(false);
    },
    onError: () => {
      enqueueSnackbar('Something went wrong please try again !', {
        variant: 'error',
      });
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () => getFBOAuthPage({ componentId }),
    gcTime: 0,
    onSettled: () => {
      setEnableListener(true);
    },
    onSuccess: (OAuth2Url: any) => {
      try {
        // Check if the popup was blocked
        if (OAuth2Url) {
          const popupWindow = window.open(
            OAuth2Url as string,
            'facebook_oauth2_window',
            'width=600,height=900'
          );

          // Check if the popup was successfully opened
          if (!popupWindow) {
            // Handle the case where the popup was blocked
            enqueueSnackbar('Popup blocked! Please allow popups for this site.', {
              variant: 'warning',
            });
          }
        } else {
          enqueueSnackbar('Error loading Google Linking account page', { variant: 'error' });
        }
      } catch (error) {
        // Handle the error
        enqueueSnackbar('Error loading Google Linking account page', { variant: 'error' });
      }
    },
    onError: () => {
      enqueueSnackbar('Something went wrong please try again !', {
        variant: 'error',
      });
    },
  });
  const hanldeDataReceivedFromServerBasedOnOAuth = useCallback(
    (receivedData: { data: string; error: any; options: any }) => {
      if (!receivedData) return;

      const { data, error } = receivedData;

      if (error) {
        enqueueSnackbar(error?.message ?? 'Something went wrong please try again !', {
          variant: 'error',
        });
        return;
      }
      if (data && Array.isArray(data) && data.length === 0) {
        enqueueSnackbar(` No facebook pages available is found`, { variant: 'info' });
        return;
      }

      setProviderResponse(() => {
        modalToggle.onTrue();
        return data;
      });

      setEnableListener(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );
  const handleonChangeProvider = async () => {
    const selectedLocation = providerResponse?.find((page: any) => page?.page_id === selectedPage);

    if (selectedLocation)
      linkProvider.mutate({
        ...selectedLocation,
        location_id: facebookPage?.location_id,
        user_id: user?.id,
      });
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event?.data?.componentId === componentId) {
        hanldeDataReceivedFromServerBasedOnOAuth(event?.data);
      }
    });

    // Clean up event listener when component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.removeEventListener('message', (_event) => {});
    };
  }, [hanldeDataReceivedFromServerBasedOnOAuth, componentId, enableListener]);

  if (connected)
    return (
      <Stack
        sx={{
          width: '100%',
          ...linkedSx,
        }}
        spacing={1}
        flexDirection="row"
        alignItems="center"
      >
        <Stack>
          <Iconify icon="devicon:facebook" width={25} height={25} />
        </Stack>
        <Stack minWidth="182px">
          <Typography variant="subtitle1">{facebookPage?.name}</Typography>
          <Typography variant="caption">{facebookPage?.email}</Typography>
        </Stack>
        <Stack>
          <IconButton onClick={() => unlinkProvider.mutate(facebookPage?.location_id)}>
            <Iconify icon="fluent:delete-16-regular" width={20} height={20} color="red" />
          </IconButton>
        </Stack>
      </Stack>
    );
  return (
    <>
      {providerResponse?.length === 0 && (
        <LoadingButton
          loading={isPending}
          variant="outlined"
          onClick={() => {
            mutate();
          }}
          sx={{
            minWidth: '200px',
            maxWidth: '200px',
            fontWeight: '300 !important',
            display: 'flex',
            justifyContent: 'flex-start',
            mb: '0.5px',
            ...sx,
          }}
          fullWidth
          startIcon={<Iconify icon="ic:baseline-facebook" />}
        >
          Link Facebook page
        </LoadingButton>
      )}
      <Dialog open={modalToggle.value} onClose={() => modalToggle.onFalse()}>
        <DialogTitle>Select Page</DialogTitle>
        <DialogContent className="my-1">
          <Stack flexDirection="row" className="mt-2">
            <FormControl size="small" fullWidth error={isExist === true}>
              <InputLabel>Pages</InputLabel>
              <Select
                name="facebook"
                label="facebook"
                placeholder="Please select item"
                onChange={(event) => {
                  if (event.target.value !== 'None') setSelectedPage(event.target.value);
                }}
                error={isExist === true}
              >
                {providerResponse?.map((page: any, index: number) => (
                  <MenuItem key={index} value={page?.page_id}>
                    {page?.name}
                  </MenuItem>
                ))}
              </Select>
              {isExist === true && !isLoading ? (
                <Typography variant="caption" color="error">
                  Already linked to another location
                </Typography>
              ) : null}
            </FormControl>
          </Stack>
          <FacebookPermissionsInfo />
        </DialogContent>
        <DialogActions>
          <Stack
            justifyContent="center"
            spacing={2}
            marginLeft={1}
            marginBottom="25px"
            direction="row"
          >
            <LoadingButton
              onClick={() => {
                setProviderResponse([]);
                modalToggle.onFalse();
              }}
              variant="outlined"
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleonChangeProvider}
              disabled={isExist}
              variant="contained"
              loading={isLoading || linkProvider.isPending}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
function FacebookPermissionsInfo() {
  return (
    <Stack direction="column" className="mt-2">
      <Typography variant="button">Permissions Required</Typography>
      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
        By confirming, you grant Revcall access to your Facebook page to:
      </Typography>
      <ul style={{ listStyleType: 'disc', paddingLeft: 20 }}>
        <li>
          <Typography variant="caption">
            Manage your page&apos;s reviews: This allows us to retrieve existing reviews, respond to
            new ones, and potentially flag inappropriate reviews (depending on Facebook&apos;s
            guidelines).
          </Typography>
        </li>
        <li>
          <Typography variant="caption">
            Access basic information: We will be able to see your page&apos;s name, profile picture,
            and public contact information.
          </Typography>
        </li>
        <li>
          <Typography variant="caption">
            Potentially gain additional permissions in the future: We may request further
            permissions in the future, but you will always be notified and have the option to accept
            or decline.
          </Typography>
        </li>
      </ul>
    </Stack>
  );
}

export default ManageFacebookPageButtonAction;
