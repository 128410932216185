/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import { ICompany } from 'src/types/company';
import Iconify from 'src/components/iconify';
import { useCompany } from 'src/Services/company';

type Props = {
  isIntegraionsOnboarding?: boolean;
  isLocationOnboarding?: boolean;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
};

export default function OnbordingCompany({
  isIntegraionsOnboarding,
  setActiveStep,
  isLocationOnboarding,
}: Props) {
  const { company, updateCompany } = useCompany();
  const { enqueueSnackbar } = useSnackbar();

  const UpdateCompanySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    website: Yup.string(),
    phone: Yup.string().required('Phone is required'),
    vat_id: Yup.string().nullable(),
    coc_id: Yup.string().nullable(),
    logo_url: Yup.mixed<any>().required('Logo is required'),
    iban: Yup.string().nullable(),
    email: Yup.string().required('Email is required').email('Email is invalid'),
  });
  const defaultValues = useMemo(
    () => ({
      name: company?.name || '',
      website: company?.website || '',
      phone: company?.phone || '',
      address1: company?.address1 || '',
      address2: company?.address2 || '',
      country: company?.country || '',
      vat_id: company?.vat_id || '',
      coc_id: company?.coc_id || '',
      logo_url: company?.logo_url || null,
      city: company?.city || '',
      state: company?.state || '',
      zip_code: company?.zip_code || '',
      email: company?.email || '',
      iban: company?.iban || '',
    }),
    [company]
  );

  const methods = useForm<any>({
    resolver: yupResolver(UpdateCompanySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = methods;

  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: (data: ICompany) => updateCompany(data),
    onSuccess: () => {
      enqueueSnackbar('Company has been updated successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Error updating Company', { variant: 'error' });
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const finaleData = {
        name: data.name,
        website: data.website,
        phone: data.phone,
        coc_id: data.coc_id,
        vat_id: data.vat_id,
        iban: data.iban,
        email: data.email,
        logo_url: data.logo_url,
      };
      await mutate(finaleData as ICompany);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      /* size */
      if (file.size > 1048576) {
        enqueueSnackbar('File size is too large', { variant: 'error' });
        return;
      }

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('logo_url', newFile);
      }
    },
    [enqueueSnackbar, setValue]
  );

  useEffect(() => {
    if (company) {
      reset({
        coc_id: company?.coc_id || '',
        vat_id: company?.vat_id || '',
        iban: company?.iban || '',
        name: company?.name || '',
        website: company?.website || '',
        phone: company?.phone || '',
        email: company?.email || '',
        logo_url: company?.logo_url || null,
      });
    }
  }, [company, reset]);
  useEffect(() => {
    if (isSuccess) {
      if (isLocationOnboarding && setActiveStep) {
        setActiveStep(1);
      } else if (isIntegraionsOnboarding && setActiveStep) {
        setActiveStep(2);
      } else {
        window.location.reload();
      }
    }
  }, [isIntegraionsOnboarding, isLocationOnboarding, isSuccess, setActiveStep]);
  return (
    <div className="flex justify-center items-center h-full">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3" align="center">
          Company Settings
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            mb: 2,
          }}
        >
          Please fill the empty fields to get started.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sx={{ mb: 3 }}>
            <Card
              sx={{
                backgroundColor: '#ff',
                borderRadius: '10px',
                width: 'fit-content',
                height: '100%',

                margin: 'auto',
              }}
            >
              <RHFUploadAvatar
                name="logo_url"
                maxSize={1048576}
                onDrop={handleDrop}
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '0%',
                  border: 'none',
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <RHFTextField name="name" label="Company name" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField name="phone" label="Company Phone number" required />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHFTextField name="website" label="Company Website" required />
          </Grid>
          <Grid item xs={12} md={12}>
            <RHFTextField name="email" label="Company Email Address" required sx={{ mb: 3 }} />
          </Grid>
        </Grid>

        <Stack spacing={3} alignItems="center" sx={{ mt: 1 }}>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting || isPending}>
            {isLocationOnboarding || isIntegraionsOnboarding ? (
              <>
                Save and continue <Iconify style={{ marginLeft: '5px' }} icon="mdi:arrow-right" />
              </>
            ) : (
              'Save and exit'
            )}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </div>
  );
}
