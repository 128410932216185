import { memo } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function DomainIllustration({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      style={{
        enableBackground: 'new 0 0 500 500',
      }}
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M0 382.398h500v.25H0zM423.904 398.494h25.997v.25h-25.997zM311 401.208h45.027v.25H311zM362.111 389.208h60.792v.25h-60.792zM52.459 390.888h19.235v.25H52.459zM80.597 390.888h55.292v.25H80.597zM169.667 395.111h74.467v.25h-74.467zM237.014 337.8H43.915a5.715 5.715 0 0 1-5.708-5.708V60.66a5.715 5.715 0 0 1 5.708-5.708h193.099a5.715 5.715 0 0 1 5.707 5.708v271.432a5.715 5.715 0 0 1-5.707 5.708zM43.915 55.203a5.464 5.464 0 0 0-5.458 5.458v271.432a5.464 5.464 0 0 0 5.458 5.458h193.099a5.463 5.463 0 0 0 5.457-5.458V60.66a5.464 5.464 0 0 0-5.457-5.458H43.915zM453.31 337.8H260.212a5.714 5.714 0 0 1-5.707-5.708V60.66a5.715 5.715 0 0 1 5.707-5.708H453.31a5.714 5.714 0 0 1 5.708 5.708v271.432a5.714 5.714 0 0 1-5.708 5.708zM260.212 55.203a5.463 5.463 0 0 0-5.457 5.458v271.432a5.463 5.463 0 0 0 5.457 5.458H453.31a5.464 5.464 0 0 0 5.458-5.458V60.66a5.464 5.464 0 0 0-5.458-5.458H260.212z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m425.993 382.398-5.979-10.814 21.1-245.371a40.805 40.805 0 0 1 6.223 25.697l-21.344 230.488z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M65.131 371.584h354.883l22.348-241.347a5.522 5.522 0 0 0-5.498-6.031H93.072a5.521 5.521 0 0 0-5.498 5.013L65.131 371.584z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="m415.324 362.444 21.238-228.398a3.997 3.997 0 0 0-3.982-4.347H96.709a3.996 3.996 0 0 0-3.981 3.647L71.489 361.745a3.998 3.998 0 0 0 3.982 4.347h335.872a3.998 3.998 0 0 0 3.981-3.648z"
        style={{
          fill: '#fafafa',
        }}
      />
      <path
        d="M432.582 129.702H96.709a4 4 0 0 0-3.986 3.647l-1.13 12.172h343.896l1.068-11.474a3.994 3.994 0 0 0-3.975-4.345z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M113.006 137.611c-.184 2.074-2.014 3.755-4.088 3.755s-3.606-1.681-3.422-3.755c.184-2.074 2.014-3.755 4.088-3.755s3.606 1.681 3.422 3.755z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M128.798 137.611c-.184 2.074-2.014 3.755-4.087 3.755s-3.606-1.681-3.422-3.755c.184-2.074 2.014-3.755 4.088-3.755s3.605 1.681 3.421 3.755z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M144.59 137.611c-.184 2.074-2.014 3.755-4.087 3.755-2.074 0-3.606-1.681-3.422-3.755.184-2.074 2.014-3.755 4.087-3.755s3.606 1.681 3.422 3.755z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M389.505 371.584h30.509l5.979 10.814h-32.707z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M389.505 371.584H37.931l3.782 10.814h351.573z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="M248.797 371.584H134.025l.424 2.431a5.613 5.613 0 0 0 5.53 4.649h103.376c3.486 0 6.128-3.143 5.53-6.577l-.088-.503z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <ellipse
        cx={250}
        cy={416.238}
        rx={193.889}
        ry={11.323}
        style={{
          fill: '#f5f5f5',
        }}
      />
      <g transform="rotate(-22.5 249.498 250.003)">
        <ellipse
          cx={249.51}
          cy={250.013}
          rx={134.306}
          ry={20.769}
          style={{
            fill: '#407bff',
          }}
        />
        <ellipse
          cx={249.51}
          cy={250.013}
          rx={134.306}
          ry={20.769}
          style={{
            opacity: 0.2,
          }}
        />
      </g>
      <path
        d="M293.729 154.368a122.112 122.112 0 0 0-4.574-1.252l.246-.969c1.538.389 3.09.814 4.611 1.262l-.283.959zM282.227 151.576a123.506 123.506 0 0 0-23.224-2.201v-1c7.861 0 15.737.747 23.411 2.219l-.187.982zM259.097 394.659v-1c16.035 0 31.741-3.138 46.683-9.327l.383.924c-15.064 6.24-30.899 9.403-47.066 9.403z"
        style={{
          fill: '#407bff',
        }}
      />
      <circle
        cx={259.049}
        cy={271.517}
        r={119.611}
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-45.001 259.047 271.521)"
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          d="m347.927 213.596 18.831 5.925c-18.341-38.112-54.704-62.428-94.447-66.889l-5.727 15.285-15.905 5.138s-17.753 16.38-17.675 17.431c.069 1.054-.298 17.442-.298 17.442l-12.239 15.894-10.5 6.871-.059 15.535 6.253 15.096 9.385-1.723 8.18-9.883-1.324-6.671 19.304-10.886 14.833 7.927 21.065-8.358 13.604 8.436-17.63 16.676-27.658 7.127-19.762 8.185-19.352 16.859 4.711 20.963 15.416 10.207 30.228 1.55 17.372 18.421 10.934 19.284 6.53 8.36 10.576-5.824 10.73-26.814 7.364-34.319-3.971-19.917-8.331 5.887-5.996-12.724-11.954-12.309-4.061-8.063 26.151 12.623 27.497-4.17 9.531-20.551-9.913-16.092-15.982 4.087-11.515.083-3.807-7.44 14.03-1.85 15.581-6.809zM202.867 374.701l-6.033-18.066-28.249-16.798-9.312-2.334c21.575 32.724 57.691 52.314 95.84 53.582l-.815-1.968s-31.26-10.139-32.31-10.062c-1.053.069-19.121-4.354-19.121-4.354zM152.232 262.909l1.149-32.948 20.023-12.624 13.53-32.664-8.627-1.438c-34.189 31.263-48.088 80.727-32.599 126.483l19.324-12.431-12.8-34.378z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="M367.261 220.579c-17.801 14.723-58.026 36.428-106.53 56.519-47.672 19.746-90.784 32.733-114.097 35.246.59 1.66 1.227 3.301 1.908 4.946 6.039 14.579 14.633 27.331 25.047 37.943 24.47-4.291 63.159-16.485 105.427-33.993 43.025-17.822 79.527-36.914 99.656-51.269-.174-14.767-3.122-29.75-9.119-44.227a106.994 106.994 0 0 0-2.292-5.165z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="m373.587 198.615 18.285 44.143c4.389 10.597-47.604 42.2-116.129 70.584-68.533 28.388-127.645 42.806-132.035 32.209l-18.285-44.143c4.389 10.597 63.501-3.822 132.035-32.209 68.525-28.384 120.519-59.986 116.129-70.584z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m373.587 198.615 18.285 44.143c4.389 10.597-47.604 42.2-116.129 70.584-68.533 28.388-127.645 42.806-132.035 32.209l-18.285-44.143c4.389 10.597 63.501-3.822 132.035-32.209 68.525-28.384 120.519-59.986 116.129-70.584z"
        style={{
          opacity: 0.5,
          fill: '#fff',
        }}
      />
      <path
        d="M184.092 306.456a930.964 930.964 0 0 0 9.466-3.041c3.395 4.51 6.778 9.015 10.151 13.529-.61-5.425-1.207-10.863-1.789-16.31a937.414 937.414 0 0 0 9.357-3.217c3.856 4.32 7.693 8.641 11.515 12.963a4144.22 4144.22 0 0 1-3.204-15.915 795.044 795.044 0 0 0 9.267-3.396c1.52 9.666 3.078 19.309 4.668 28.923a898.17 898.17 0 0 1-9.492 3.376 3617.428 3617.428 0 0 0-13.011-14.613c.647 6.141 1.31 12.27 1.99 18.383a894.24 894.24 0 0 1-9.584 3.148 4115.063 4115.063 0 0 0-19.334-23.83zM238.061 287.582a861.264 861.264 0 0 0 9.236-3.61c3.203 4.511 6.394 9.025 9.576 13.543-.485-5.593-.961-11.209-1.418-16.825a941.742 941.742 0 0 0 9.126-3.781c3.649 4.29 7.281 8.58 10.898 12.876a4589.687 4589.687 0 0 1-2.792-16.33 915.226 915.226 0 0 0 9.036-3.958c1.283 9.93 2.602 19.84 3.956 29.72a845.638 845.638 0 0 1-9.249 3.965 3575.306 3575.306 0 0 0-12.316-14.513c.508 6.335 1.032 12.656 1.574 18.963a927.431 927.431 0 0 1-9.343 3.738 4003.545 4003.545 0 0 0-18.284-23.788zM290.698 265.451a964.656 964.656 0 0 0 9.001-4.173c3.01 4.515 6.006 9.025 8.992 13.54-.361-5.763-.708-11.537-1.043-17.323a1012.67 1012.67 0 0 0 8.891-4.341 3208.92 3208.92 0 0 1 10.271 12.771 4397.281 4397.281 0 0 1-2.377-16.726 923.382 923.382 0 0 0 8.798-4.52 4152.511 4152.511 0 0 0 3.239 30.489 882.228 882.228 0 0 1-9.003 4.552c-3.85-4.804-7.717-9.597-11.61-14.396.368 6.521.752 13.034 1.152 19.524a821.404 821.404 0 0 1-9.099 4.325 3860.825 3860.825 0 0 0-17.212-23.722z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M211.093 68.644c-.986-3.891-8.023-7.195-14.487-6.142-7.79 1.27-10.652 7.773-10.019 16.458.803 11.005-9.389 10.853-5.702 22.066 4.176-1.54 10.238-4.063 19.913.062 8.548 3.645 18.907.196 22.05-3.251-10.582-6.221 6.2-30.573-11.755-29.193z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M224.041 97.297a.251.251 0 0 1-.076-.012c-6.553-2.098-5.51-8.907-4.5-15.493.725-4.73 1.409-9.197-.82-11.478-1.4-1.433-3.859-1.898-7.52-1.423a.25.25 0 1 1-.064-.496c3.822-.496 6.424.016 7.941 1.569 2.404 2.459 1.701 7.047.957 11.903-.978 6.378-1.988 12.973 4.158 14.94a.25.25 0 0 1 .162.314.25.25 0 0 1-.238.176z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M176.355 112.794a38.542 38.542 0 0 1-3.019 1.105c-.984.323-1.972.62-2.966.902a57.541 57.541 0 0 1-6.086 1.32c-2.06.353-4.18.543-6.335.608l-1.625.023-1.649-.071c-.543-.011-1.113-.102-1.667-.152l-.835-.092c-.1-.008-.39-.054-.59-.087a7.34 7.34 0 0 1-.666-.141c-1.801-.413-3.586-1.555-4.678-2.89-1.124-1.329-1.733-2.705-2.143-3.964-.777-2.535-.856-4.785-.817-6.945.147-4.32.989-8.215 2.249-12.159a2.49 2.49 0 0 1 4.86.844l-.006.141c-.13 3.628-.089 7.449.359 10.833.228 1.678.617 3.303 1.157 4.399.257.543.561.905.736 1.004.195.098.141-.009.227-.02l.044-.009c.038-.005-.014-.004.127-.004h.607c.408-.009.797.026 1.218-.014a39.49 39.49 0 0 0 2.502-.186c1.688-.19 3.4-.467 5.124-.843a83.777 83.777 0 0 0 5.166-1.303c.86-.242 1.719-.496 2.565-.772l2.431-.816.227-.076a4.998 4.998 0 0 1 6.328 3.147c.843 2.51-.434 5.225-2.845 6.218z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="m145.905 93.411-2.595-4.439 6.757-3.502s1.64 4.957-.171 8.281l-3.991-.34zM161.665 323.37l-7.014-.538.508-18.297 7.921.264z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="m154.104 322.531 8.084-4.16a.618.618 0 0 1 .812.197l4.012 5.936c.416.616.149 1.539-.535 1.876-2.842 1.402-4.212 1.295-7.768 3.124-2.187 1.126-7.28 3.763-10.576 4.584-3.224.803-4.191-2.38-2.883-3.015 5.862-2.85 6.737-4.903 7.865-7.479.204-.465.559-.841.989-1.063z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m163.081 304.805-7.924-.265-.273 9.97 7.429.423z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="m139.526 298.764-6.857 1.575-4.962-17.619 7.64-2.107z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="m130.682 296.215 9.079-.473a.619.619 0 0 1 .659.513l1.221 7.06c.127.732-.496 1.464-1.258 1.491-3.167.111-4.362-.746-8.355-.538-2.456.128-7.989-.083-11.331-.688-3.269-.592-2.843-3.891-1.39-3.933 6.515-.191 7.951-.983 10.038-2.869a2.166 2.166 0 0 1 1.337-.563z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m135.35 280.619-7.643 2.106 2.707 9.599 7.219-1.807z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M185.234 115.463c-5.877 2.657-13.875 2.481-13.875 2.481-2.034-5.542-4.304-11.219-2.691-14.663 0 0 9.585-3.188 17.586-1.715 4.376.807 3.655 11.784-1.02 13.897z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M172.225 110.33c-1.519 6.782-2.861 18.202-2.417 37.049 12.819 2.007 26.074 4.088 32.313 5.066 2.865-15.313 6.182-23.821 12.903-35.188 2.023-3.421.477-7.836-3.221-9.293-7.739-3.048-18.27-5.593-26.923-6.561-5.878-.658-11.363 3.155-12.655 8.927z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M190.458 102.161s-7.569 1.617-5.831 10.617c13.651-.71 20.268-7.123 20.268-7.123l-14.437-3.494z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M205.33 95.3c-1.257 3.821-1.945 7.941-.435 10.355 0 0-4.074 3.467-17.636 3.791-1.964-6.303 3.199-7.285 3.199-7.285 5.514.027 6.539-3.355 6.687-9.377l4.549-.571c2.103-1.033 4.358.89 3.636 3.087z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M201.699 92.212c1.982-.976 4.108.687 3.721 2.731-2.678 2.2-5.603 3.788-9.258 4.139.692-1.557.925-3.681.99-6.299l4.547-.571z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M212.622 80.005c-1.646 7.892-2.176 11.264-6.74 14.859-6.864 5.407-16.061 1.898-16.773-6.268-.641-7.351 2.159-19.023 10.308-21.244 8.03-2.189 14.851 4.761 13.205 12.653z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M209.128 68.048c-7.078.447-6.499 13.729 2.586 18.227 5.652-6.984 1.369-16.802-2.586-18.227z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M208.957 67.715c-5.58-3.4-12.957-3.098-16.583 1.93-5.504 7.63 8.416.581 13.512.914 3.447 3.146 3.071-2.844 3.071-2.844zM190.131 76.103a.394.394 0 0 1-.01-.595c1.583-1.411 3.257-.952 3.327-.932.2.057.314.27.255.475a.381.381 0 0 1-.466.269c-.063-.017-1.367-.355-2.627.768a.37.37 0 0 1-.479.015z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M213.765 87.758c-.979 1.537-2.565 2.499-4.018 2.826-2.185.492-3.106-1.474-2.355-3.463.676-1.79 2.627-4.204 4.751-3.806 2.094.391 2.741 2.686 1.622 4.443z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M169.808 147.379c-2.317 4.07-5.131 9.117-7.032 12.928-12.067 10.202-38.549 36.144-47.294 57.107-2.482 20.419 13.851 72.73 13.851 72.73l7.913-3.971s-5.277-41.211-5.18-63.274c16.378-17.73 65.262-46.145 60.76-71.911l-23.018-3.609z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M176.591 167.76s-9.929 13.073-19.315 33.378c12.897-10.624 26.358-22.481 32.46-34.346l-13.145.968z"
        style={{
          opacity: 0.3,
        }}
      />
      <path
        d="M179.103 148.835s-15.922 46.449-22.349 79.335c-3.578 24.419-2.757 83.476-2.757 83.476l9.18 1.09s9.366-57.081 9.294-81.925c14.127-31.288 34.152-52.598 29.65-78.365l-23.018-3.611z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M173.105 167.482a.25.25 0 0 1-.213-.119c-.082-.133-1.999-3.284-1.697-6.196.297-2.853 3.003-11.705 3.118-12.081a.25.25 0 1 1 .479.146c-.028.091-2.807 9.181-3.099 11.986-.218 2.094.834 4.403 1.352 5.391l5.395-16.594a.25.25 0 1 1 .474.155l-5.571 17.139a.248.248 0 0 1-.208.171l-.03.002zM200.489 161.043a.252.252 0 0 1-.093-.018c-8.77-3.495-12.744-9.03-12.783-9.085a.249.249 0 0 1 .06-.348.251.251 0 0 1 .349.06c.038.054 3.936 5.473 12.561 8.909a.25.25 0 0 1 .14.325.254.254 0 0 1-.234.157z"
        style={{
          opacity: 0.2,
          fill: '#fff',
        }}
      />
      <path
        d="m153.122 307.748.635 5.06 10.164.536.609-5.451zM126.873 286.587l2.14 4.63 9.847-2.574-1.073-5.378z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M200.732 80.849c-.095.647-.51 1.131-.928 1.08-.418-.051-.68-.618-.585-1.265.095-.647.51-1.131.928-1.079.418.05.68.617.585 1.264zM193.505 79.963c-.095.647-.51 1.131-.928 1.079-.418-.051-.68-.618-.585-1.265.095-.647.51-1.131.928-1.079.418.051.68.617.585 1.265z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m193.1 78.756-1.47-.637c0 .001.61 1.312 1.47.637z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M194.74 81.162s-1.493 2.654-2.884 3.853c.727.901 2.216.713 2.216.713l.668-4.566z"
        style={{
          fill: '#ff5652',
        }}
      />
      <path
        d="M196.045 88.594a.186.186 0 0 1-.089-.189.196.196 0 0 1 .216-.167c2.798.343 4.407-1.48 4.423-1.499a.192.192 0 0 1 .268-.023.193.193 0 0 1 .016.27c-.07.082-1.754 2.002-4.763 1.632a.171.171 0 0 1-.071-.024zM202.51 78.674a.358.358 0 0 1-.117-.155c-.629-1.563-2.001-1.818-2.015-1.82a.37.37 0 0 1-.298-.441.393.393 0 0 1 .448-.312c.073.013 1.792.331 2.573 2.27a.391.391 0 0 1-.213.502.372.372 0 0 1-.378-.044zM200.326 79.643l-1.47-.637s.611 1.311 1.47.637z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M217.905 116.597c.66 2.45 1.177 4.846 1.721 7.28.52 2.43.996 4.874 1.405 7.342a118.52 118.52 0 0 1 1.06 7.494c.28 2.554.498 5.089.423 7.954l-.007.265a2.326 2.326 0 0 1-.024.266c-.405 2.577-1.065 4.777-1.739 6.999-.675 2.207-1.455 4.341-2.253 6.454a143.863 143.863 0 0 1-2.572 6.248c-.932 2.052-1.844 4.086-2.889 6.108a2.496 2.496 0 0 1-4.653-1.699l2.913-12.839c.492-2.12.924-4.244 1.335-6.327.398-2.069.757-4.187.892-6.006l-.031.53c-.1-2.028-.47-4.359-.846-6.646a208.944 208.944 0 0 0-1.342-6.971l-1.521-7.027-1.576-7.009-.004-.018a5.001 5.001 0 0 1 3.782-5.976c2.623-.589 5.229 1.009 5.926 3.578z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M204.237 113.92c-1.755 6.206 5.133 19.858 5.133 19.858 3.78-.201 7.728-6.296 12.297-10.194 0 0-3.801-10.558-7.473-13.987-3.252-3.038-8.561-.615-9.957 4.323z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m142.316 87.221.995 1.751 6.757-3.502-1.163-1.769a2.407 2.407 0 0 0-2.917-.908l-2.482 1.007a2.41 2.41 0 0 0-1.19 3.421zM209.176 169.108l-5.039 4.795 5.109 6.692s5.885-3.491 4.545-9.756l-4.615-1.731zM202.683 178.77l1.453-4.867 5.109 6.692-2.259 2.806a.968.968 0 0 1-1.547-.052l-2.621-3.747a.966.966 0 0 1-.135-.832z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M169.764 145.017c-.957-.241-1.311 2.624-.555 3.416.756.792 15.029 4.486 33.174 5.703 1.978-.554 1.499-3.584.134-3.772-4.546.29-21.891-2.616-32.753-5.347z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M169.764 145.017c-.957-.241-1.311 2.624-.555 3.416.756.792 15.029 4.486 33.174 5.703 1.978-.554 1.499-3.584.134-3.772-4.546.29-21.891-2.616-32.753-5.347z"
        style={{
          opacity: 0.2,
          fill: '#fff',
        }}
      />
      <path
        d="M159.659 54.474 127.3 78.799l13.853 1.242-6.047 10.946 6.712 3.708 6.047-10.945 8.427 11.066z"
        style={{
          fill: '#407bff',
        }}
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          d="M144.206 71.853c-.823-1.99-1.139-3.481-1.29-4.8l-6.483 4.876c.164 1.254.494 2.689 1.275 4.566.642 1.542 1.248 2.596 1.854 3.399l1.592.145-.588 1.064c1.347 1.423 2.666 2.354 4.135 5.895.215.531.398 1.02.543 1.488l2.619-4.741 4.624 6.077c-.081-2.121.059-4.201-1.288-7.465-2.463-5.982-4.52-4.525-6.993-10.504zM158.071 73.487c-1.845-2.789-3.541-2.861-5.499-7.594-.877-2.141-1.183-3.707-1.325-5.103l-4.54 3.422c.15 1.351.464 2.874 1.319 4.938 2.463 5.982 4.52 4.525 6.993 10.504 1.844 4.484 1.157 6.431 1.727 9.638l1.325-15.805z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="M150.288 92.863c2.195-2.672 1.209-6.091 1.276-9.345.008-.396-.807-.905-1.629.356-.527.808-.652 2.305-.49 3.082-1.645.738-2.768 2.943-1.495 5.461 1.37.519 2.338.446 2.338.446z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M206.235 70.026c-1.135 1.771-7.231 3.513-9.955 3.937 5.608-2.141 7.55-3.983 7.55-3.983l2.405.046z"
        style={{
          fill: '#263238',
        }}
      />
    </Box>
  );
}

export default memo(DomainIllustration);
