import { lazy } from 'react';

// ----------------------------------------------------------------------

// JWT
const ApiDocPage = lazy(() => import('src/pages/api-docs/index'));

// ----------------------------------------------------------------------

export const docsRoutes = [
  {
    path: 'api-docs',
    element: <ApiDocPage />,
  },
];
