/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { ICompany, ITimezone } from 'src/types/company';
import { supabaseClient } from 'src/utils/supabaseClient';
import { CreateLogsForm } from 'src/types/logs.type';
import { LogsActions } from 'src/enumerate/logsActions';
import { LocationPaginationResponse } from 'src/sections/settings/locations/view/Locations/types';
import { useDispatch } from 'react-redux';
import { userInfoUpdated } from 'src/redux/slices/user.slice';
import { store } from 'src/redux/store';
import axiosBackendInstance from 'src/utils/axios-backend-instance';
import { createNewLog } from './logs';
import { uploadImage } from './supabaseUpload';
import { areKeysEmpty } from './onboarding';

export function useCompany() {
  const {
    user: userConnect,
    user: { company },
  } = store.getState();
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCompany = useCallback(async () => {
    if (!userConnect.id || companyData !== null || loading || company?.id) return;

    setLoading(true);
    setError(null);
    try {
      const { data: company_data, error: supaBaseError } = await supabaseClient
        .from('user_company')
        .select('is_owner,company(*)')
        .eq('user_id', userConnect?.id)
        .eq('is_owner', true);
      if (supaBaseError) {
        throw new Error(supaBaseError.message);
      }

      if (company_data && company_data.length > 0) {
        const companyFinal = {
          ...company_data[0]?.company,
          isOwner: company_data[0]?.is_owner,
        };
        setCompanyData(companyFinal);
        dispatch(userInfoUpdated({ company: companyFinal }));
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConnect.id, companyData, loading, dispatch]);

  const updateCompany = async (data: ICompany) => {
    if (data?.logo_url instanceof File) {
      const url = await uploadImage(data?.logo_url, 'company', userConnect.id);
      data.logo_url = url;
    }
    if (data?.email_logo_url instanceof File) {
      const url = await uploadImage(data?.email_logo_url, 'company', userConnect.id);
      data.email_logo_url = url;
    }
    if (data?.sms_logo_url instanceof File) {
      const url = await uploadImage(data?.sms_logo_url, 'company', userConnect.id);
      data.sms_logo_url = url;
    }
    const { data: company_data, error: supaBaseError } = await supabaseClient
      .from('company')
      .update(data)
      .eq('id', company?.id)
      .select('name,logo_url,website,phone')
      .single();
    if (supaBaseError) {
      const newLogsForm: CreateLogsForm = {
        action: LogsActions.UPDATE_COMPANY,
        message: supaBaseError.message,
        is_error: true,
        user_email: userConnect.email,
        user_id: userConnect.id,
        entry_data: JSON.parse(JSON.stringify(data)),
      };
      createNewLog(newLogsForm);
      throw new Error(supaBaseError.message);
    }
    const newLogsForm: CreateLogsForm = {
      action: LogsActions.UPDATE_COMPANY,
      message: 'Company updated successfully',
      is_error: false,
      user_email: userConnect.email,
      user_id: userConnect.id,
    };
    createNewLog(newLogsForm);

    if (!areKeysEmpty(company_data)) {
      /* update user */
      const { error: userError } = await supabaseClient.auth.updateUser({
        data: {
          ...userConnect?.user_metadata,
          company_onboarding: false,
        },
      });
      if (userError) {
        throw new Error(userError.message);
      }
    }
    const cmp_data = (await getCompany()) as any;
    const domain_verified = data?.name ? data?.name === company?.name : true;
    const new_company_data = {
      ...cmp_data,
      is_domain_verified: domain_verified,
    };

    dispatch(userInfoUpdated({ company: new_company_data }));

    return company_data;
  };

  const getLocations = async ({
    currentPage,
    rowPerPage,
    searchText = '',
  }: {
    currentPage?: number;
    rowPerPage?: number;
    searchText?: string;
  }): Promise<LocationPaginationResponse> => {
    const { data: userCompany, error: userCompanyError } = await supabaseClient
      .rpc('get_location_members_by_user', {
        p_user_id: userConnect?.id,
        search_text: searchText,
      })
      .select('*')
      .range(
        (currentPage || 0) * (rowPerPage || 0),
        (currentPage || 0) * (rowPerPage || 0) + (rowPerPage || 0) - 1
      );

    if (userCompanyError) {
      throw new Error(userCompanyError.message);
    }
    const { count } = await supabaseClient
      .from('users_locations')
      .select('*', { count: 'exact', head: false });

    return {
      data:
        userCompany?.map((item: any) => ({
          ...item?.locations,
        })) ?? [],
      count: count ?? 0,
    };
  };
  const getPermittedLocations = async (): Promise<any> => {
    const { data } = await supabaseClient
      .from('users_locations')
      .select('...location_id (*)')
      .eq('user_id', userConnect?.id);

    return data || [];
  };

  const getCompanyMembers = async ({ currentPage = 0, rowPerPage = 4, searchText = '' }) => {
    const { data: companyMembers, error: companyMembersError } = (await supabaseClient
      .from('users_locations')
      .select('location_id')
      .eq('user_id', userConnect?.id)) as any;
    if (companyMembersError) {
      throw new Error(companyMembersError.message);
    }

    const locationsIds = companyMembers?.map((member: any) => member?.location_id);
    const { data, error: memberError } = await supabaseClient
      .rpc('get_location_members', {
        locations_id: locationsIds,
        search_text: searchText,
      })
      .select('*')
      .range(currentPage * rowPerPage, currentPage * rowPerPage + rowPerPage - 1);
    if (memberError) {
      throw new Error(memberError.message);
    }
    const finaleData = data?.map((member: any) => ({
      ...member.profiles,
      locations: member.locations,
    }));

    const { count } = await supabaseClient
      .from('profiles')
      .select('*', { count: 'exact', head: false })
      .in(
        'id',
        data?.map((member: any) => member?.profiles?.id)
      );

    return {
      data: finaleData ?? [],
      count: count ?? 0,
    };
  };
  const getTimezoneList = async (): Promise<ITimezone[]> => {
    const { data: timezoneList, error: timezoneListError } = await supabaseClient
      .from('timezone')
      .select('id,name,value,offset');

    if (timezoneListError) {
      return [];
    }
    return timezoneList;
  };

  const validateDomain = async () => {
    try {
      const rslt = await validateDomainBE();
      return rslt;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const validateDomainBE = async () => {
    try {
      const result = await axiosBackendInstance.post('/sendgrid/verify-domain');
      const { data } = result;
      const {
        user: { company: new_company },
      } = store.getState();
      if (data) {
        dispatch(userInfoUpdated({ company: { ...new_company, is_domain_verified: true } }));
      }
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  useEffect(() => {
    if (companyData === null) {
      fetchCompany();
    }
  }, [companyData, fetchCompany]);

  const resetData = useCallback(() => {
    setCompanyData(null);
  }, []);

  return {
    companyData,
    loading,
    error,
    company,
    userConnect,
    resetData,
    getLocations,
    getCompanyMembers,
    validateDomain,
    getCompany,
    getTimezoneList,
    updateCompany,
    getPermittedLocations,
  };
}
export const getCompany = async () => {
  const { data: userCon, error: userError } = await supabaseClient.auth.getUser();
  if (userError) {
    throw new Error(userError.message);
  }
  const { data: company_data, error: supaBaseError } = await supabaseClient
    .from('user_company')
    .select('is_owner,...company(*)')
    .eq('user_id', userCon?.user?.id)
    .maybeSingle();
  if (supaBaseError) {
    throw new Error(supaBaseError.message);
  }
  return company_data;
};
